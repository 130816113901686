import * as React from 'react'
import { Flex } from 'antd'

import { ExercisesWaitingClient } from '../exercises-waiting-client/exercises-waiting-client.component'
import { ExercisesWaitingClientsListProps } from './exercises-waiting-clients-list.types'
import './exercises-waiting-clients-list.styles.less'

export const ExercisesWaitingClientsList: React.FC<ExercisesWaitingClientsListProps> = props => {
  const { clients, onBook } = props

  return (
    <Flex vertical gap="small">
      {clients.map(client => (
        <ExercisesWaitingClient
          key={client.id}
          id={client.id}
          name={client.name}
          phone={client.phone}
          photo={client.photo}
          onBook={onBook}
        />
      ))}
    </Flex>
  )
}
