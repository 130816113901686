import { Button, Checkbox, Flex, List, Tooltip, Typography } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'

import { ExercisesPeekClientListProps } from './exercises-peek-client-list.types'
import { formatPluralized } from '../../../../../format/text.format'
import { ExercisesPeekClientListItem } from './exercises-peek-client-list-item/exercises-peek-client-list-item.component'
import { isDefAndMoreThenZero } from '../../../../../types/lang.types'
import { EXERCISE_PEEK_BOOKINS_SIZE } from '../../../../../pages/schedule-page/schedule-page-peek-exercise/schedule-page-peek-exercise.types'
import { useExercisesPeekClientList } from './exercises-peek-client-list.hook'
import { ExercisesPeekClientListSearch } from './exercises-peek-client-list-search/exercises-peek-client-list-search.component'
import './exercises-peek-client-list.styles.less'

export const ExercisesPeekClientList: React.FC<ExercisesPeekClientListProps> = props => {
  const {
    exerciseId,
    bookings,
    clientsCount,
    clientsInWaitingList,
    maxClientsCount,
    confirmedCount,
    bookingsTotalElements,
    clientBookingCreatePermission,
    onLoadMoreBookingsHandler,
    onBookingHandler,
    ...rest
  } = props

  const {
    filteredBookings,
    showButtonShowCancelled,
    showCancelled,
    hasMoreBookings,
    bookingsToShow,
    changeShowCancelled,
    handleSearch,
    onQrCodeHandler,
    onWaitingListHandler,
  } = useExercisesPeekClientList({ bookings, exerciseId, clientsCount, confirmedCount, bookingsTotalElements })

  return (
    <Flex vertical gap={15}>
      <Typography.Title className="exercises-peek-client-list__title" level={4}>
        В группе {clientsCount} из {formatPluralized(maxClientsCount, ['человека', 'человек', 'человек'])}
      </Typography.Title>
      <Flex justify="space-between">
        <div style={{ width: '220px' }}>
          <ExercisesPeekClientListSearch onSearch={handleSearch} onQrCode={onQrCodeHandler} />
        </div>
        <Flex gap={10}>
          <Button disabled={!isDefAndMoreThenZero(clientsInWaitingList || 0)} onClick={onWaitingListHandler}>
            {formatPluralized(clientsInWaitingList || 0, ['гость', 'гостя', 'гостей'])} в листе ожидания
          </Button>
          <Tooltip title={!clientBookingCreatePermission ? 'У вас нет разрешения на это действие' : ''}>
            <Button
              type="primary"
              disabled={!clientBookingCreatePermission || clientsCount >= maxClientsCount}
              onClick={onBookingHandler}
            >
              Добавить гостя
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <List
        itemLayout="vertical"
        dataSource={filteredBookings}
        renderItem={item => <ExercisesPeekClientListItem exerciseId={exerciseId} booking={item} {...rest} />}
      />
      {showButtonShowCancelled && (
        <Checkbox value={showCancelled} onChange={changeShowCancelled}>
          Показать отмененные
        </Checkbox>
      )}
      {hasMoreBookings && (
        <Button icon={<ArrowDownOutlined />} type="link" onClick={onLoadMoreBookingsHandler}>
          Показать еще {bookingsToShow >= EXERCISE_PEEK_BOOKINS_SIZE ? EXERCISE_PEEK_BOOKINS_SIZE : bookingsToShow}
        </Button>
      )}
    </Flex>
  )
}
