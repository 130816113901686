import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Layout, Dropdown, Avatar, Button, Flex, Typography, Space } from 'antd'
import { BellOutlined, DownOutlined, UserOutlined } from '@ant-design/icons'
import { clsx } from 'clsx'

import { genSelectedKeys } from './app-layout-top-bar.utils'
import { AppLayoutTopBarMenu, AppLayoutUserMenu } from './app-layout-top-bar.types'
import { genSchedulePagePath } from '../../../../format/path.format'
import { useStudio } from '../../../../hooks/use-studios.hook'
import { ClientsSearchContainer } from '../../../../containers/clients-search/clients-search.container'
import './app-layout-top-bar.styles.less'
import { isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { EmployeesApi } from '../../../../api/types/employees-api.types'

interface Props {
  className?: string
  menuItems: AppLayoutTopBarMenu[]
  userMenu: AppLayoutUserMenu
  theme: string
  currentEmployee: Nullable<EmployeesApi.CurrentEmployee>
}

export const AppLayoutTopBar: React.FC<Props> = props => {
  const { className } = props
  const { menuItems, userMenu } = props
  const { theme, currentEmployee } = props

  const { pathname, search } = useLocation()
  const { studioId } = useStudio()
  const isPageSchedulePage = studioId && genSchedulePagePath(studioId) === pathname

  return (
    <Layout.Header
      className={clsx(
        `app-layout-top-bar ${theme === 'dark' && 'app-layout-top-bar--dark'} ${
          isPageSchedulePage && 'app-layout-top-bar--schedule'
        }`,
        className
      )}
    >
      <Flex align="center" style={{ maxWidth: '250px' }}>
        <ClientsSearchContainer />
      </Flex>

      <Flex
        gap="middle"
        align="center"
        className={clsx('app-layout-top-bar-nav', {
          'app-layout-top-bar-nav--dark': theme === 'dark',
        })}
      >
        {menuItems.map(menu => {
          const { items, title, icon: Icon } = menu

          if (menu.path) {
            return menu.isExternal ? (
              <a
                href={menu.path}
                key={title}
                className="app-layout-top-bar-nav__item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Flex gap="small" align="center">
                  <Icon />
                  {title}
                </Flex>
              </a>
            ) : (
              <Link to={menu.path} key={title} className="app-layout-top-bar-nav__item">
                <Flex gap="small" align="center">
                  <Icon />
                  {title}
                </Flex>
              </Link>
            )
          }
          if (isDefAndNotEmpty(items)) {
            const selectedKeys = genSelectedKeys(items, pathname, search)

            return (
              <Dropdown
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                key={title}
                overlayStyle={{ width: 'max-content' }}
                overlayClassName={clsx('app-layout-top-bar-nav__dropdown', {
                  'app-layout-top-bar-nav__dropdown--dark': theme === 'dark',
                })}
                overlay={
                  <Flex vertical>
                    {items.map(menuItem => (
                      <>
                        <Typography.Text className="app-layout-top-bar-nav__title" strong>
                          {menuItem.title}
                        </Typography.Text>
                        <Menu selectedKeys={selectedKeys} className="app-layout-top-bar-nav__menu">
                          {menuItem.items.map(subMenuItem => {
                            const { icon: Icon, disabled } = subMenuItem
                            return (
                              <Menu.Item key={subMenuItem.title} onClick={subMenuItem.onClick} disabled={disabled}>
                                {subMenuItem.path ? (
                                  subMenuItem.isExternal ? (
                                    <a
                                      href={subMenuItem.path}
                                      style={{ color: 'inherit', whiteSpace: 'nowrap' }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Space size={8}>
                                        {Icon && <Icon />}
                                        <span style={{ whiteSpace: 'nowrap' }}>{subMenuItem.title}</span>
                                      </Space>
                                    </a>
                                  ) : (
                                    <Link to={subMenuItem.path} style={{ color: 'inherit', whiteSpace: 'nowrap' }}>
                                      <Space size={8}>
                                        {Icon && <Icon />}
                                        <span style={{ whiteSpace: 'nowrap' }}>{subMenuItem.title}</span>
                                      </Space>
                                    </Link>
                                  )
                                ) : (
                                  <Space size={8}>
                                    {Icon && <Icon />}
                                    <Typography.Text disabled>{subMenuItem.title}</Typography.Text>
                                  </Space>
                                )}
                              </Menu.Item>
                            )
                          })}
                        </Menu>
                      </>
                    ))}
                  </Flex>
                }
              >
                <Flex gap="small" align="center" className="app-layout-top-bar-nav__button">
                  <Icon />
                  {title}
                  <span className="app-layout-top-bar-nav__icon">
                    <DownOutlined style={{ fontSize: '12px' }} />
                  </span>
                </Flex>
              </Dropdown>
            )
          }
        })}
      </Flex>

      <Flex gap="middle" align="center">
        <Button disabled icon={<BellOutlined />} />

        {userMenu.map(item => {
          return (
            <Dropdown
              arrow
              key={item.title}
              overlay={
                <Menu>
                  <Menu.Item key={item.title} onClick={item.onClick}>
                    {item.title}
                  </Menu.Item>
                </Menu>
              }
            >
              <Flex gap={5} align="center" style={{ cursor: 'pointer' }}>
                <Avatar size={32} icon={<UserOutlined />} />
                {currentEmployee && (
                  <p className="app-layout-top-bar__user">
                    {currentEmployee.lastName ?? ''}{' '}
                    {currentEmployee.firstName ? currentEmployee.firstName[0] + '.' : ''}
                  </p>
                )}
              </Flex>
            </Dropdown>
          )
        })}
      </Flex>
    </Layout.Header>
  )
}
