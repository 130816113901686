import { PromotionsPageSection } from '@pages/promotions-page/promotions-page.types'

import { ContractsInfoPageSection } from '../pages/contracts-info-page/contracts-info-page.types'
import { EMPLOYEE_SCHEDULE_STATUS } from '../constants/employees/schedule'
import { ClientsEditPageSection } from '../pages/clients-edit-page/clients-edit-page.types'
import { ProductsPageSection } from '../pages/products-page/products-page.types'
import { BookingStatus } from '../types/bookings.types'
import { ClientSubscriptionStatus } from '../types/clients-subscriptions.types'
import { ClientSex } from '../types/clients.types'
import { Colors } from '../types/colors.types'
import { Days, Weekdays, Weekend } from '../types/days.types'
import { isDef, NString } from '../types/lang.types'
import { AppPath } from '../types/path.types'
import { ClientPaymentMethod, PaymentMethod, PaymentType } from '../types/payment.types'
import { PositionType } from '../types/positions.types'
import { AdvanceSettlementReceiptStatus, ReceiptType } from '../types/receipt.types'
import { TimeLimitation } from '../types/time-limitation.types'
import { TransactionStatus } from '../types/transactions.types'
import { UserActionSource, UserType } from '../types/users.types'
import { formatRublesPenniesCurrency } from './number.format'

export function formatPathName(path: AppPath): string {
  switch (path) {
    case AppPath.HOME:
      return 'Главная'
    case AppPath.NOT_FOUND:
      return '404'
    case AppPath.SCHEDULE:
      return 'Расписание'
    case AppPath.SCHEDULE_EXERCISE:
      return 'Расписание'
    case AppPath.SCHEDULE_MANAGEMENT:
      return 'Управление расписанием'
    case AppPath.SCHEDULE_MANAGEMENT_EDIT:
      return 'Управление расписанием'
    case AppPath.SCHEDULE_LONGTERM:
      return 'Длинные записи клиентов'
    case AppPath.SCHEDULE_LONGTERM_EDIT:
      return 'Длинные записи клиентов'
    case AppPath.PRODUCTS:
      return 'Абонементы и услуги'
    case AppPath.PRODUCTS_CREATE_SUBSCRIPTION:
      return 'Добавить абонемент'
    case AppPath.PRODUCTS_EDIT_SUBSCRIPTION:
      return 'Редактировать абонемент'
    case AppPath.PRODUCTS_CREATE_SERVICE:
      return 'Добавить услугу'
    case AppPath.PRODUCTS_EDIT_SERVICE:
      return 'Редактировать услугу'
    case AppPath.TRANSACTIONS:
      return 'Транзакции'
    case AppPath.TRANSACTIONS_CREATE:
      return 'Создание транзакции'
    case AppPath.STUDIOS:
      return 'Локации'
    case AppPath.STUDIOS_CREATE:
      return 'Добавить локацию'
    case AppPath.STUDIOS_EDIT:
      return 'Редактировать локацию'
    case AppPath.SALARIES:
      return 'Зарплатный модуль'
    case AppPath.CUSTOM_FIELDS:
      return 'Кастомные поля'
    case AppPath.FRANCHISES:
      return 'Компании'
    case AppPath.EMPLOYEES_SCHEDULE:
      return 'График работы'
    case AppPath.EMPLOYEES_SCHEDULE_CREATE:
      return 'Добавить график работ'
    case AppPath.EMPLOYEES_SCHEDULE_EDIT:
      return 'Редактировать график работ'
    case AppPath.FRANCHISES_CREATE:
      return 'Добавить компанию'
    case AppPath.FRANCHISES_EDIT:
      return 'Редактировать компанию'
    case AppPath.EMPLOYEES:
      return 'Команда'
    case AppPath.EMPLOYEES_CREATE:
      return 'Добавить сотрудника'
    case AppPath.EMPLOYEES_EDIT:
      return 'Редактировать сотрудника'
    case AppPath.DIRECTIONS:
      return 'Групповые направления'
    case AppPath.DIRECTIONS_CREATE:
      return 'Добавить групповое направление'
    case AppPath.DIRECTIONS_EDIT:
      return 'Редактировать групповое направление'
    case AppPath.EXERCISES_TYPES:
      return 'Категории записей'
    case AppPath.EXERCISES_TYPES_CREATE:
      return 'Добавить категорию записей'
    case AppPath.EXERCISES_TYPES_EDIT:
      return 'Редактировать категорию записей'
    case AppPath.CLIENTS:
      return 'Список клиентов'
    case AppPath.CLIENTS_CREATE:
      return 'Добавить клиента'
    case AppPath.CLIENTS_EDIT:
      return 'Редактировать клиента'
    case AppPath.MASTER_SERVICES:
      return 'Персональные услуги'
    case AppPath.MASTER_SERVICES_CREATE:
      return 'Создать услугу'
    case AppPath.MASTER_SERVICES_EDIT:
      return 'Редактировать персональную услугу'
    case AppPath.MASTER_SERVICES_WIDGET_CREATE:
      return 'Настроить виджет'
    case AppPath.POSITIONS:
      return 'Должности и доступы'
    case AppPath.POSITIONS_CREATE:
      return 'Создать новую должность'
    case AppPath.POSITIONS_EDIT:
      return 'Редактировать должность'
    case AppPath.GOODS:
      return 'Товары'
    case AppPath.CONSIGNMENT_CREATE:
      return 'Добавление накладной'
    case AppPath.CONSIGNMENT_EDIT:
      return 'Редактирование накладной'
    case AppPath.ANALYTICS:
      return 'Аналитика'
    case AppPath.REPORTS:
      return 'Отчётность'
    case AppPath.CONTRACTS:
      return 'Контрагенты'
    case AppPath.CONTRACTS_INFO:
      return 'Информация о контрагентах'
    case AppPath.PROMOTIONS:
      return 'Промокоды и акции'
  }
}

export function formatUserType(userType: UserType): string {
  switch (userType) {
    case UserType.Administrators:
      return 'Администраторы'
    case UserType.Trainers:
      return 'Исполнители'
    case UserType.Managers:
      return 'Менеджеры'
    case UserType.All:
      return 'Все сотрудники'
  }
}

export function formatPositionType(position: PositionType): string {
  switch (position) {
    case PositionType.ADMINISTRATOR:
      return 'Администратор'
    case PositionType.MANAGER:
      return 'Менеджер'
    case PositionType.EXECUTOR:
      return 'Исполнитель'
    case PositionType.CUSTOM:
      return 'Другой тип'
  }
}

export function formatFullName(firstName: NString, lastName: NString, middleName?: NString): string {
  if (firstName || lastName) {
    return `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`
  }
  return DEFAULT_EMPTY_SYMBOL
}

export function formatProductsPageSectionName(section: ProductsPageSection): string {
  switch (section) {
    case ProductsPageSection.GROUP_SUBSCRIPTIONS:
      return 'Абонементы'
    case ProductsPageSection.INDIVIDUAL_SUBSCRIPTIONS:
      return 'Абонементы индивидуальные'
    case ProductsPageSection.SERVICES:
      return 'Разовые услуги'
  }
}

export function formatContractsInfoPageSectionName(section: ContractsInfoPageSection): string {
  switch (section) {
    case ContractsInfoPageSection.DESCRIPTION:
      return 'Общая информация'
    case ContractsInfoPageSection.CLIENTS:
      return 'Сотрудники'
    case ContractsInfoPageSection.BOOKING_AND_PRODUCTS:
      return 'Записи и услуги'
    case ContractsInfoPageSection.CONTRACTS:
      return 'Договоры'
  }
}

export function formatPromotionsPageSectionName(section: PromotionsPageSection): string {
  switch (section) {
    case PromotionsPageSection.CODES:
      return 'Промокоды'
    case PromotionsPageSection.SALES:
      return 'Акции'
  }
}

export function formatPluralized(num: number, ends: string[]): string {
  const n = Math.abs(num) % 100
  const remainder = n % 10

  if (n > 10 && n < 20) {
    return `${num} ${ends[2]}`
  }

  if (remainder > 1 && remainder < 5) {
    return `${num} ${ends[1]}`
  }

  if (remainder === 1) {
    return `${num} ${ends[0]}`
  }

  return `${num} ${ends[2]}`
}

export function formatDays(day: Days, full: boolean = false): string {
  switch (day) {
    case Days.MONDAY:
      return full ? 'Понедельник' : 'Пн'
    case Days.TUESDAY:
      return full ? 'Вторник' : 'Вт'
    case Days.WEDNESDAY:
      return full ? 'Среда' : 'Ср'
    case Days.THURSDAY:
      return full ? 'Четверг' : 'Чт'
    case Days.FRIDAY:
      return full ? 'Пятница' : 'Пт'
    case Days.SATURDAY:
      return full ? 'Суббота' : 'Сб'
    case Days.SUNDAY:
      return full ? 'Воскресенье' : 'Вс'
  }
}

export function formatWeekdays(day: Weekdays): string {
  switch (day) {
    case Weekdays.MONDAY:
      return 'Понедельник'
    case Weekdays.TUESDAY:
      return 'Вторник'
    case Weekdays.WEDNESDAY:
      return 'Среда'
    case Weekdays.THURSDAY:
      return 'Четверг'
    case Weekdays.FRIDAY:
      return 'Пятница'
  }
}

export function formatWeekend(day: Weekend): string {
  switch (day) {
    case Weekend.SATURDAY:
      return 'Суббота'
    case Weekend.SUNDAY:
      return 'Воскресенье'
  }
}

export function formatColors(color: Colors): string {
  switch (color) {
    case Colors.RED:
      return 'Красный'
    case Colors.VOLCANO:
      return 'Вулканический'
    case Colors.ORANGE:
      return 'Оранжевый'
    case Colors.GOLD:
      return 'Золотой'
    case Colors.YELLOW:
      return 'Желтый'
    case Colors.LIME:
      return 'Лаймовый'
    case Colors.GREEN:
      return 'Зеленый'
    case Colors.CYAN:
      return 'Голубой'
    case Colors.BLUE:
      return 'Рассветный Синий'
    case Colors.GEEKBLUE:
      return 'Синий'
    case Colors.PURPLE:
      return 'Фиолетовый'
    case Colors.MAGENTA:
      return 'Пурпурный'
  }
}

export function formatPaymentType(paymentType: PaymentType): string {
  switch (paymentType) {
    case PaymentType.ON_PLACE:
      return 'Оплата на месте'
    case PaymentType.ONE_TIME:
      return 'Единоразовая оплата'
    case PaymentType.SUBSCRIPTION:
      return 'Абонемент'
    case PaymentType.WAITING:
      return 'Ожидаем оплату'
    case PaymentType.CREATE_TRANSACTION:
      return 'Создание транзакции'
    case PaymentType.RESERVED:
      return 'Ожидаем оплату'
    case PaymentType.TRIAL:
      return 'Единоразовая оплата, Пробник'
  }
}

export function formatPaymentMethod(paymentType: PaymentMethod): string {
  switch (paymentType) {
    case PaymentMethod.CARD:
      return 'Карта'
    case PaymentMethod.CASH:
      return 'Наличные'
    case PaymentMethod.SMS:
      return 'СМС'
    case PaymentMethod.QR:
      return 'QR'
    case PaymentMethod.NO_PAYMENT:
      return 'Без оплаты'
    case PaymentMethod.CONTRACT:
      return 'Договор'
  }
}

export function formatTransactionStatus(status: TransactionStatus): string {
  switch (status) {
    case TransactionStatus.PAID:
      return 'Оплачено'
    case TransactionStatus.UNPAID:
      return 'Не оплачено'
    case TransactionStatus.REFUND:
      return 'Возврат'
    case TransactionStatus.WAITING:
      return 'Ожидает оплаты'
  }
}

export function formatClientSex(sex: ClientSex): string {
  switch (sex) {
    case ClientSex.F:
      return 'Женский'
    case ClientSex.M:
      return 'Мужской'
    case ClientSex.U:
      return 'Не указано'
  }
}

export function formatPluralize(amount: number, variants: string[]): string {
  const n = Math.abs(amount) % 100
  const n1 = n % 10

  if (n > 10 && n < 20) {
    return variants[2]
  }

  if (n1 > 1 && n1 < 5) {
    return variants[1]
  }

  if (n1 === 1) {
    return variants[0]
  }

  return variants[2]
}

export function formatIdContract(id: string): string {
  if (id.length <= 8) {
    return id
  }
  return id.slice(0, 8) + '...' + id.slice(-8)
}

export function formatClientsEditPageSection(section: ClientsEditPageSection): string {
  switch (section) {
    case ClientsEditPageSection.OVERVIEW:
      return 'Карточка'
    case ClientsEditPageSection.BOOKINGS_ACTIVE:
      return 'Активные записи'
    case ClientsEditPageSection.BOOKINGS_HISTORY:
      return 'История посещений'
    case ClientsEditPageSection.PURCHASES:
      return 'Покупки'
    case ClientsEditPageSection.SUBSCRIPTIONS:
      return 'Абонементы'
  }
}

export function formatClientSubscriptionStatus(status: ClientSubscriptionStatus): string {
  switch (status) {
    case ClientSubscriptionStatus.NEW:
      return 'Ожидает активации'
    case ClientSubscriptionStatus.ACTIVE:
      return 'Активный'
    case ClientSubscriptionStatus.EXPIRED:
      return 'Истек'
    case ClientSubscriptionStatus.HOLD:
      return 'Заморожен'
    case ClientSubscriptionStatus.REFUNDED:
      return 'Возврат'
    case ClientSubscriptionStatus.NO_VISITS:
      return 'Баланс исчерпан'
  }
}

export function formatClientsPaymentMethod(paymentMethod: ClientPaymentMethod): string {
  switch (paymentMethod) {
    case ClientPaymentMethod.CARD:
      return 'Карта в локации'
    case ClientPaymentMethod.CASH:
      return 'Наличные'
    case ClientPaymentMethod.NO_PAYMENT:
      return 'Без оплаты'
    case ClientPaymentMethod.CONTRACT:
      return 'Контракт'
    default:
      return 'Онлайн'
  }
}

export function formatRefundTextByPaymentMethod(paymentMethod: ClientPaymentMethod, sum: number): string {
  const rubles = formatRublesPenniesCurrency(sum)
  switch (paymentMethod) {
    case ClientPaymentMethod.CASH:
      return `Подготовьте сумму к возврату в размере ${rubles}. И после подтвердите возврат.`
    case ClientPaymentMethod.CARD:
      return `Вставьте карту клиента в терминал и произведите возврат на сумму ${rubles}. И после этого нажмите кнопку Подтверждаю.`
    default:
      return `К возврату ${rubles}. Покупка была оплачена онлайн. Возврат будет произведен после подтверждения.`
  }
}

export function getClientPaymentLocation(paymentMethod: ClientPaymentMethod, studioName: string): string {
  switch (paymentMethod) {
    case ClientPaymentMethod.APP:
      return 'Приложение'
    case ClientPaymentMethod.WEB:
      return 'Сайт'
    default:
      return studioName
  }
}

export function formatTimeLimitation(timeLimitation: TimeLimitation): string {
  switch (timeLimitation) {
    case TimeLimitation.NONE:
      return 'Весь день'
    case TimeLimitation.UNTIL_1645:
      return 'До 16:45'
  }
}

export function formatReceiptNumber(number: NString, isWaitingStatus: boolean): string {
  return `ОФД: №${number ? number : isWaitingStatus ? 'ожидание отправки' : 'ошибка отправки'}`
}

export function formatText(text?: NString): string {
  return isDef(text) ? text : DEFAULT_EMPTY_SYMBOL
}

export function formatTextToStrikeText(text: string) {
  return text
    .split('')
    .map(char => char + '\u0336')
    .join('')
}

export const DEFAULT_EMPTY_SYMBOL = '-'

export function formatBookingStatus(status: BookingStatus): string {
  switch (status) {
    case BookingStatus.VISIT_CONFIRMED:
      return 'Визит подтвержден'
    case BookingStatus.NOT_VISITED_CONFIRMED:
      return 'Визит не подтвержден'
    case BookingStatus.BOOKED:
      return 'Забронировано'
    case BookingStatus.CANCELLED:
      return 'Отменено'
  }
}

export function formatBooleanValue(value: boolean): string {
  return value ? 'да' : 'нет'
}

export function formatUserActionSource(source: UserActionSource): string {
  switch (source) {
    case UserActionSource.APP:
      return 'Приложение'
    case UserActionSource.WEB:
      return 'Сайт'
    case UserActionSource.ADMIN:
      return 'Администратор'
    case UserActionSource.SERVER:
      return 'Платформа'
    case UserActionSource.WIDGET:
      return 'Виджет'
  }
}

export function formatAdvanceSettlementReceiptStatus(receiptStatus: AdvanceSettlementReceiptStatus): string {
  switch (receiptStatus) {
    case AdvanceSettlementReceiptStatus.INITIAL:
      return 'чек не отправлен'
    case AdvanceSettlementReceiptStatus.NOT_REQUIRED:
      return 'чек не нужен'
    case AdvanceSettlementReceiptStatus.PENDING:
      return 'попытка отправки чека'
    case AdvanceSettlementReceiptStatus.SUCCESS:
      return 'успешно'
    case AdvanceSettlementReceiptStatus.ERROR:
      return 'ошибка'
    case AdvanceSettlementReceiptStatus.UNKNOWN:
      return 'неизвестно'
  }
}

export function formatEmployeeScheduleStatus(scheduleStatus: EMPLOYEE_SCHEDULE_STATUS): string {
  switch (scheduleStatus) {
    case EMPLOYEE_SCHEDULE_STATUS.ACTIVE:
      return 'установлено'
    case EMPLOYEE_SCHEDULE_STATUS.ARCHIVE:
      return 'архив'
    case EMPLOYEE_SCHEDULE_STATUS.DRAFT:
      return 'черновик'
    case EMPLOYEE_SCHEDULE_STATUS.PAUSED:
      return 'приостановлено'
  }
}

export function formatTransactionReceiptType(receiptType: ReceiptType): string {
  switch (receiptType) {
    case ReceiptType.AGENT_COMMISSION:
      return 'Агентский сбор'
    case ReceiptType.FULL_PAYMENT_SERVICE_REFUND:
      return 'Чек возврата'
    case ReceiptType.FULL_PAYMENT_SERVICE_SELL:
      return 'Полный расчёт'
    case ReceiptType.GOODS_REFUND:
      return 'Возврат за товар'
    case ReceiptType.GOODS_SELL:
      return 'Полный расчёт'
    case ReceiptType.SERVICE_REFUND:
      return 'Чек возврата'
    case ReceiptType.SERVICE_REFUND_COMMISSION:
      return 'Сервисный сбор'
    case ReceiptType.SERVICE_SELL:
      return 'Полный расчёт'
    case ReceiptType.SUBSCRIPTION_REFUND:
      return 'Чек возврата'
    case ReceiptType.SUBSCRIPTION_REFUND_COMMISSION:
      return 'Сервисный сбор'
    case ReceiptType.SUBSCRIPTION_SELL:
      return 'Полный расчёт'
    case ReceiptType.SUBSCRIPTION_VISIT:
      return 'Зачёт аванса, посещение'
    case ReceiptType.VISIT_REFUND_CORRECTION:
      return 'Чек возврата'
  }
}
