import * as React from 'react'

import { SchedulePageModalBookFromWaitingProps } from './schedule-page-modal-book-from-waiting.types'
import { useSchedulePageModalBookFromWaiting } from './schedule-page-modal-book-from-waiting.hook'
import { ExercisesBookFromWaitingModal } from '../../../components/exercises/exercises-book-from-waiting-modal/exercises-book-from-waiting-modal.component'

export const SchedulePageModalBookFromWaiting: React.FC<SchedulePageModalBookFromWaitingProps> = props => {
  const { scheduleId, phone, name } = props

  const { form, loading, paymentTypesOptions, placesOptions, onSaveHandler, onCancelHandler } =
    useSchedulePageModalBookFromWaiting(scheduleId, phone)

  return (
    <ExercisesBookFromWaitingModal
      form={form}
      loading={loading}
      paymentTypesOptions={paymentTypesOptions}
      placesOptions={placesOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
      name={name}
    />
  )
}
