import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { SchedulePageModalBookingCreateBookingPayload } from './schedule-page-modal-booking.types'

export interface SchedulePageModalBookingState {
  exercisesSpots: Nullable<ExercisesApi.ExerciseSpot[]>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: SchedulePageModalBookingState = {
  exercisesSpots: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: schedulePageModalBookingActions, reducer: schedulePageModalBookingReducer } = createSlice({
  name: '@@schedule-page-modal-booking',
  initialState,
  reducers: {
    fetchExercisesSpots: (state: Draft<SchedulePageModalBookingState>, _: PayloadAction<string>): void => {
      state.isLoading = true
    },
    fetchExercisesSpotsSuccess: (
      state: Draft<SchedulePageModalBookingState>,
      action: PayloadAction<Nullable<ExercisesApi.ExerciseSpot[]>>
    ): void => {
      state.isLoading = false
      state.isLoaded = true
      state.exercisesSpots = action.payload
    },
    fetchExercisesSpotsError: (state: Draft<SchedulePageModalBookingState>, action: PayloadAction<Error>): void => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },

    createBooking: (
      state: Draft<SchedulePageModalBookingState>,
      _: PayloadAction<SchedulePageModalBookingCreateBookingPayload>
    ): void => {
      state.isLoading = true
    },
    createBookingSuccess: (state: Draft<SchedulePageModalBookingState>): void => {
      state.isLoading = false
    },
    createBookingError: (state: Draft<SchedulePageModalBookingState>, action: PayloadAction<Error>): void => {
      state.isLoading = false
      state.error = action.payload
    },

    reset: () => initialState,
  },
})
