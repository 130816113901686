import { FormInstance } from 'antd'

import { isDef } from '../../../types/lang.types'
import { ExercisesBookFromWaitingFormValues } from './exercises-book-from-waiting-modal-form/exercises-book-from-waiting-modal-form.types'

export function genExercisesBookFromWaitingFormIsValid(form: FormInstance<ExercisesBookFromWaitingFormValues>) {
  const placeIsValid = isDef(form.getFieldValue('place'))
  const paymentTypeIsValid = isDef(form.getFieldValue('paymentType'))

  return placeIsValid && paymentTypeIsValid
}
