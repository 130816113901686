import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useForm } from 'antd/es/form/Form'
import * as React from 'react'
import { Dayjs } from 'dayjs'

import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { isDef } from '../../../../../types/lang.types'
import {
  getPromotionsSaleFormValue,
  getPromotionsSalesIsEditing,
  getPromotionsSalesIsModalLoading,
  getPromotionsSalesProductsList,
  getSalesEditSelectedFranchises,
} from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.selectors'
import { promotionsPageSalesEditActions } from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.slice'

export const useSalesEdit = (id: string) => {
  const dispatch = useDispatch()
  const salesForm = useSelector(getPromotionsSaleFormValue)
  const productsSalesList = useSelector(getPromotionsSalesProductsList)
  const promotionsEditSelectedFranchises = useSelector(getSalesEditSelectedFranchises)
  const isEditing = useSelector(getPromotionsSalesIsEditing)
  const isModalLoading = useSelector(getPromotionsSalesIsModalLoading)
  const [form] = useForm<PromotionsApi.Promotion<Dayjs>>()

  const onSearchHandler = React.useCallback(
    (name: string, type: PromotionsApi.PromotionProductType): void => {
      dispatch(
        promotionsPageSalesEditActions.searchPromotionsProducts({
          name,
          type,
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(promotionsPageSalesEditActions.fetchPromotionsSalesById({ id }))
  }, [dispatch, id])

  React.useEffect(() => {
    dispatch(promotionsPageSalesEditActions.fetchPromotionsModalData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesEditActions.reset())
    }
  }, [dispatch])

  React.useEffect((): void => {
    if (isDef(salesForm)) {
      form.setFieldsValue(salesForm)
    }
  }, [form, salesForm])

  return {
    form,
    isEditing,
    isModalLoading,
    productsSalesList,
    onSearchHandler,
    promotionsEditSelectedFranchises,
  }
}
