import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import { useForm } from 'antd/es/form/Form'
import { Dayjs } from 'dayjs'

import { fileActions } from '../../../../../store/common/files/files.slice'
import { contractsInfoPageContractsEditActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-edit/contracts-info-page-contracts-edit.slice'
import { getManagersCardsList } from '../../../../../store/pages/contracts-page/contracts-page.selectors'
import { contractsPageActions } from '../../../../../store/pages/contracts-page/contracts-page.slice'
import { isDef } from '../../../../../types/lang.types'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import {
  getCounterpartyContractFormValue,
  getCounterpartyContractIsEditing,
  getCounterpartyContractsIsLoadingById,
} from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-edit/contracts-info-page-contracts-edit.selectors'

export const useModalEdit = (id: string) => {
  const dispatch = useDispatch()
  const [form] = useForm<ContractsApi.ContractsDTO<Dayjs>>()
  const contractForm = useSelector(getCounterpartyContractFormValue)
  const managers = useSelector(getManagersCardsList)
  const isEditing = useSelector(getCounterpartyContractIsEditing)
  const isLoading = useSelector(getCounterpartyContractsIsLoadingById)

  const paymentModel = [
    {
      value: 'ONE_TIME',
      label: 'Разовая оплата',
    },
    {
      value: 'PERIODIC',
      label: 'Периодическая оплата',
    },
    {
      value: 'VALUE_SPENT',
      label: 'По обороту в период',
    },
    {
      value: 'VALUE_LEFT',
      label: 'По остатку баланса в период',
    },
  ]

  const handleUploadPdf = React.useCallback(
    (pdf: any) => {
      if (isDef(pdf.fileList[0])) {
        dispatch(fileActions.uploadFile(pdf))
      }
    },
    [dispatch]
  )

  React.useEffect((): void => {
    if (isDef(contractForm)) {
      form.setFieldsValue(contractForm)
    }
  }, [contractForm, form])

  React.useEffect(() => {
    return () => {
      dispatch(contractsInfoPageContractsEditActions.reset())
    }
  }, [dispatch, form])

  useEffect(() => {
    dispatch(contractsInfoPageContractsEditActions.fetchContractById({ id }))
    dispatch(contractsPageActions.fetchAllManagers())
  }, [dispatch, id])

  return {
    form,
    managers,
    isEditing,
    isLoading,
    handleUploadPdf,
    paymentModel,
  }
}
