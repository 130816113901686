import * as React from 'react'

import {
  genExercisesBookFromWaitingModalFormPlaceValidationRules,
  genExercisesBookFromWaitingModalFormPaymentTypeValidationRules,
} from './exercises-book-from-waiting-modal-form.utils'

export function useExercisesBookFromWaitingModalForm() {
  const placeValidationRules = React.useMemo(genExercisesBookFromWaitingModalFormPlaceValidationRules, [])
  const paymentTypeValidationRules = React.useMemo(genExercisesBookFromWaitingModalFormPaymentTypeValidationRules, [])

  return {
    placeValidationRules,
    paymentTypeValidationRules,
  }
}
