import React, { useState } from 'react'
import { Button, DatePicker, Divider, Flex, Form, Input, Modal, Select, Switch, Typography } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { PlusOutlined } from '@ant-design/icons'
import { DefaultOptionType } from 'antd/lib/select'
import { useDispatch } from 'react-redux'
import { Dayjs } from 'dayjs'

import { ContractsModalContainer } from '../../../../../components/contracts/contracts-modal-container/contracts-modal-container.component'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { genDefaultDateFormat } from '../../../../../format/date.format'
import { validateNumericInput } from '../../../../../utils/form/validations-rules-form'
import { fileActions } from '../../../../../store/common/files/files.slice'
import { AppModalBaseProps } from '../../../../../types/modal.types'
import { useModalEdit } from './contracts-info-modal-edit.hook'

export interface ContractModalEditProps extends AppModalBaseProps {
  handleOk: (id: string, data: ContractsApi.ContractsDTO<Dayjs>) => void
  handleCancel: () => void
  managers?: DefaultOptionType[]
  contractId: string
}

export const ContractModalEdit = ({ handleOk, handleCancel, contractId }: ContractModalEditProps) => {
  const { paymentModel, form, managers, isEditing, isLoading, handleUploadPdf } = useModalEdit(contractId)
  const [paymentMethod, setPaymentMethod] = useState('ONE_TIME')
  const switchValue = Form.useWatch('disablePaymentsOnMaxAmount', form)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const initialPaymentMethod = form.getFieldValue('paymentModel') || paymentModel[0].value
    setPaymentMethod(initialPaymentMethod)
    return () => {
      dispatch(fileActions.reset())
    }
  }, [dispatch, form, paymentModel])

  function handleChangePaymentMethod(method: string) {
    setPaymentMethod(method)
  }

  function handleFinish(data: ContractsApi.ContractsDTO<Dayjs>) {
    handleOk(contractId, data)
  }

  const onSwitchChange = () => {
    const newValue = !form.getFieldValue('disablePaymentsOnMaxAmount')
    form.setFieldsValue({
      disablePaymentsOnMaxAmount: newValue,
    })
  }

  return (
    <Modal
      title="Редактирование договора"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" htmlType="submit" disabled={isEditing} loading={isEditing}>
          Сохранить изменения
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          autoComplete="off"
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
          disabled={isLoading}
          onFinish={handleFinish}
          form={form}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      <Typography.Title level={5}>Загрузить договор в формате PDF</Typography.Title>
      <Dragger
        style={{ height: '104px' }}
        maxCount={1}
        accept=".pdf"
        onChange={handleUploadPdf}
        beforeUpload={() => false}
      >
        <p>
          <PlusOutlined color={'rgba(0, 0, 0, 0.45)'} />
        </p>
        <p>Загрузите файл для замены текущего документа (макс 50мб)</p>
      </Dragger>
      <Typography.Title level={5}>Условия договора</Typography.Title>
      <ContractsModalContainer>
        <Form.Item
          name="name"
          label="Название или номер договора"
          rules={[
            {
              required: true,
              message: 'Введите Название или номер договора!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Flex justify="space-between" gap="small" style={{ width: '100%' }}>
          <Form.Item
            style={{ width: '100%' }}
            name="validSince"
            label="Начало действия"
            rules={[
              {
                required: true,
                message: 'Выберите дату начала договора!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const validUntil = getFieldValue('validUntil')
                  if (!value || !validUntil || !value.isAfter(validUntil)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Дата начала не может быть позже даты окончания!'))
                },
              }),
            ]}
          >
            <DatePicker style={{ width: '100%' }} format={genDefaultDateFormat()} />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="validUntil"
            label="Конец действия"
            dependencies={['validSince']}
            rules={[
              {
                required: true,
                message: 'Выберите дату конца договора!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const validSince = getFieldValue('validSince')
                  if (!value || !validSince || !value.isBefore(validSince)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Дата конца не может быть раньше даты начала!'))
                },
              }),
            ]}
          >
            <DatePicker style={{ width: '100%' }} format={genDefaultDateFormat()} />
          </Form.Item>
        </Flex>
        <Form.Item
          name="maxTotalAmount"
          label="Сумма договора"
          rules={[
            {
              required: true,
              message: 'Введите Сумму договора!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} suffix="₽" />
        </Form.Item>
        <Form.Item
          name="paymentModel"
          label="Метод выставления счёта"
          initialValue={paymentModel[0].value}
          rules={[
            {
              required: true,
              message: 'Выберите Срок выставления счёта!',
            },
          ]}
        >
          <Select options={paymentModel} onChange={handleChangePaymentMethod} />
        </Form.Item>
        {paymentMethod === 'PERIODIC' && (
          <>
            <Form.Item
              name="months"
              label="Периодичность списания оплаты (в месяцах)"
              rules={[
                {
                  required: true,
                  message: 'Введите Периодичность списания оплаты!',
                },
              ]}
            >
              <Input type="textarea" onKeyPress={validateNumericInput} />
            </Form.Item>
            <Form.Item
              name="day"
              label="Число месяца внесения оплаты"
              rules={[
                {
                  required: true,
                  message: 'Введите Число внесения оплаты!',
                },
              ]}
            >
              <Input type="textarea" onKeyPress={validateNumericInput} />
            </Form.Item>
          </>
        )}
        {paymentMethod === 'VALUE_SPENT' && (
          <>
            <Form.Item
              name="value"
              label="Пороговая сумма"
              rules={[
                {
                  required: true,
                  message: 'Введите Пороговую сумму для уведомления!',
                },
              ]}
            >
              <Input type="textarea" onKeyPress={validateNumericInput} suffix="₽" />
            </Form.Item>
          </>
        )}
        {paymentMethod === 'VALUE_LEFT' && (
          <>
            <Form.Item
              name="value"
              label="Пороговая сумма"
              rules={[
                {
                  required: true,
                  message: 'Введите Пороговую сумму для уведомления!',
                },
              ]}
            >
              <Input type="textarea" onKeyPress={validateNumericInput} suffix="₽" />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="disablePaymentsOnMaxAmount"
          valuePropName="checked"
          rules={[
            {
              required: false,
              message: 'Введите Срок внесения оплаты!',
            },
          ]}
        >
          <Flex style={{ cursor: 'pointer' }} onClick={onSwitchChange} gap={'small'} align="center">
            <Switch size="small" checked={switchValue} />
            <Typography>Запретить оплату при достижении лимита баланса</Typography>
          </Flex>
        </Form.Item>
      </ContractsModalContainer>
      <Typography.Title level={5}>Менеджер договора</Typography.Title>
      <ContractsModalContainer>
        <Form.Item
          name="managerId"
          label="Выберите менеджера"
          rules={[
            {
              required: true,
              message: 'Выберите менеджера!',
            },
          ]}
        >
          <Select options={managers} />
        </Form.Item>
      </ContractsModalContainer>
    </Modal>
  )
}
