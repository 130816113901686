import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { Dayjs } from 'dayjs'

import { PromotionsCodesFetchByIdPayload } from '../promotions-page-codes-create/promotions-page-codes-create.types'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { Nullable } from '../../../../../types/lang.types'
import { Pagination } from '../../../../../api/types/api.types'

export interface PromotionsPageCodesEditState {
  codes: Nullable<Pagination<PromotionsApi.PromotionsCodesShort>>
  code: Nullable<PromotionsApi.Code>
  codePattern: Nullable<PromotionsApi.CodePattern>
  isModalLoading: boolean
  isModalLoaded: boolean
  isEditing: boolean
  isEdited: boolean
  error: Nullable<Error>
}

const initialState: PromotionsPageCodesEditState = {
  codes: null,
  code: null,
  codePattern: null,
  isModalLoading: false,
  isModalLoaded: false,
  isEditing: false,
  isEdited: false,
  error: null,
}

export const { actions: promotionsPageCodesEditActions, reducer: promotionsPageCodesEditReducer } = createSlice({
  name: '@@promotions-page-codes-edit',
  initialState,
  reducers: {
    fetchPromotionsCodesById: (
      state: Draft<PromotionsPageCodesEditState>,
      _: PayloadAction<PromotionsCodesFetchByIdPayload>
    ) => {
      state.isModalLoading = true
    },
    fetchPromotionsCodesByIdSuccess: (
      state: Draft<PromotionsPageCodesEditState>,
      action: PayloadAction<Nullable<PromotionsApi.Code>>
    ) => {
      state.code = action.payload
      state.isModalLoading = false
      state.isModalLoaded = true
    },
    fetchPromotionsCodesByIdError: (state: Draft<PromotionsPageCodesEditState>, action: PayloadAction<Error>) => {
      state.isModalLoading = false
      state.isModalLoaded = true
      state.error = action.payload
    },
    fetchPromotionsCodesPatternById: (
      state: Draft<PromotionsPageCodesEditState>,
      _: PayloadAction<PromotionsCodesFetchByIdPayload>
    ) => {
      state.isModalLoading = true
    },
    fetchPromotionsCodesPatternByIdSuccess: (
      state: Draft<PromotionsPageCodesEditState>,
      action: PayloadAction<Nullable<PromotionsApi.CodePattern>>
    ) => {
      state.codePattern = action.payload
      state.isModalLoading = false
      state.isModalLoaded = true
    },
    fetchPromotionsCodesPatternByIdError: (
      state: Draft<PromotionsPageCodesEditState>,
      action: PayloadAction<Error>
    ) => {
      state.isModalLoading = false
      state.isModalLoaded = true
      state.error = action.payload
    },
    resetPromotionsCodesById: (state: Draft<PromotionsPageCodesEditState>) => {
      state.isModalLoading = false
      state.isModalLoaded = false
      state.code = null
      state.codePattern = null
    },
    updatePromotionsCodes: (
      state: Draft<PromotionsPageCodesEditState>,
      _: PayloadAction<{ id: string; data: PromotionsApi.CodeDTO<Dayjs> }>
    ) => {
      state.isEditing = true
    },
    updatePromotionsCodesSuccess: (state: Draft<PromotionsPageCodesEditState>, _: PayloadAction<void>) => {
      state.isEditing = false
      state.isEdited = true
    },
    updatePromotionsCodesError: (state: Draft<PromotionsPageCodesEditState>, action: PayloadAction<Error>) => {
      state.isEditing = false
      state.isEdited = true
      state.error = action.payload
    },
    updatePromotionsCodesPattern: (
      state: Draft<PromotionsPageCodesEditState>,
      _: PayloadAction<{ id: string; data: PromotionsApi.CodePatternDTO<Dayjs> }>
    ) => {
      state.isEditing = true
    },
    updatePromotionsCodesPatternSuccess: (state: Draft<PromotionsPageCodesEditState>, _: PayloadAction<void>) => {
      state.isEditing = false
      state.isEdited = true
    },
    updatePromotionsCodesPatternError: (state: Draft<PromotionsPageCodesEditState>, action: PayloadAction<Error>) => {
      state.isEditing = false
      state.isEdited = true
      state.error = action.payload
    },
    generatePromotionsCodesPattern: (
      state: Draft<PromotionsPageCodesEditState>,
      _: PayloadAction<{ id: string; count: number }>
    ) => {
      state.isEditing = true
    },
    generatePromotionsCodesPatternSuccess: (
      state: Draft<PromotionsPageCodesEditState>,
      _: PayloadAction<{ id: string; name: string }[]>
    ) => {
      state.isEditing = false
      state.isEdited = true
    },
    generatePromotionsCodesPatternError: (state: Draft<PromotionsPageCodesEditState>, action: PayloadAction<Error>) => {
      state.isEditing = false
      state.isEdited = true
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
