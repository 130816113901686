import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { SchedulePageModalBookFromWaitingCreateBookingPayload } from './schedule-page-modal-book-from-waiting.types'

export interface SchedulePageModalBookFromWaitingState {
  exercisesSpots: Nullable<ExercisesApi.ExerciseSpot[]>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: SchedulePageModalBookFromWaitingState = {
  exercisesSpots: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: schedulePageModalBookFromWaitingActions, reducer: schedulePageModalBookFromWaitingReducer } =
  createSlice({
    name: '@@schedule-page-modal-book-from-waiting',
    initialState,
    reducers: {
      fetchExercisesSpots: (state: Draft<SchedulePageModalBookFromWaitingState>, _: PayloadAction<string>): void => {
        state.isLoading = true
      },
      fetchExercisesSpotsSuccess: (
        state: Draft<SchedulePageModalBookFromWaitingState>,
        action: PayloadAction<Nullable<ExercisesApi.ExerciseSpot[]>>
      ): void => {
        state.isLoading = false
        state.isLoaded = true
        state.exercisesSpots = action.payload
      },
      fetchExercisesSpotsError: (
        state: Draft<SchedulePageModalBookFromWaitingState>,
        action: PayloadAction<Error>
      ): void => {
        state.isLoading = false
        state.isLoaded = true
        state.error = action.payload
      },

      createBooking: (
        state: Draft<SchedulePageModalBookFromWaitingState>,
        _: PayloadAction<SchedulePageModalBookFromWaitingCreateBookingPayload>
      ): void => {
        state.isLoading = true
      },
      createBookingSuccess: (state: Draft<SchedulePageModalBookFromWaitingState>): void => {
        state.isLoading = false
      },
      createBookingError: (state: Draft<SchedulePageModalBookFromWaitingState>, action: PayloadAction<Error>): void => {
        state.isLoading = false
        state.error = action.payload
      },

      reset: () => initialState,
    },
  })
