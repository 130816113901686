import React, { useEffect } from 'react'
import { Button, DatePicker, Typography, Divider, Flex, Form, Input, Modal } from 'antd'
import { Dayjs } from 'dayjs'

import { genDefaultDateFormat } from '../../../../../format/date.format'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { useCodeEdit } from './promotions-page-codes-modal-edit.hook'
import { PromotionsCodesModalEditProps } from './promotions-page-codes-modal-edit.types'
import { PromotionsFormLimitation } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-limitation/promotions-create-form-limitation.component'

export const PromotionsCodesModalEdit = ({ handleOk, handleCancel, isPattern, id }: PromotionsCodesModalEditProps) => {
  const { form, onSearchHandler, isEditing, isModalLoading, productsCodesList, promotionsEditSelectedFranchises } =
    useCodeEdit(id, isPattern)

  useEffect(() => {
    form.setFieldValue('limitedByOrganization', promotionsEditSelectedFranchises)
  }, [form, promotionsEditSelectedFranchises])

  function handleFinishModal(value: PromotionsApi.CodeDTO<Dayjs>) {
    handleOk(id, value)
  }

  return (
    <Modal
      title="Редактирование промокода"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          disabled={isEditing || isModalLoading}
          loading={isEditing || isModalLoading}
        >
          Редактировать промокод
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          layout="vertical"
          name="form_in_modal"
          form={form}
          onFinish={handleFinishModal}
          disabled={isModalLoading}
          initialValues={{
            products: [{}],
          }}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      <Flex gap={'large'} vertical>
        <Flex vertical gap={'middle'}>
          <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
            Основная информация
          </Typography.Title>
          <Form.Item
            style={{ flex: '1' }}
            name="name"
            label="Название промокампании"
            rules={[{ required: true, message: 'Введите название промокампании!' }]}
          >
            <Input style={{ width: '100%' }} size={'large'} />
          </Form.Item>

          <Flex gap={'middle'}>
            <Form.Item style={{ flex: '1' }} name="activeFrom" label="Начало действия">
              <DatePicker style={{ width: '100%' }} size={'large'} format={genDefaultDateFormat()} />
            </Form.Item>
            <Form.Item style={{ flex: '1' }} name="activeTo" label="Конец действия">
              <DatePicker style={{ width: '100%' }} size={'large'} format={genDefaultDateFormat()} />
            </Form.Item>
          </Flex>
        </Flex>

        {/*<PromotionsFormProducts onSearchHandler={onSearchHandler} form={form} productsList={productsCodesList} />*/}
        <PromotionsFormLimitation form={form} />
      </Flex>
    </Modal>
  )
}
