import React from 'react'
import { Flex, Form, FormInstance, Input, Select, Typography } from 'antd'

import { usePromotionsFormLimitation } from './promotions-create-form-limitation.hook'
import { validateNumericInput } from '../../../../utils/form/validations-rules-form'

interface PromotionsFormLimitationProps {
  form: FormInstance
}

export const PromotionsFormLimitation = ({ form }: PromotionsFormLimitationProps) => {
  const {
    franchisesOptions,
    counterpartiesOptions,
    clientsCategoryOptions,
    studiosOptions,
    onSelectOrganizationHandler,
    isLoadingModal,
  } = usePromotionsFormLimitation()

  React.useEffect(() => {
    if (!studiosOptions || studiosOptions.length === 0) {
      form?.setFieldsValue({ limitedByStudioIds: null })
    }
  }, [studiosOptions, form])

  return (
    <Flex vertical gap={'middle'}>
      <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
        Ограничения
      </Typography.Title>
      <Flex gap={'small'}>
        <Form.Item
          name="maxUsageCount"
          label={'Кол-во использований'}
          tooltip={'При нулевом значении кол-во использований будет неограничено'}
          initialValue={0}
          style={{ flex: '1' }}
        >
          <Input onKeyPress={validateNumericInput} size={'large'} />
        </Form.Item>
        <Form.Item
          name="maxUsageByClientCount"
          label={'Кол-во на пользователя'}
          style={{ flex: '1' }}
          initialValue={0}
          tooltip={'При нулевом значении кол-во использований будет неограничено'}
        >
          <Input onKeyPress={validateNumericInput} size={'large'} />
        </Form.Item>
      </Flex>
      <Form.Item
        name="limitedByOrganization"
        label={'Юр. лица'}
        tooltip={'При пустом поле акция действует во всех организациях'}
      >
        <Select
          placement={'topRight'}
          size={'large'}
          mode="multiple"
          options={franchisesOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
          onChange={onSelectOrganizationHandler}
        />
      </Form.Item>
      {studiosOptions && studiosOptions?.length > 0 && (
        <Form.Item
          name="limitedByStudioIds"
          label={'Студии'}
          tooltip={'При пустом поле акция действует во всех студиях'}
          hidden={!studiosOptions || studiosOptions.length === 0}
        >
          <Select
            placement={'topRight'}
            size={'large'}
            mode="multiple"
            options={studiosOptions}
            placeholder={'Любые'}
            loading={isLoadingModal}
            disabled={isLoadingModal}
          />
        </Form.Item>
      )}
      <Form.Item
        name="limitedByClientCategoryIds"
        label={'Категории клиентов'}
        tooltip={'При пустом поле акция действует на все категории клиентов'}
      >
        <Select
          placement={'topRight'}
          size={'large'}
          mode="multiple"
          options={clientsCategoryOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
        />
      </Form.Item>
      <Form.Item
        name="limitedByContractCounterpartyIds"
        label={'Контрагенты'}
        tooltip={'При пустом поле акция действует на всех контрагентов'}
      >
        <Select
          placement={'topRight'}
          size={'large'}
          mode="multiple"
          options={counterpartiesOptions}
          placeholder={'Любые'}
          loading={isLoadingModal}
          disabled={isLoadingModal}
        />
      </Form.Item>
    </Flex>
  )
}
