import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { Dayjs } from 'dayjs'

import { contractsInfoPageContractsCreateActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-create/contracts-info-page-contracts.slice'
import { getContractPaymentMethod } from '../../../../../mapping/contracts.mapping'
import { getFileLink } from '../../../../../store/common/files/files.selectors'
import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../../types/modal.types'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { isDef } from '../../../../../types/lang.types'
import { formatDayjsToDate } from '../../../../../format/date.format'

function genContractDTO(values: ContractsApi.ContractsDTO<Dayjs>): ContractsApi.ContractsDTO {
  const { validSince, validUntil, ...restValues } = values

  return {
    ...restValues,
    validSince: isDef(validSince) ? formatDayjsToDate(validSince) : null,
    validUntil: isDef(validUntil) ? formatDayjsToDate(validUntil) : null,
  }
}

export const useContractsInfoContract = (id: string) => {
  const dispatch = useDispatch()
  const link = useSelector(getFileLink)

  const onCreateContract = React.useCallback(
    (value: ContractsApi.ContractsDTO<Dayjs>) => {
      const { validSince, validUntil } = genContractDTO(value)
      const paymentModel = getContractPaymentMethod(value)
      dispatch(
        contractsInfoPageContractsCreateActions.createContract({
          ...value,
          link,
          validSince,
          validUntil,
          paymentModel,
          counterpartyId: id,
        })
      )
    },
    [dispatch, id, link]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCreateContractHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_CONTRACT_MODAL_CREATE,
        props: {
          handleOk: onCreateContract,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, onCloseHandler, onCreateContract])

  return {
    onCreateContractHandler,
  }
}
