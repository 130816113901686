import React, { useState } from 'react'
import { Button, Flex, DatePicker, Divider, Form, Radio, Input, Modal, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Dayjs } from 'dayjs'

import { genDefaultDateFormat } from '../../../../../format/date.format'
import { validateNumericInput } from '../../../../../utils/form/validations-rules-form'
import { PromotionsFormConditions } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-conditions.component'
import { usePromotionsCodesCreate } from './promotions-page-codes-modal-create.hook'
import { ClickableLabelSwitch } from '../../../../../components/forms/clickableLabelSwitch/ClickableLabelSwitch.component'
import { PromotionsFormProducts } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-products/promotions-create-form-products.component'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { PromotionsCodesModalCreateProps } from './promotions-page-codes-modal-create.types'
import { PromotionsFormLimitation } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-limitation/promotions-create-form-limitation.component'

export const PromotionsCodesModalCreate = ({
  handleOk,
  handleOkPattern,
  handleCancel,
}: PromotionsCodesModalCreateProps) => {
  const { onSearchHandler, isCreating } = usePromotionsCodesCreate()
  const [form] = useForm<PromotionsApi.CodeDTO>()

  const [codesType, setCodesType] = useState<PromotionsApi.PromotionsCodesType>('UNIQUE')
  const [isUnlimitedSales, setIsUnlimitedSales] = useState(true)

  function handleChangeCodesType(value: PromotionsApi.PromotionsCodesType) {
    setCodesType(value)
  }

  function handleChangePromotionTime() {
    setIsUnlimitedSales(prev => !prev)
    if (isUnlimitedSales) {
      form.setFieldValue('activeFrom' as const, '')
      form.setFieldValue('activeTo' as const, '')
    }
  }

  const handleFinish = (values: PromotionsApi.CodeDTO | PromotionsApi.CodePatternDTO) => {
    if (codesType === 'UNIQUE') {
      handleOk(values as PromotionsApi.CodeDTO)
    } else {
      handleOkPattern(values as PromotionsApi.CodePatternDTO)
    }
  }

  return (
    <Modal
      title="Создание промокода"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Создать промокод
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          layout="vertical"
          name="form_in_modal"
          onFinish={handleFinish}
          form={form}
          initialValues={{
            products: [{}],
          }}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      <Flex gap={'large'} vertical>
        <Flex vertical gap={'middle'}>
          <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
            Основная информация
          </Typography.Title>
          <Radio.Group
            size={'large'}
            onChange={e => handleChangeCodesType(e.target.value)}
            style={{ display: 'flex', textAlign: 'center' }}
            defaultValue={'UNIQUE'}
          >
            <Radio.Button value="UNIQUE" style={{ flex: '1' }}>
              Именной
            </Radio.Button>
            <Radio.Button value="PATTERN" style={{ flex: '1' }}>
              Сгенерировать промокод
            </Radio.Button>
          </Radio.Group>
          <Form.Item
            style={{ width: '100%' }}
            name="name"
            label="Название промокампании"
            rules={[{ required: true, message: 'Введите название промокампании!' }]}
          >
            <Input style={{ width: '100%' }} size={'large'} />
          </Form.Item>
          {codesType === 'UNIQUE' ? (
            <Form.Item
              style={{ width: '100%' }}
              name="code"
              label="Название промокода на латинице"
              rules={[{ required: true, message: 'Введите название промокода!' }]}
            >
              <Input style={{ width: '100%' }} size={'large'} placeholder={'viva'} />
            </Form.Item>
          ) : (
            <Flex gap={'small'}>
              <Form.Item
                style={{ width: '100%' }}
                name="codePrefix"
                label="Буквы в начало"
                rules={[{ required: true, message: 'Введите буквы промокода!' }]}
              >
                <Input style={{ width: '100%' }} size={'large'} placeholder={'viva'} />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                name="codeRandomSuffixLength"
                label="Кол-во символов"
                rules={[{ required: true, message: 'Введите количество символов в промокоде!' }]}
              >
                <Input style={{ width: '100%' }} onKeyPress={validateNumericInput} size={'large'} />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                name="codeCount"
                label="Кол-во кодов"
                rules={[{ required: true, message: 'Введите количество промокодов!' }]}
              >
                <Input style={{ width: '100%' }} onKeyPress={validateNumericInput} size={'large'} />
              </Form.Item>
            </Flex>
          )}

          {!isUnlimitedSales && (
            <Flex gap={'middle'}>
              <Form.Item
                style={{ width: '100%' }}
                name="activeFrom"
                label="Начало действия"
                rules={[{ required: true, message: 'Выберите дату начала!' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={isUnlimitedSales}
                  size={'large'}
                  format={genDefaultDateFormat()}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                name="activeTo"
                label="Конец действия"
                rules={[{ required: true, message: 'Выберите дату окончания!' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={isUnlimitedSales}
                  size={'large'}
                  format={genDefaultDateFormat()}
                />
              </Form.Item>
            </Flex>
          )}
          <ClickableLabelSwitch
            name={'isUnlimitedSales'}
            label={'Ограничить по времени'}
            form={form}
            onChange={handleChangePromotionTime}
          />
        </Flex>

        <PromotionsFormProducts onSearchHandler={onSearchHandler} form={form} />
        <PromotionsFormLimitation form={form} />
        <PromotionsFormConditions form={form} />
      </Flex>
    </Modal>
  )
}
