import { Button, Card } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useCallback } from 'react'

import { ExercisesApi } from '../../../../../api/types/exercises-api.types'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import './exercises-peek-linked-products.styles.less'
import { ExercisesPeekLinkedProductsList } from '../exercises-peek-linked-products-list/exercises-peek-linked-products-list.component'

interface Props {
  linkedProducts: ExercisesApi.ExerciseLinkedLinkedProducts[]
  phone: string
  clientId: string
  isLoading: boolean
  isCancelled: boolean
  onCreateTransaction: (phone: string, clientId: string) => void
}

export const ExercisesPeekLinkedProducts: React.FC<Props> = props => {
  const { linkedProducts, phone, clientId, isLoading, isCancelled, onCreateTransaction } = props

  const handleAddProduct = useCallback(() => {
    onCreateTransaction(phone, clientId)
  }, [clientId, onCreateTransaction, phone])

  if (isDefAndNotEmpty(linkedProducts)) {
    return (
      <Card title="Оплаченные дополнительные услуги" size="small" className="exercises-peek-inked-products">
        <ExercisesPeekLinkedProductsList
          linkedProducts={linkedProducts}
          isLoading={isLoading}
          isCancelled={isCancelled}
          onAddProduct={handleAddProduct}
        />
      </Card>
    )
  }
  if (!isCancelled) {
    return (
      <Button
        icon={<PlusCircleOutlined />}
        size="small"
        disabled={isLoading}
        style={{ alignSelf: 'baseline' }}
        onClick={() => onCreateTransaction(phone, clientId)}
      >
        Добавить дополнительные услуги
      </Button>
    )
  }
}
