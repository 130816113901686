import * as React from 'react'

import { ExercisesQrReaderModal } from '../../../components/exercises/exercises-qr-reader-modal/exercises-qr-reader-modal.component'
import { useSchedulePageModalQrReader } from './schedule-page-modal-qr-reader.hook'
import { SchedulePageModalQrReaderProps } from './schedule-page-modal-qr-reader.types'

export const SchedulePageModalQrReader: React.FC<SchedulePageModalQrReaderProps> = props => {
  const { exerciseId } = props

  const { onCancelHandler } = useSchedulePageModalQrReader(exerciseId)

  return <ExercisesQrReaderModal {...props} scheduleId={exerciseId} onCancel={onCancelHandler} />
}
