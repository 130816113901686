import { Rule } from 'antd/lib/form'

import { ClientsApi } from '../../../../api/types/clients-api.types'
import { formatFormValueToPhoneNumber } from '../../../../format/phone.format'
import { Nullable } from '../../../../types/lang.types'
import { ExercisesBookingFormValues } from './exercises-booking-modal-form.types'

export function genExercisesBookingFormPaymentTypeValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите способ оплаты' }]
}

export function genExercisesBookingFormClientDTO(
  formValues: ExercisesBookingFormValues,
  clientCurrentData: Nullable<ClientsApi.Client>
): Nullable<ClientsApi.ClientDTO> {
  const { clientId, firstName, lastName, email, phone } = formValues

  if (!clientId && phone && (firstName || lastName || email)) {
    return {
      phone: formatFormValueToPhoneNumber(phone).replace(/\D/g, ''),
      email,
      firstName,
      lastName,
    }
  }

  if (clientCurrentData && (firstName || lastName || email)) {
    return {
      email: email ?? clientCurrentData.email,
      firstName: firstName ?? clientCurrentData.firstName,
      lastName: lastName ?? clientCurrentData.lastName,
      phone: clientCurrentData.phone,
      middleName: clientCurrentData.middleName,
      sex: clientCurrentData.sex,
      birthDate: clientCurrentData.birthDate,
      loyaltyCard: clientCurrentData.loyaltyCard,
      deposit: clientCurrentData.deposit,
      source: clientCurrentData.source,
      comment: clientCurrentData.comment,
      clientCategoryId: clientCurrentData.clientCategory?.id ?? undefined,
      trialUsed: clientCurrentData.trialUsed,
    }
  }

  return null
}
