import { DefaultOptionType } from 'antd/lib/select'
import dayjs, { Dayjs } from 'dayjs'

import { StudiosApi } from '../api/types/studios-api.types'
import { sortOptions } from '../utils/options.utils'
import { ProductsApi } from '../api/types/products-api.types'
import { ContractsApi } from '../api/types/contracts-api.types'
import { isDef, isDefAndNotEmpty, NNumber, Nullable } from '../types/lang.types'
import { PromotionsApi } from '../api/types/promotions-api.types'
import { PromotionsCodesTableRow } from '../components/promotions/promotions-codes-table/promotions-codes-table.types'
import { PromotionsSalesTableRow } from '../components/promotions/promotions-sales-table/promotions-sales-table.types'

export function mapCodesToPromotionsCodesTable(
  codes: Nullable<PromotionsApi.PromotionsCodesShort[]>
): Nullable<PromotionsCodesTableRow[]> {
  if (isDefAndNotEmpty(codes)) {
    return codes.map(
      (codes: PromotionsApi.PromotionsCodesShort): PromotionsCodesTableRow => ({
        id: codes.id,
        promotionsName: codes.pattern.toUpperCase(),
        companyName: codes.campaignName,
        dateActiveFrom: codes.activeFrom,
        dateActiveTo: codes.activeTo,
        target: codes.target,
        promotionsCount: codes.activeCount,
        type: codes.type,
      })
    )
  }

  return null
}

export function mapSalesToPromotionsSalesTable(
  sales: Nullable<PromotionsApi.PromotionsSalesShort[]>
): Nullable<PromotionsSalesTableRow[]> {
  if (isDefAndNotEmpty(sales)) {
    return sales.map(
      (sales: PromotionsApi.PromotionsSalesShort): PromotionsSalesTableRow => ({
        id: sales.id,
        promotionsName: sales.name,
        dateActiveFrom: sales.activeFrom,
        dateActiveTo: sales.activeTo,
        status: sales.status,
        hasStudioLimitation: sales.hasStudioLimitation,
        studios: sales.studios,
        sales: sales.discount,
        salesType: sales.discountType,
      })
    )
  }

  return null
}

export function genPromotionsSalesDTO(
  data: Nullable<PromotionsApi.PromotionDTO>,
  studioOffset: NNumber
): Nullable<PromotionsApi.PromotionDTO> {
  if (isDef(data)) {
    const activeFrom = data.activeFrom
      ? dayjs(data.activeFrom)
          .utcOffset(studioOffset || 0, true)
          .startOf('day')
          .format()
      : ''

    const activeTo = data.activeTo
      ? dayjs(data.activeTo)
          .utcOffset(studioOffset || 0, true)
          .endOf('day')
          .format()
      : ''

    return {
      name: data.name,
      activeFrom,
      activeTo,
      products: data.products || [],
      maxUsageCount: data.maxUsageCount,
      maxUsageByClientCount: data.maxUsageByClientCount,
      newClientsOnly: data.newClientsOnly,
      afterTrialOnly: data.afterTrialOnly,
      hasContractCounterpartyLimitation: data.limitedByContractCounterpartyIds?.length > 0,
      limitedByContractCounterpartyIds: data.limitedByContractCounterpartyIds,
      hasStudioLimitation: data.limitedByStudioIds?.length > 0,
      limitedByStudioIds: data.limitedByStudioIds,
      hasClientCategoryLimitation: data.limitedByClientCategoryIds?.length > 0,
      limitedByClientCategoryIds: data.limitedByClientCategoryIds,
      canMixWithOtherCampaigns: data.canMixWithOtherCampaigns,
    }
  }

  return null
}

export function genPromotionsSalesUpdateDTO(
  data: Nullable<PromotionsApi.PromotionDTO<Dayjs>>
): Nullable<PromotionsApi.PromotionDTO<Dayjs>> {
  if (isDef(data)) {
    return {
      ...data,
      hasContractCounterpartyLimitation: data.limitedByContractCounterpartyIds?.length > 0,
      hasStudioLimitation: data.limitedByStudioIds?.length > 0,
      hasClientCategoryLimitation: data.limitedByClientCategoryIds?.length > 0,
    }
  }

  return null
}

export function genPromotionsCodesDTO(
  data: Nullable<PromotionsApi.CodeDTO>,
  studioOffset: NNumber
): Nullable<PromotionsApi.CodeDTO> {
  if (isDef(data)) {
    const activeFrom = data.activeFrom
      ? dayjs(data.activeFrom)
          .utcOffset(studioOffset || 0, true)
          .startOf('day')
          .format()
      : ''

    const activeTo = data.activeTo
      ? dayjs(data.activeTo)
          .utcOffset(studioOffset || 0, true)
          .endOf('day')
          .format()
      : ''

    return {
      name: data.name,
      code: data.code,
      activeFrom,
      activeTo,
      products: data.products || [],
      maxUsageCount: data.maxUsageCount,
      maxUsageByClientCount: data.maxUsageByClientCount,
      newClientsOnly: data.newClientsOnly,
      afterTrialOnly: data.afterTrialOnly,
      hasContractCounterpartyLimitation: data.limitedByContractCounterpartyIds?.length > 0,
      limitedByContractCounterpartyIds: data.limitedByContractCounterpartyIds,
      hasStudioLimitation: data.limitedByStudioIds?.length > 0,
      limitedByStudioIds: data.limitedByStudioIds,
      hasClientCategoryLimitation: data.limitedByClientCategoryIds?.length > 0,
      limitedByClientCategoryIds: data.limitedByClientCategoryIds,
      canMixWithOtherCampaigns: data.canMixWithOtherCampaigns,
    }
  }
  return null
}

export function genPromotionsCodesUpdateDTO(
  data: Nullable<PromotionsApi.CodeDTO<Dayjs>>
): Nullable<PromotionsApi.CodeDTO<Dayjs>> {
  if (isDef(data)) {
    return {
      ...data,
      hasContractCounterpartyLimitation: data.limitedByContractCounterpartyIds?.length > 0,
      hasStudioLimitation: data.limitedByStudioIds?.length > 0,
      hasClientCategoryLimitation: data.limitedByClientCategoryIds?.length > 0,
    }
  }
  return null
}

export function genPromotionsCodesPatternUpdateDTO(
  data: Nullable<PromotionsApi.CodePatternDTO<Dayjs>>
): Nullable<PromotionsApi.CodePatternDTO<Dayjs>> {
  if (isDef(data)) {
    return {
      ...data,
      hasContractCounterpartyLimitation: data.limitedByContractCounterpartyIds?.length > 0,
      hasStudioLimitation: data.limitedByStudioIds?.length > 0,
      hasClientCategoryLimitation: data.limitedByClientCategoryIds?.length > 0,
    }
  }
  return null
}

export function genPromotionsCodesPatternDTO(
  data: Nullable<PromotionsApi.CodePatternDTO>,
  studioOffset: NNumber
): Nullable<PromotionsApi.CodePatternDTO> {
  if (isDef(data)) {
    const activeFrom = data.activeFrom
      ? dayjs(data.activeFrom)
          .utcOffset(studioOffset || 0, true)
          .startOf('day')
          .format()
      : ''

    const activeTo = data.activeTo
      ? dayjs(data.activeTo)
          .utcOffset(studioOffset || 0, true)
          .endOf('day')
          .format()
      : ''

    return {
      name: data.name,
      codePrefix: data.codePrefix,
      codeCount: data.codeCount,
      codeRandomSuffixLength: data.codeRandomSuffixLength,
      activeFrom,
      activeTo,
      products: data.products || [],
      maxUsageCount: data.maxUsageCount,
      maxUsageByClientCount: data.maxUsageByClientCount,
      newClientsOnly: data.newClientsOnly,
      afterTrialOnly: data.afterTrialOnly,
      hasContractCounterpartyLimitation: data.limitedByContractCounterpartyIds?.length > 0,
      limitedByContractCounterpartyIds: data.limitedByContractCounterpartyIds,
      hasStudioLimitation: data.limitedByStudioIds?.length > 0,
      limitedByStudioIds: data.limitedByStudioIds,
      hasClientCategoryLimitation: data.limitedByClientCategoryIds?.length > 0,
      limitedByClientCategoryIds: data.limitedByClientCategoryIds,
      canMixWithOtherCampaigns: data.canMixWithOtherCampaigns,
    }
  }

  return null
}

export function mapCounterpartiesToOptions(
  counterparties: Nullable<ContractsApi.Counterparties[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(counterparties)) {
    return counterparties.map(
      (counterparties: ContractsApi.Counterparties): DefaultOptionType => ({
        value: counterparties.id,
        label: counterparties.type === 'INDIVIDUAL' ? counterparties.directorName : counterparties.companyName,
      })
    )
  }
}

export function mapSelectedStudiosToOptions(studios: { id: string; name: string }[]): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(studios)) {
    const options = studios.map(
      (studios: { id: string; name: string }): DefaultOptionType => ({
        value: studios.id,
        label: studios.name,
      })
    )
    return sortOptions(options)
  }
}

export function mapProductsToOptions(products: Nullable<ProductsApi.Product[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(products)) {
    const options = products.map(
      (product: { id: string; name: string }): DefaultOptionType => ({
        value: product.id,
        label: product.name,
      })
    )
    return sortOptions(options)
  }
}

export function genPromotionsProductsDTO(
  data: Nullable<PromotionsApi.PromotionDTO<Dayjs>> | Nullable<PromotionsApi.PromotionDTO>
): Nullable<PromotionsApi.PromotionsProduct[]> {
  if (isDef(data)) {
    return data.products?.map((product: PromotionsApi.PromotionsProduct): PromotionsApi.PromotionsProduct => {
      return {
        type: product.type,
        discount: product.discount,
        discountType: product.discountType,
        productIds: product.productIds || [],
      }
    })
  }
  return null
}

export function genPromotionsEditOrganization(
  selectedStudioList: Nullable<string[]>,
  allStudiosList: Nullable<StudiosApi.Studio[]>
): Nullable<string[]> {
  if (isDef(allStudiosList) && isDef(selectedStudioList)) {
    const uniqueOrgIds = new Set<string>()

    allStudiosList.forEach(item => {
      if (selectedStudioList.includes(item.id)) {
        uniqueOrgIds.add(item.organization.id)
      }
    })
    return Array.from(uniqueOrgIds)
  }

  return null
}

export function genPromotionsSalesFormValue(
  data: Nullable<PromotionsApi.Promotion>,
  studioOffset: NNumber
): Nullable<PromotionsApi.Promotion<Dayjs>> {
  if (isDef(data)) {
    return {
      id: data.id,
      name: data.name,
      activeFrom: isDef(data.activeFrom) ? dayjs(data.activeFrom).utcOffset(studioOffset || 0, false) : undefined,
      activeTo: isDef(data.activeTo) ? dayjs(data.activeTo).utcOffset(studioOffset || 0, false) : undefined,
      products: data.products || [],
      maxUsageCount: data.maxUsageCount,
      maxUsageByClientCount: data.maxUsageByClientCount,
      newClientsOnly: data.newClientsOnly,
      afterTrialOnly: data.afterTrialOnly,
      hasContractCounterpartyLimitation: data.hasContractCounterpartyLimitation,
      limitedByContractCounterpartyIds: data.limitedByContractCounterpartyIds,
      hasStudioLimitation: data.hasStudioLimitation,
      limitedByStudioIds: data.limitedByStudioIds,
      hasClientCategoryLimitation: data.hasClientCategoryLimitation,
      limitedByClientCategoryIds: data.limitedByClientCategoryIds,
      canMixWithOtherCampaigns: data.canMixWithOtherCampaigns,
    }
  }

  return null
}

export function genPromotionsCodesFormValue(
  data: Nullable<PromotionsApi.Code>,
  studioOffset: NNumber
): Nullable<PromotionsApi.Code<Dayjs>> {
  if (isDef(data)) {
    return {
      id: data.id,
      name: data.name,
      code: data.code,
      activeFrom: isDef(data.activeFrom) ? dayjs(data.activeFrom).utcOffset(studioOffset || 0, false) : undefined,
      activeTo: isDef(data.activeTo) ? dayjs(data.activeTo).utcOffset(studioOffset || 0, false) : undefined,
      products: data.products || [],
      maxUsageCount: data.maxUsageCount,
      maxUsageByClientCount: data.maxUsageByClientCount,
      newClientsOnly: data.newClientsOnly,
      afterTrialOnly: data.afterTrialOnly,
      hasContractCounterpartyLimitation: data.hasContractCounterpartyLimitation,
      limitedByContractCounterpartyIds: data.limitedByContractCounterpartyIds,
      hasStudioLimitation: data.hasStudioLimitation,
      limitedByStudioIds: data.limitedByStudioIds,
      hasClientCategoryLimitation: data.hasClientCategoryLimitation,
      limitedByClientCategoryIds: data.limitedByClientCategoryIds,
      canMixWithOtherCampaigns: data.canMixWithOtherCampaigns,
    }
  }

  return null
}

export function genPromotionsCodesPatternFormValue(
  data: Nullable<PromotionsApi.CodePattern>,
  studioOffset: NNumber
): Nullable<PromotionsApi.CodePattern<Dayjs>> {
  if (isDef(data)) {
    return {
      id: data.id,
      name: data.name,
      codePrefix: data.codePrefix,
      activeCodes: data.activeCodes,
      codeRandomSuffixLength: data.codeRandomSuffixLength,
      activeFrom: isDef(data.activeFrom) ? dayjs(data.activeFrom).utcOffset(studioOffset || 0, false) : undefined,
      activeTo: isDef(data.activeTo) ? dayjs(data.activeTo).utcOffset(studioOffset || 0, false) : undefined,
      products: data.products || [],
      maxUsageCount: data.maxUsageCount,
      maxUsageByClientCount: data.maxUsageByClientCount,
      newClientsOnly: data.newClientsOnly,
      afterTrialOnly: data.afterTrialOnly,
      hasContractCounterpartyLimitation: data.hasContractCounterpartyLimitation,
      limitedByContractCounterpartyIds: data.limitedByContractCounterpartyIds,
      hasStudioLimitation: data.hasStudioLimitation,
      limitedByStudioIds: data.limitedByStudioIds,
      hasClientCategoryLimitation: data.hasClientCategoryLimitation,
      limitedByClientCategoryIds: data.limitedByClientCategoryIds,
      canMixWithOtherCampaigns: data.canMixWithOtherCampaigns,
    }
  }

  return null
}
