import { ColumnsType } from 'antd/lib/table'
import { Tag } from 'antd'
import React from 'react'

import { formatDateToHumanize } from '../../../format/date.format'
import { PromotionsApi } from '../../../api/types/promotions-api.types'
import { PromotionsCodesTableActions } from './promotions-codes-table-action/promotions-codes-table-action.component'
import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { PromotionsCodesTableRow } from './promotions-codes-table.types'

export interface PromotionsSalesServicesTableActions {
  onEdit: (id: string) => void
  onEditPattern: (id: string) => void
  onRemove: (id: string) => void
  onRemovePattern: (id: string, type: PromotionsApi.PromotionsCodesType) => void
}

const colors = [
  'blue',
  'purple',
  'cyan',
  'green',
  'magenta',
  'pink',
  'red',
  'orange',
  'yellow',
  'volcano',
  'geekblue',
  'lime',
  'gold',
]

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex]
}

const formatDateRange = (dateActiveFrom: string, dateActiveTo: string) => {
  if (!dateActiveFrom && !dateActiveTo) {
    return 'Бессрочный'
  }

  const formattedDateFrom = formatDateToHumanize(dateActiveFrom)
  const formattedDateTo = formatDateToHumanize(dateActiveTo)

  return `${formattedDateFrom} - ${formattedDateTo}`.trim()
}

export function genPromotionsCodesTableColumns({
  onEdit,
  onEditPattern,
  onRemove,
  onRemovePattern,
}: PromotionsSalesServicesTableActions): ColumnsType<PromotionsCodesTableRow> {
  return [
    {
      title: 'Название',
      dataIndex: 'promotionsName',
      key: 'promotionsName',
      render: (_, { promotionsName }) => (
        <Tag color={getRandomColor()} style={{ fontSize: '18px' }}>
          {promotionsName}
        </Tag>
      ),
    },
    {
      title: 'Название компании',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (_, { companyName }) => <TableCellText text={companyName} />,
    },
    {
      title: 'Срок действия',
      dataIndex: 'dateActive',
      key: 'dateActive',
      render: (_, { dateActiveTo, dateActiveFrom }) => (
        <TableCellText text={formatDateRange(dateActiveFrom, dateActiveTo)} />
      ),
    },
    {
      title: 'Назначение',
      dataIndex: 'target',
      key: 'target',
      render: (_, { target }) => (
        <TableCellText text={target === 'ALL_CLIENTS' ? 'Все' : 'Для новых клиентов по услуге'} />
      ),
    },
    {
      title: 'Кол-во',
      dataIndex: 'promotionsCount',
      key: 'promotionsCount',
      render: (_, { promotionsCount }) => <TableCellText text={String(promotionsCount)} />,
    },
    {
      title: 'Операции',
      key: 'action',
      render: (_, { id, promotionsName, type }) => (
        <PromotionsCodesTableActions
          id={id}
          promoCodesType={type}
          name={promotionsName}
          onEdit={onEdit}
          onEditPattern={onEditPattern}
          onRemove={onRemove}
          onRemovePattern={onRemovePattern}
        />
      ),
    },
  ]
}
