import { all, put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'
import { notification } from 'antd'

import { promotionsPageSalesCreateActions } from '../promotions-page-sales-create/promotions-page-sales-create.slice'
import { modalActions } from '../../../../common/modal/modal.slice'
import { genPromotionsSalesUpdateDTO } from '../../../../../mapping/promotions.mapping'
import { isDef } from '../../../../../types/lang.types'
import { getRouterState } from '../../../../common/router/router.selectors'
import { promotionsPageSalesEditActions } from './promotions-page-sales-edit.slice'
import { api } from '../../../../../api/api'
import { callApi } from '../../../../../utils/sagas.utils'
import { genPromotionsPageQueryParams } from '../../../../../pages/promotions-page/promotions-page.utils'

export function* fetchPromotionsModalData(
  action: ReturnType<typeof promotionsPageSalesEditActions.fetchPromotionsModalData>
) {
  try {
    const [counterparties, franchises, categories, studios]: [
      Awaited<ReturnType<typeof api.contractsService.fetchAll>>,
      Awaited<ReturnType<typeof api.organizationsService.fetchAll>>,
      Awaited<ReturnType<typeof api.clientsCategoryService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>
    ] = yield all([
      callApi(api.contractsService.fetchAll, { size: 300 }),
      callApi(api.organizationsService.fetchAll, { size: 300 }),
      callApi(api.clientsCategoryService.fetchAll, { size: 300 }),
      callApi(api.studiosService.fetchAll, { size: 300 }),
    ])

    yield put(
      promotionsPageSalesEditActions.fetchPromotionsModalDataSuccess({
        counterparties: counterparties?.data,
        franchises: franchises?.data,
        categories: categories?.data,
        studios: studios?.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesEditActions.fetchPromotionsModalDataError(new Error()))
  }
}

export function* fetchPromotionsSalesById(
  action: ReturnType<typeof promotionsPageSalesEditActions.fetchPromotionsSalesById>
) {
  try {
    const { id } = action.payload

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchPromotionsSalesById>> = yield callApi(
      api.promotionsService.fetchPromotionsSalesById,
      id
    )
    yield put(promotionsPageSalesEditActions.fetchPromotionsSalesByIdSuccess(response?.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesEditActions.fetchPromotionsSalesByIdError(new Error()))
  }
}

export function* updatePromotionsSales(
  action: ReturnType<typeof promotionsPageSalesEditActions.updatePromotionsSales>
) {
  try {
    const { id, data } = action.payload
    const promotionsSalesDTO = genPromotionsSalesUpdateDTO(data)

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    if (isDef(promotionsSalesDTO)) {
      yield callApi(api.promotionsService.updatePromotionsSales, id, promotionsSalesDTO)
      yield put(promotionsPageSalesEditActions.updatePromotionsSalesSuccess())
      yield notification.success({
        message: 'Акция успешно обновлена',
      })
      yield put(promotionsPageSalesCreateActions.fetchAllSales({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesEditActions.updatePromotionsSalesError(new Error()))
  }
}

export function* searchPromotionsProducts(
  action: ReturnType<typeof promotionsPageSalesEditActions.searchPromotionsProducts>
) {
  try {
    const { name, type } = action.payload

    const response: Awaited<ReturnType<typeof api.promotionsService.searchPromotionsProduct>> = yield callApi(
      api.promotionsService.searchPromotionsProduct,
      { name, type }
    )
    yield put(promotionsPageSalesEditActions.searchPromotionsProductsSuccess(response?.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesEditActions.searchPromotionsProducts(new Error()))
  }
}

export function* promotionsPageSalesEditSagas() {
  yield takeLatest(promotionsPageSalesEditActions.fetchPromotionsModalData.type, fetchPromotionsModalData)
  yield takeLatest(promotionsPageSalesEditActions.updatePromotionsSales.type, updatePromotionsSales)
  yield takeLatest(promotionsPageSalesEditActions.fetchPromotionsSalesById.type, fetchPromotionsSalesById)
  yield takeLatest(promotionsPageSalesEditActions.searchPromotionsProducts.type, searchPromotionsProducts)
}
