import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { Dayjs } from 'dayjs'

import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { Nullable } from '../../../../../types/lang.types'

export interface ContractsInfoPageContractsState {
  contract: Nullable<ContractsApi.Contracts>
  isEditing: boolean
  isEdit: boolean
  isLoadingById: boolean
  isLoadedById: boolean
  error: Nullable<Error>
}

const initialState: ContractsInfoPageContractsState = {
  contract: null,
  isEditing: false,
  isEdit: false,
  isLoadedById: false,
  isLoadingById: false,
  error: null,
}

export const { actions: contractsInfoPageContractsEditActions, reducer: contractsInfoPageContractsEditReducer } =
  createSlice({
    name: '@@contracts-info-page-contracts-edit',
    initialState,
    reducers: {
      updateContract: (
        state: Draft<ContractsInfoPageContractsState>,
        _: PayloadAction<{ id: string; counterpartyId: string; data: ContractsApi.ContractsDTO<Dayjs> }>
      ) => {
        state.isEditing = true
      },
      updateContractSuccess: (state: Draft<ContractsInfoPageContractsState>) => {
        state.isEditing = false
        state.isEdit = true
      },
      updateContractError: (state: Draft<ContractsInfoPageContractsState>, action: PayloadAction<Error>) => {
        state.isEditing = false
        state.isEdit = true
        state.error = action.payload
      },
      fetchContractById: (state: Draft<ContractsInfoPageContractsState>, _: PayloadAction<{ id: string }>) => {
        state.isLoadingById = true
      },
      fetchContractByIdSuccess: (
        state: Draft<ContractsInfoPageContractsState>,
        action: PayloadAction<ContractsApi.Contracts>
      ) => {
        state.contract = action.payload
        state.isLoadingById = false
        state.isLoadedById = true
      },
      fetchContractByIdError: (state: Draft<ContractsInfoPageContractsState>, action: PayloadAction<Error>) => {
        state.isLoadingById = false
        state.isLoadedById = true
        state.error = action.payload
      },
      reset: () => initialState,
    },
  })
