import { Space } from 'antd'

export function genVisitConfirmOptions() {
  return [
    {
      label: (
        <Space>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
            <circle cx="5" cy="5.5" r="4" fill="#52C41A" stroke="white" stroke-width="2" />
          </svg>
          Клиент пришел
        </Space>
      ),
      value: true,
    },
    {
      label: (
        <Space>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
            <circle cx="5" cy="5.5" r="4" fill="#FAAD14" stroke="white" stroke-width="2" />
          </svg>
          Ожидаем
        </Space>
      ),
      value: false,
    },
  ]
}
