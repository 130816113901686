import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../../app.store'
import { mapFranchisesToOptions } from '../../../../../mapping/franchises.mapping'
import { getCurrentStudioOffset } from '../../../../common/layout/layout.selectors'
import {
  genPromotionsEditOrganization,
  genPromotionsSalesFormValue,
  mapCounterpartiesToOptions,
} from '../../../../../mapping/promotions.mapping'
import { mapClientCategoriesToOptions } from '../../../../../mapping/clients-categories.mapping'

const getFranchisesInternal = (state: AppState) => state.promotionsPage.promotionsPageEditSales.franchises
const getCounterpartiesInternal = (state: AppState) => state.promotionsPage.promotionsPageEditSales.counterparties
const getStudiosInternal = (state: AppState) => state.promotionsPage.promotionsPageEditSales.studios
const getPromotionsCategoryInternal = (state: AppState) => state.promotionsPage.promotionsPageEditSales.categories
const getPromotionsSaleInternal = (state: AppState) => state.promotionsPage.promotionsPageEditSales.sale
export const getPromotionsSalesIsEditing = (state: AppState) => state.promotionsPage.promotionsPageEditSales.isEditing
export const getPromotionsSalesIsModalLoading = (state: AppState) =>
  state.promotionsPage.promotionsPageEditSales.isModalLoading
export const getStudiosList = createSelector(getStudiosInternal, studios => studios?.content)
export const getFranchisesList = createSelector(getFranchisesInternal, franchises => franchises?.content)
export const getPromotionsSaleFormValue = createSelector(
  getPromotionsSaleInternal,
  getCurrentStudioOffset,
  (promotion, offset) => genPromotionsSalesFormValue(promotion, offset)
)
export const getPromotionsSalesProductsList = createSelector(getPromotionsSaleInternal, sale => sale?.products)
export const getPromotionsSalesOrganizationOptions = createSelector(getFranchisesInternal, franchises =>
  mapFranchisesToOptions(franchises?.content)
)
export const getSalesEditSelectedFranchises = createSelector(
  getPromotionsSaleInternal,
  getStudiosInternal,
  (promotion, studios) => genPromotionsEditOrganization(promotion?.limitedByStudioIds, studios?.content)
)
export const getPromotionsSalesCategoryOptions = createSelector(getPromotionsCategoryInternal, categories =>
  mapClientCategoriesToOptions(categories?.content)
)
export const getPromotionsSalesCounterpartiesOptions = createSelector(getCounterpartiesInternal, counterparties =>
  mapCounterpartiesToOptions(counterparties?.content)
)
