import * as React from 'react'
import { Button, Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { TableCellDateTime } from '../../../table-cells/table-cell-date-time/table-cell-date-time.component'

interface Props {
  date: string
  onBooking: (studioId: string, scheduleId: string) => void
  exerciseId: string
  studioId: string
  clientBookingReadPermission: boolean
}

export const ClientsSubscriptionsTableBookingsExerciseTime: React.FC<Props> = props => {
  const { date, exerciseId, studioId, clientBookingReadPermission, onBooking } = props

  const onBookingHandler = () => {
    onBooking(studioId, exerciseId)
  }

  return (
    <Space size="middle">
      <Tooltip title={clientBookingReadPermission ? 'Перейти к группе' : 'У вас нет разрешения на это действие'}>
        <Button
          icon={<InfoCircleOutlined />}
          disabled={!clientBookingReadPermission}
          size="middle"
          onClick={onBookingHandler}
        />
      </Tooltip>
      <TableCellDateTime date={date} format="HH:mm" />
    </Space>
  )
}
