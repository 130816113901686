import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../../app.store'
import {
  mapProductsToOptions,
  mapSalesToPromotionsSalesTable,
  mapSelectedStudiosToOptions,
} from '../../../../../mapping/promotions.mapping'

export const getSelectedStudiosInternal = (state: AppState) =>
  state.promotionsPage.promotionsPageCreateSales.selectedStudios
export const getPromotionsProducts = (state: AppState) => state.promotionsPage.promotionsPageEditSales.products
export const getPromotionsSalesInternal = (state: AppState) => state.promotionsPage.promotionsPageCreateSales.sales

export const getPromotionsSalesIsCreating = (state: AppState) =>
  state.promotionsPage.promotionsPageCreateSales.isCreating
export const getPromotionsSalesIsLoading = (state: AppState) => state.promotionsPage.promotionsPageCreateSales.isLoading
export const getPromotionsSalesIsLoaded = (state: AppState) => state.promotionsPage.promotionsPageCreateSales.isLoaded

export const getPromotionsSalesProductsOptions = createSelector(getPromotionsProducts, mapProductsToOptions)
export const getPromotionsSalesStudiosOptions = createSelector(getSelectedStudiosInternal, studios =>
  mapSelectedStudiosToOptions(studios)
)

export const getPromotionsSalesTotalElements = createSelector(getPromotionsSalesInternal, sales => sales?.totalElements)

export const getPromotionsSaleForTableRow = createSelector(getPromotionsSalesInternal, sales =>
  mapSalesToPromotionsSalesTable(sales?.content)
)
