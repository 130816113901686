import { all, put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'
import { notification } from 'antd'

import { getCurrentStudioOffset } from '../../../../common/layout/layout.selectors'
import { modalActions } from '../../../../common/modal/modal.slice'
import { genPromotionsSalesDTO } from '../../../../../mapping/promotions.mapping'
import { isDef, NNumber } from '../../../../../types/lang.types'
import { getRouterState } from '../../../../common/router/router.selectors'
import { promotionsPageSalesCreateActions } from './promotions-page-sales-create.slice'
import { genPaginationParamsDTO } from '../../../../../utils/pagination.utils'
import { api } from '../../../../../api/api'
import { callApi } from '../../../../../utils/sagas.utils'
import { genPromotionsPageQueryParams } from '../../../../../pages/promotions-page/promotions-page.utils'

export function* fetchAllSales(action: ReturnType<typeof promotionsPageSalesCreateActions.fetchAllSales>) {
  try {
    const { size, page, isShowPast = true } = action.payload

    const paginationParams = genPaginationParamsDTO(page, size)
    const params = {
      ...paginationParams,
      showPast: isShowPast,
    }

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchAllPromotionsSales>> = yield callApi(
      api.promotionsService.fetchAllPromotionsSales,
      params
    )

    yield put(promotionsPageSalesCreateActions.fetchAllSalesSuccess(response?.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesCreateActions.fetchAllSalesError(new Error()))
  }
}

export function* removeSales(action: ReturnType<typeof promotionsPageSalesCreateActions.removeSales>) {
  try {
    const { id } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    yield callApi(api.promotionsService.removePromotionsSales, id)

    yield put(promotionsPageSalesCreateActions.removeSalesSuccess())
    yield put(promotionsPageSalesCreateActions.fetchAllSales({ ...params }))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesCreateActions.removeSalesError(new Error()))
  }
}

export function* createSales(action: ReturnType<typeof promotionsPageSalesCreateActions.createSales>) {
  try {
    const studioOffset: NNumber = yield select(getCurrentStudioOffset)
    const promotionsSalesDTO = genPromotionsSalesDTO(action.payload, studioOffset)

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    if (isDef(promotionsSalesDTO)) {
      yield callApi(api.promotionsService.createPromotionsSales, promotionsSalesDTO)
      yield put(promotionsPageSalesCreateActions.createSalesSuccess())

      yield notification.success({
        message: 'Акция успешно создана',
      })

      yield put(promotionsPageSalesCreateActions.fetchAllSales({ ...params }))

      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageSalesCreateActions.createSalesError(new Error()))
  }
}

export function* promotionsPageSalesCreateSagas() {
  yield takeLatest(promotionsPageSalesCreateActions.fetchAllSales.type, fetchAllSales)
  yield takeLatest(promotionsPageSalesCreateActions.removeSales.type, removeSales)
  yield takeLatest(promotionsPageSalesCreateActions.createSales.type, createSales)
}
