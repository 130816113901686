import { Space, Spin, Tag, Typography } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useMemo, useCallback } from 'react'

import { getExercisesPeekPaymentTypeColor } from './exercises-peek-payment-type.utils'
import { formatPaymentType } from '../../../../../format/text.format'
import { PaymentType } from '../../../../../types/payment.types'
import './exercises-peek-payment-type.styles.less'

interface Props {
  paymentType: PaymentType
  isLoading?: boolean
  isDisabled?: boolean
  handlePaymentType?: () => Promise<void>
}

export const ExercisesPeekPaymentType: React.FC<Props> = props => {
  const { paymentType, isLoading, isDisabled, handlePaymentType } = props

  const tagColor = useMemo(() => getExercisesPeekPaymentTypeColor(paymentType), [paymentType])

  const paymentTypeText = useMemo(() => formatPaymentType(paymentType), [paymentType])

  const shouldShowArrow = !isDisabled && !isLoading && handlePaymentType && paymentType === 'ON_PLACE'

  const handleClick = useCallback(() => {
    if (shouldShowArrow) {
      handlePaymentType()
    }
  }, [handlePaymentType, shouldShowArrow])

  return (
    <Tag className="exercises-peek-payment-type" color={tagColor} onClick={handleClick}>
      <Space size={5}>
        <Typography.Text strong>{paymentTypeText}</Typography.Text>

        {isLoading ? (
          <Spin size="small" indicator={<LoadingOutlined spin />} />
        ) : (
          shouldShowArrow && <DownOutlined className="exercises-peek-payment-type__icon" />
        )}
      </Space>
    </Tag>
  )
}
