import React from 'react'
import { FormInstance } from 'antd'

import { genExercisesBookFromWaitingFormIsValid } from './exercises-book-from-waiting-modal.utils'
import { ExercisesBookFromWaitingFormValues } from './exercises-book-from-waiting-modal-form/exercises-book-from-waiting-modal-form.types'

export function useExercisesBookFromWaitingModal(form: FormInstance<ExercisesBookFromWaitingFormValues>) {
  const [isValid, setIsValid] = React.useState<boolean>(false)

  const onFieldsChangeHandler = React.useCallback(setIsValid, [setIsValid])

  const onFieldsChange = React.useCallback((): void => {
    const isValid = genExercisesBookFromWaitingFormIsValid(form)
    onFieldsChangeHandler(isValid)
  }, [form, onFieldsChangeHandler])

  return {
    isValid,
    onFieldsChange,
  }
}
