import React from 'react'
import { Button, Divider, Form, Modal, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'

import { ContractsModalContainer } from '../../../../components/contracts/contracts-modal-container/contracts-modal-container.component'
import { ClientsAutocompleteContainer } from '../../../../containers/clients-autocomplete/clients-autocomplete.container'
import { genClientsFormPhoneValidationRules } from '../../../../components/clients/clients-form/clients-form.utils'
import { AppModalBaseProps } from '../../../../types/modal.types'

export interface ClientsModalCreateProps extends AppModalBaseProps {
  handleOk: (phone: { clientPhone: string }) => void
  handleCancel: () => void
  isLoading?: boolean
}

export const ClientsModalCreate = ({ handleOk, isLoading, handleCancel }: ClientsModalCreateProps) => {
  const [form] = useForm<{ clientPhone: string }>()
  const onChangePhoneHandler = (value: string = '') => {
    form.setFieldValue('clientPhone', value)
  }

  return (
    <Modal
      open={true}
      title="Добавление сотрудника"
      onCancel={handleCancel}
      footer={[
        <Button type="primary" htmlType="submit" disabled={isLoading}>
          Добавить сотрудника
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
          onFinish={handleOk}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      <Typography.Title level={5}>Выберите сотрудника</Typography.Title>
      <ContractsModalContainer>
        <Form.Item name="clientPhone" rules={genClientsFormPhoneValidationRules()}>
          <ClientsAutocompleteContainer
            size="middle"
            form={form}
            name="clientPhone"
            onChange={onChangePhoneHandler}
            placeholder="Поиск"
            suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
          />
        </Form.Item>
      </ContractsModalContainer>
    </Modal>
  )
}
