import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { getManagersCardsList } from '../../../../../store/pages/contracts-page/contracts-page.selectors'
import { contractsPageActions } from '../../../../../store/pages/contracts-page/contracts-page.slice'
import { fileActions } from '../../../../../store/common/files/files.slice'

export const useModalCreate = () => {
  const dispatch = useDispatch()
  const managers = useSelector(getManagersCardsList)

  React.useEffect(() => {
    dispatch(contractsPageActions.fetchAllManagers())
    return () => {
      dispatch(fileActions.reset())
    }
  }, [dispatch])

  const contractType = [
    {
      value: 'POSTPAY',
      label: `Постоплата`,
    },
    {
      value: 'PREPAY',
      label: `Предоплата`,
    },
  ]

  const paymentModel = [
    {
      value: 'ONE_TIME',
      label: 'Разовая оплата',
    },
    {
      value: 'PERIODIC',
      label: 'Периодическая оплата',
    },
    {
      value: 'VALUE_SPENT',
      label: 'По обороту в период',
    },
    {
      value: 'VALUE_LEFT',
      label: 'По остатку баланса в период',
    },
  ]

  return {
    managers,
    contractType,
    paymentModel,
  }
}
