import { put, takeLeading } from 'redux-saga/effects'

import { callApi } from '../../../utils/sagas.utils'
import { api } from '../../../api/api'
import { UserType } from '../../../types/users.types'
import { selectManagersActions } from './select-managers.slice'

export function* fetchAllManagers(_: ReturnType<typeof selectManagersActions.fetchAllManagers>) {
  try {
    const managers: Awaited<ReturnType<typeof api.employeesService.fetchAll>> = yield callApi(
      api.employeesService.fetchAll,
      { size: 100 },
      UserType.Managers
    )

    yield put(selectManagersActions.fetchAllManagersSuccess(managers.data))
  } catch (e) {
    yield put(selectManagersActions.fetchAllManagersError(new Error()))
  }
}

export function* selectManagersSagas() {
  yield takeLeading(selectManagersActions.fetchAllManagers, fetchAllManagers)
}
