import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Dayjs } from 'dayjs'

import {
  getPromotionsCodesForTableRow,
  getPromotionsCodesIsLoading,
  getPromotionsCodeTotalElements,
} from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-create/promotions-page-codes-create.selectors'
import { promotionsPageCodesEditActions } from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.slice'
import { getPromotionsCodeIsModalLoading } from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.selectors'
import { promotionsPageCodesCreateActions } from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-create/promotions-page-codes-create.slice'
import { genPromotionsProductsDTO } from '../../../../mapping/promotions.mapping'
import { modalActions } from '../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../types/modal.types'
import { PromotionsApi } from '../../../../api/types/promotions-api.types'
import { genPromotionsPagePath } from '../../../../format/path.format'
import { PromotionsPageSection } from '../../promotions-page.types'
import { usePromotionsPageParams } from '../../promotions-page-hooks/promotions-page.hook'
import { genPaginationConfig } from '../../../../utils/pagination.utils'

export const useCodesTabs = () => {
  const dispatch = useDispatch()

  const { page, size } = usePromotionsPageParams()
  const { push } = useHistory()

  const codes = useSelector(getPromotionsCodesForTableRow)
  const isLoading = useSelector(getPromotionsCodesIsLoading)
  const isLoadingModal = useSelector(getPromotionsCodeIsModalLoading)
  const totalElements = useSelector(getPromotionsCodeTotalElements)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onEditPromotionsCodesHandler = React.useCallback(
    (id: string, value: PromotionsApi.CodeDTO<Dayjs>): void => {
      const products = genPromotionsProductsDTO(value)
      dispatch(promotionsPageCodesEditActions.updatePromotionsCodes({ id, data: { ...value, products } }))
    },
    [dispatch]
  )

  const onEditPromotionsCodesPatternHandler = React.useCallback(
    (id: string, value: PromotionsApi.CodePatternDTO<Dayjs>): void => {
      const products = genPromotionsProductsDTO(value)
      dispatch(promotionsPageCodesEditActions.updatePromotionsCodesPattern({ id, data: { ...value, products } }))
    },
    [dispatch]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onEditCodesHandler = React.useCallback(
    (id: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.PROMOTIONS_PAGE_CODES_MODAL_EDIT,
          props: {
            id: id,
            handleOk: onEditPromotionsCodesHandler,
            handleCancel: onCloseHandler,
          },
        })
      )
    },
    [dispatch, isLoadingModal, onCloseHandler, onEditPromotionsCodesHandler]
  )

  const onEditCodesPatternHandler = React.useCallback(
    (id: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.PROMOTIONS_PAGE_CODES_MODAL_EDIT,
          props: {
            id: id,
            isPattern: true,
            handleOk: onEditPromotionsCodesPatternHandler,
            handleCancel: onCloseHandler,
          },
        })
      )
    },
    [dispatch, isLoadingModal, onCloseHandler, onEditPromotionsCodesPatternHandler]
  )

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      dispatch(promotionsPageCodesCreateActions.removeCodes({ id }))
    },
    [dispatch]
  )

  const onRemovePatternHandler = React.useCallback(
    (id: string, type: PromotionsApi.PromotionsCodesType): void => {
      dispatch(promotionsPageCodesCreateActions.removePatternCodes({ id }))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.CODES }, { page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.CODES }, { page, size: pageSize }))
    },
    [page, push]
  )

  const onVisiblePastCodesHandler = React.useCallback(
    (value: boolean) => {
      dispatch(promotionsPageCodesCreateActions.fetchAllCodes({ page, size, isShowPast: !value }))
    },
    [dispatch, page, size]
  )

  React.useEffect(() => {
    dispatch(promotionsPageCodesCreateActions.fetchAllCodes({ page, size }))
  }, [dispatch, page, size])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageCodesCreateActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    codes,
    pagination,
    onEditCodesHandler,
    onEditCodesPatternHandler,
    onRemoveHandler,
    onChangePageHandler,
    onRemovePatternHandler,
    onChangePageSizeHandler,
    onVisiblePastCodesHandler,
  }
}
