import React from 'react'
import style from '@pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-contract-card/contracts-info-contract-card.module.scss'
import { Typography } from 'antd'
import { useParams } from 'react-router-dom'

import { ContractsPageInfoParams } from '../../../contracts-info-page.types'
import { ContractsInfoContractCardArchive } from '../contracts-info-contract-card/contracts-info-contract-card-archive.component'
import { PageEmpty } from '../../../../../components/page/page-empty/page-empty.component'
import { useContractsInfoContractList } from './contracts-info-contract-list.hook'
import { ContractsInfoContractCard } from '../contracts-info-contract-card/contracts-info-contract-card.component'
import { PageLoader } from '../../../../../components/page/page-loader/page-loader.component'

export const ContractsInfoContractList = () => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { activeContractsList, expiredContractsList, isLoading, handleEditContract, onChangeManagerHandler } =
    useContractsInfoContractList(id)

  if (isLoading) {
    return <PageLoader />
  }

  if (activeContractsList?.length === 0 && expiredContractsList?.length === 0) {
    return <PageEmpty description={'Нет активных договоров'} />
  }

  return (
    <>
      {activeContractsList?.length !== 0 ? (
        <div className={style.containerCards}>
          {activeContractsList?.map(contract => (
            <ContractsInfoContractCard
              key={contract.id}
              contract={contract}
              onEditContract={handleEditContract}
              onChangeManagers={onChangeManagerHandler}
            />
          ))}
        </div>
      ) : (
        <PageEmpty description={'Нет активных договоров'} />
      )}
      {expiredContractsList && expiredContractsList?.length > 0 && (
        <>
          <Typography.Title level={3}>Архив договоров</Typography.Title>
          <div className={style.containerCards}>
            {expiredContractsList?.map(contract => (
              <ContractsInfoContractCardArchive key={contract.id} contract={contract} />
            ))}
          </div>
        </>
      )}
    </>
  )
}
