import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'

import { promotionsPageSalesCreateActions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.slice'
import { isDef } from '../../../../types/lang.types'
import { getPromotionsSalesStudiosOptions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.selectors'
import {
  getPromotionsSalesCategoryOptions,
  getPromotionsSalesCounterpartiesOptions,
  getPromotionsSalesIsModalLoading,
  getPromotionsSalesOrganizationOptions,
  getSalesEditSelectedFranchises,
  getStudiosList,
} from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.selectors'
import { SelectedStudiosData } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.types'
import {
  getCodesEditSelectedFranchises,
  getCodesPatternEditSelectedFranchises,
} from '../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.selectors'

export const usePromotionsFormLimitation = () => {
  const dispatch = useDispatch()

  const studiosOptions = useSelector(getPromotionsSalesStudiosOptions)
  const studiosList = useSelector(getStudiosList)
  const promotionsEditSalesSelectedFranchises = useSelector(getSalesEditSelectedFranchises)
  const promotionsEditCodesSelectedFranchises = useSelector(getCodesEditSelectedFranchises)
  const promotionsEditCodesPatternSelectedFranchises = useSelector(getCodesPatternEditSelectedFranchises)
  const franchisesOptions = useSelector(getPromotionsSalesOrganizationOptions)
  const counterpartiesOptions = useSelector(getPromotionsSalesCounterpartiesOptions)
  const clientsCategoryOptions = useSelector(getPromotionsSalesCategoryOptions)
  const isLoadingModal = useSelector(getPromotionsSalesIsModalLoading)

  const onSelectOrganizationHandler = React.useCallback(
    (selectedStudiosId: string[]) => {
      // @ts-ignore
      const result: SelectedStudiosData[] = selectedStudiosId?.flatMap(selectedStudioId =>
        studiosList
          ?.filter(studio => studio.organization.id === selectedStudioId)
          ?.map(studio => ({ name: studio.name, id: studio.id }))
      )

      dispatch(promotionsPageSalesCreateActions.setSelectedStudios(result))
    },
    [dispatch, studiosList]
  )

  useEffect(() => {
    if (isDef(promotionsEditSalesSelectedFranchises)) {
      onSelectOrganizationHandler(promotionsEditSalesSelectedFranchises as string[])
    }
    if (isDef(promotionsEditCodesSelectedFranchises)) {
      onSelectOrganizationHandler(promotionsEditCodesSelectedFranchises as string[])
    }
    if (isDef(promotionsEditCodesPatternSelectedFranchises)) {
      onSelectOrganizationHandler(promotionsEditCodesPatternSelectedFranchises as string[])
    }
  }, [
    onSelectOrganizationHandler,
    promotionsEditCodesPatternSelectedFranchises,
    promotionsEditCodesSelectedFranchises,
    promotionsEditSalesSelectedFranchises,
  ])

  useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesCreateActions.resetSelectedStudios())
    }
  }, [dispatch])

  return {
    isLoadingModal,
    studiosOptions,
    franchisesOptions,
    counterpartiesOptions,
    clientsCategoryOptions,
    onSelectOrganizationHandler,
  }
}
