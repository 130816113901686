import { CheckOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons'
import { Card, Flex, Typography, Row, Col, Select, Button, Tag, Input, Form, Radio } from 'antd'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { ScheduleModalConflictsExerciseProps } from './schedule-modal-conflicts-exercise.types'
import { genScheduleExercisePagePath } from '../../../../format/path.format'
import { useScheduleModalConflictsExercise } from './schedule-modal-conflicts-exercise.hook'
import { formatDate } from '../../../../format/date.format'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import './schedule-modal-conflicts-exercise.styles.less'

export const ScheduleModalConflictsExercise: React.FC<ScheduleModalConflictsExerciseProps> = props => {
  const { isLoading, studioId, action, conflict, trainersOptions, studiosRoomsOptions, studioOffset } = props
  const { onSaveHandler, onSkipHandler } = props

  const {
    theme,
    form,
    timeFrom,
    timeTo,
    trainerNames,
    hasTrainersInConflict,
    hasRoomsInConflict,
    trainersList,
    onRemoveTrainer,
    onFinish,
  } = useScheduleModalConflictsExercise({
    conflict,
    trainersOptions,
    studioOffset,
    onSaveHandler,
  })

  return (
    <Card
      className={clsx('schedule-modal-conflict', {
        'schedule-modal-conflict--dark': theme === 'dark',
      })}
    >
      <Form name={conflict.exercise.id} form={form} onFinish={onFinish} disabled={isLoading}>
        <Flex vertical gap={16}>
          <div>
            <Typography.Title style={{ margin: 0 }} level={5}>
              {formatDate(conflict.exercise.timeFrom, 'D MMMM')}, с {timeFrom} до {timeTo}
            </Typography.Title>
            <Typography.Text>{trainerNames}</Typography.Text>
          </div>
          {hasRoomsInConflict && (
            <Flex vertical gap={16}>
              <Flex vertical gap={4}>
                {conflict.conflicts?.rooms?.map((conflict, index) => {
                  if (conflict) {
                    return (
                      <Typography.Text key={index} type="danger">
                        Время в {conflict.room?.name} занято другой{' '}
                        <Link
                          to={genScheduleExercisePagePath(studioId, conflict.conflictingExerciseId)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          записью
                        </Link>
                      </Typography.Text>
                    )
                  }
                  return null
                })}
              </Flex>
              <div className="schedule-modal-conflict__rooms">
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.room !== curValues.room}>
                  {({ getFieldValue }) => {
                    const roomId = getFieldValue('room')

                    return (
                      <Form.Item name={'room'} rules={[{ required: true, message: 'Пожалуйста, выберите значение' }]}>
                        <Radio.Group className="schedule-modal-conflict__radio-group">
                          <Flex vertical gap={16}>
                            {studiosRoomsOptions
                              ?.filter(room => room.value !== conflict.conflicts?.rooms?.[0].room?.id)
                              ?.map(room => {
                                return (
                                  <Flex key={room.value} justify="space-between" align="center">
                                    <Tag className="schedule-modal-conflict__tag schedule-modal-conflict__tag--dashed">
                                      {room.label}
                                    </Tag>
                                    <Radio className="schedule-modal-conflict__radio-button" value={room.value}>
                                      <Tag className="schedule-modal-conflict__tag">
                                        {roomId === room.value ? (
                                          <>
                                            <CheckOutlined /> Выбрано
                                          </>
                                        ) : (
                                          'Выбрать'
                                        )}
                                      </Tag>
                                    </Radio>
                                  </Flex>
                                )
                              })}
                          </Flex>
                        </Radio.Group>
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </div>
            </Flex>
          )}
          {hasTrainersInConflict && (
            <Flex vertical gap={16}>
              <Flex vertical gap={4}>
                {conflict.conflicts?.trainers?.map((conflict, index) => {
                  if (conflict) {
                    return (
                      <Typography.Text key={index} type="danger">
                        {conflict.trainers?.length === 1 ? 'Исполнитель' : 'Исполнители'}{' '}
                        {conflict.trainers?.map(trainer => trainer.name).join(', ')} есть в другой{' '}
                        <Link
                          to={genScheduleExercisePagePath(studioId, conflict.conflictingExerciseId)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          записи
                        </Link>
                      </Typography.Text>
                    )
                  }
                  return null
                })}
              </Flex>
              <Form.Item name="noTrainer" noStyle />

              {isDefAndNotEmpty(trainersList) && (
                <Card className="schedule-modal-conflict__trainers">
                  <Flex vertical gap={8}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => {
                        return prevValues?.noTrainer !== curValues?.noTrainer
                      }}
                    >
                      <>
                        <Row gutter={24}>
                          <Col style={{ width: 'calc(50% - 24px)' }}>
                            <Typography.Text strong>Исполнитель</Typography.Text>
                          </Col>
                          <Col style={{ width: 'calc(50% - 24px)' }}>
                            <Typography.Text strong>Выберите новое значение</Typography.Text>
                          </Col>
                        </Row>
                        {trainersList.map((trainer, trainerIndex) => (
                          <Row gutter={16} key={trainerIndex}>
                            <Col style={{ width: 'calc(50% - 24px)' }}>
                              <Input disabled value={trainer?.name} />
                            </Col>
                            <Col style={{ width: 'calc(50% - 24px)' }}>
                              <Form.Item
                                name={['trainers', trainerIndex]}
                                rules={[{ required: true, message: 'Пожалуйста, выберите значение' }]}
                              >
                                <Select placeholder="Выберите новое значение" options={trainersOptions} />
                              </Form.Item>
                            </Col>
                            <Col flex="0 1 32px">
                              <Button danger icon={<DeleteOutlined />} onClick={() => onRemoveTrainer(trainerIndex)} />
                            </Col>
                          </Row>
                        ))}
                      </>
                    </Form.Item>
                  </Flex>
                </Card>
              )}
            </Flex>
          )}
          {conflict.conflicts ? (
            <Flex gap={16}>
              <Button style={{ width: '100%' }} onClick={() => onSkipHandler(conflict.exercise.id, conflict.action)}>
                Не {action === 'CREATE' ? 'создавать' : 'редактировать'} запись
              </Button>
              <Button icon={<ReloadOutlined />} style={{ width: '100%' }} htmlType="submit">
                Применить изменения
              </Button>
            </Flex>
          ) : (
            <Typography.Text type="danger">{conflict.error}</Typography.Text>
          )}
        </Flex>
      </Form>
    </Card>
  )
}
