import {
  WalletOutlined,
  ClockCircleOutlined,
  EditTwoTone,
  ApartmentOutlined,
  UsergroupAddOutlined,
  PieChartOutlined,
  MessageOutlined,
  SettingOutlined,
  IssuesCloseOutlined,
  IdcardOutlined,
  TagsOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  PercentageOutlined,
  SolutionOutlined,
  ReconciliationOutlined,
  LockOutlined,
  ShareAltOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  BuildOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  AuditOutlined,
  SmileOutlined,
  ProjectOutlined,
  CommentOutlined,
} from '@ant-design/icons'

import { PromotionsPageSection } from '../../pages/promotions-page/promotions-page.types'
import {
  AppLayoutTopBarMenu,
  AppLayoutUserMenu,
} from '../../components/layouts/app-layout/app-layout-top-bar/app-layout-top-bar.types'
import { AppLayoutSidebarMenuItem } from '../../components/layouts/app-layout/app-layout-sidebar/app-layout-sidebar.types'
import {
  genClientsPagePath,
  genDirectionsPagePath,
  genExercisesTypesPagePath,
  genEmployeesPagePath,
  genFranchisesPagePath,
  genProductsPagePath,
  genSchedulePagePath,
  genStudiosPagePath,
  genTransactionsPagePath,
  genSalariesPagePath,
  genScheduleManagementPagePath,
  genMasterServicesPagePath,
  genPositionsPagePath,
  genEmployeesSchedulePagePath,
  genCustomFieldsPagePath,
  genGoodsPagePath,
  genScheduleLongtermPagePath,
  genContractsPagePath,
  genPromotionsPagePath,
} from '../../format/path.format'
import { formatPathName, formatProductsPageSectionName } from '../../format/text.format'
import { AppPath } from '../../types/path.types'
import { ProductsPageSection } from '../../pages/products-page/products-page.types'
import { isDef, NString } from '../../types/lang.types'

export function genAppLayoutSidebarMenu(
  studioId: NString,
  transactionPermission: boolean,
  timetableReadPermission: boolean
): AppLayoutSidebarMenuItem[] {
  return [
    isDef(studioId)
      ? {
          Icon: ClockCircleOutlined,
          title: formatPathName(AppPath.SCHEDULE),
          path: genSchedulePagePath(studioId),
          ...(timetableReadPermission && {
            items: {
              ItemIcon: EditTwoTone,
              path: genScheduleManagementPagePath(studioId),
            },
          }),
        }
      : null,
    isDef(studioId) && timetableReadPermission
      ? {
          Icon: ApartmentOutlined,
          title: formatPathName(AppPath.SCHEDULE_LONGTERM),
          path: genScheduleLongtermPagePath(studioId),
        }
      : null,
    // {
    //   Icon: BarChartOutlined,
    //   title: 'Аналитика',
    //   path: '/analytics',
    // },
    isDef(studioId) && transactionPermission
      ? {
          Icon: WalletOutlined,
          title: formatPathName(AppPath.TRANSACTIONS),
          path: genTransactionsPagePath(studioId),
        }
      : null,
    // {
    //   Icon: AlertOutlined,
    //   title: 'Оповещения',
    //   path: '/notifications',
    // },
  ].reduce<AppLayoutSidebarMenuItem[]>((acc, it) => {
    if (isDef(it)) {
      acc.push(it)
    }

    return acc
  }, [])
}

export function genAppLayoutTopBarMenu(managementPermission: boolean): AppLayoutTopBarMenu[] {
  const menu: AppLayoutTopBarMenu[] = [
    {
      title: 'Клиенты',
      icon: UsergroupAddOutlined,
      path: genClientsPagePath(),
    },
    {
      title: 'Аналитика',
      icon: PieChartOutlined,
      path: 'https://report.vivacrm.ru/',
      isExternal: true,
    },
    {
      title: 'HelpDesk',
      icon: MessageOutlined,
      path: 'https://helpdesk.vivacrm.ru/',
      isExternal: true,
    },
    {
      title: 'Задачи',
      icon: IssuesCloseOutlined,
      path: 'https://plans.vivacrm.ru/',
      isExternal: true,
    },
  ]
  if (managementPermission) {
    menu.push({
      title: 'Управление',
      icon: SettingOutlined,
      items: [
        {
          title: 'Услуги, товары, абонементы',
          items: [
            {
              title: formatPathName(AppPath.MASTER_SERVICES),
              icon: IdcardOutlined,
              path: genMasterServicesPagePath(),
            },
            {
              title: formatProductsPageSectionName(ProductsPageSection.SERVICES),
              icon: TagsOutlined,
              path: genProductsPagePath(ProductsPageSection.SERVICES),
            },
            {
              title: formatProductsPageSectionName(ProductsPageSection.GROUP_SUBSCRIPTIONS),
              icon: ProfileOutlined,
              path: genProductsPagePath(ProductsPageSection.GROUP_SUBSCRIPTIONS),
            },
            {
              title: formatPathName(AppPath.GOODS),
              icon: ShoppingCartOutlined,
              path: genGoodsPagePath(),
            },
          ],
        },
        {
          title: 'Маркетинг',
          items: [
            {
              title: formatPathName(AppPath.PROMOTIONS),
              icon: PercentageOutlined,
              path: genPromotionsPagePath({ section: PromotionsPageSection.SALES }),
            },
            {
              title: 'Рассылки',
              disabled: true,
              icon: CommentOutlined,
            },
            {
              title: 'Автоворонки',
              disabled: true,
              icon: ProjectOutlined,
            },
          ],
        },
        {
          title: 'Управление командой',
          items: [
            {
              title: formatPathName(AppPath.EMPLOYEES),
              icon: UsergroupAddOutlined,
              path: genEmployeesPagePath(),
            },
            {
              title: formatPathName(AppPath.EMPLOYEES_SCHEDULE),
              icon: ReconciliationOutlined,
              path: genEmployeesSchedulePagePath(),
            },
            {
              title: formatPathName(AppPath.SALARIES),
              icon: SolutionOutlined,
              path: genSalariesPagePath(),
            },
            {
              title: formatPathName(AppPath.POSITIONS),
              icon: LockOutlined,
              path: genPositionsPagePath(),
            },
          ],
        },
        {
          title: 'Договоры и семейный доступ',
          items: [
            {
              title: formatPathName(AppPath.CONTRACTS),
              path: genContractsPagePath(),
              icon: AuditOutlined,
            },
            {
              title: 'Семейный доступ',
              disabled: true,
              icon: SmileOutlined,
            },
          ],
        },
        {
          title: 'Настройки',
          items: [
            {
              title: formatPathName(AppPath.DIRECTIONS),
              icon: ShareAltOutlined,
              path: genDirectionsPagePath(),
            },
            {
              title: 'Уведомления',
              icon: NotificationOutlined,
              path: 'https://report.vivacrm.ru/',
              isExternal: true,
            },
            {
              title: formatPathName(AppPath.STUDIOS),
              icon: EnvironmentOutlined,
              path: genStudiosPagePath(),
            },
            {
              title: formatPathName(AppPath.EXERCISES_TYPES),
              icon: BuildOutlined,
              path: genExercisesTypesPagePath(),
            },
            {
              title: formatPathName(AppPath.FRANCHISES),
              icon: ShopOutlined,
              path: genFranchisesPagePath(),
            },
            {
              title: formatPathName(AppPath.CUSTOM_FIELDS),
              icon: AppstoreOutlined,
              path: genCustomFieldsPagePath(),
            },
          ],
        },
      ],
    })
  }
  return menu
}

export function genAppLayoutUserMenu(logout: () => void): AppLayoutUserMenu {
  return [
    {
      title: 'Выход',
      onClick: logout,
    },
  ]
}
