import { all, spawn } from 'redux-saga/effects'

import { contractsInfoPageContractsEditSagas } from './contracts-info-page-contracts/contracts-info-page-contracts-edit/contracts-info-page-contracts-edit.sagas'
import { contractsInfoPageDescriptionSagas } from './contracts-info-page-description/contracts-info-page-description.sagas'
import { contractsInfoPageClientsSagas } from './contracts-info-page-clients/contracts-info-page-clients.sagas'
import { contractsInfoPageContractsCreateSagas } from './contracts-info-page-contracts/contracts-info-page-contracts-create/contracts-info-page-contracts.sagas'

export function* contractsInfoPageSagas() {
  yield all(
    [
      contractsInfoPageDescriptionSagas,
      contractsInfoPageClientsSagas,
      contractsInfoPageContractsCreateSagas,
      contractsInfoPageContractsEditSagas,
    ].map(saga => spawn(saga))
  )
}
