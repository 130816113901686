import { useMemo, useRef, useState } from 'react'
import { Form, FormInstance } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import {
  genExercisesPeekOverviewTimeFromValidationRules,
  genExercisesPeekOverviewTimeToValidationRules,
  genExercisesPeekOverviewStudiosRoomsValidationRules,
  genExercisesPeekOverviewSubServiceValidationRules,
  genExercisesPeekOverviewMaxClientsCountValidationRules,
} from './exercises-peek-overview.utils'
import { ExercisesPeekFormValues } from './exercises-peek-overview.types'
import { InputTimeRangeValue } from '../../../../controls/input-time-range/input-time-range.types'

interface Props {
  form: FormInstance<ExercisesPeekFormValues>
  clientsCount: number
  onEdit: (values: ExercisesPeekFormValues) => void
  handleRefetchTrainers: (roomId: string, timeFrom?: string, timeTo?: string) => void
}

export function useExercisesPeekOverview(props: Props) {
  const { form, clientsCount, onEdit, handleRefetchTrainers } = props

  const timeFromValidationRules = useMemo(genExercisesPeekOverviewTimeFromValidationRules, [])
  const timeToValidationRules = useMemo(genExercisesPeekOverviewTimeToValidationRules, [])

  const studiosRoomsValidationRules = useMemo(genExercisesPeekOverviewStudiosRoomsValidationRules, [])
  const subServiceValidationRules = useMemo(genExercisesPeekOverviewSubServiceValidationRules, [])
  const maxClientsCountValidationRules = useMemo(
    () => genExercisesPeekOverviewMaxClientsCountValidationRules(clientsCount),
    [clientsCount]
  )

  const buttonRef = useRef<HTMLDivElement>(null)

  const [isFormTouched, setIsFormTouched] = useState(false)

  const onValuesChange = (changedValues: any, values: ExercisesPeekFormValues) => {
    const isTouched = form.isFieldsTouched()
    setIsFormTouched(isTouched)

    if (isTouched && buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  const studioId = Form.useWatch('studioId', form)
  const masterServiceId = Form.useWatch('masterService', form)

  const onChangeStudioHandler = (value: string): void => {
    const values = form.getFieldsValue()

    form.setFieldsValue({
      ...values,
      roomId: undefined,
      studioId: value,
    })
  }

  const onChangemMsterServiceHandler = (value: string): void => {
    const values = form.getFieldsValue()

    form.setFieldsValue({
      ...values,
      direction: undefined,
      masterService: value,
    })
  }

  const onChangeStudioRoomHandler = (roomId: string): void => {
    const values = form.getFieldsValue()
    const { date, time } = values

    const exerciseDate = dayjs(date).format('YYYY-MM-DD')

    const timeFrom = `${exerciseDate}T${time[0]}`
    const timeTo = `${exerciseDate}T${time[1]}`

    handleRefetchTrainers(roomId, timeFrom, timeTo)
  }

  const onChangeDateHandler = (date: Dayjs): void => {
    const values = form.getFieldsValue()
    const { roomId, time } = values

    const exerciseDate = dayjs(date).format('YYYY-MM-DD')

    const timeFrom = `${exerciseDate}T${time[0]}`
    const timeTo = `${exerciseDate}T${time[1]}`

    handleRefetchTrainers(roomId, timeFrom, timeTo)
  }

  const onChangeTimeHandler = (value: InputTimeRangeValue): void => {
    const values = form.getFieldsValue()
    const { roomId, date } = values

    const exerciseDate = dayjs(date).format('YYYY-MM-DD')

    const timeFrom = `${exerciseDate}T${value[0]}`
    const timeTo = `${exerciseDate}T${value[1]}`

    handleRefetchTrainers(roomId, timeFrom, timeTo)
  }

  const onEditExercise = async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()
      onEdit(values)
      setIsFormTouched(false)
    } catch (errorInfo) {}
  }

  return {
    timeFromValidationRules,
    timeToValidationRules,
    studiosRoomsValidationRules,
    subServiceValidationRules,
    maxClientsCountValidationRules,
    isFormTouched,
    buttonRef,
    studioId,
    masterServiceId,
    onChangeStudioHandler,
    onChangemMsterServiceHandler,
    onChangeStudioRoomHandler,
    onChangeDateHandler,
    onChangeTimeHandler,
    onValuesChange,
    onEditExercise,
  }
}
