import { CloseOutlined, DeleteOutlined, LinkOutlined, NotificationOutlined } from '@ant-design/icons'
import { Button, Flex, message, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { clsx } from 'clsx'
import CopyToClipboard from 'react-copy-to-clipboard'

import './side-peek.styles.less'
import { peekActions } from '../../store/common/peek/peek.slice'
import { getTheme } from '../../store/common/layout/layout.selectors'

interface Props {
  children: React.ReactNode
  isLoading?: boolean
  tag?: JSX.Element
  link?: string
  removePermission?: boolean
  onCancel?: () => void
  onRemove?: () => void
}

export const SidePeek: React.FC<Props> = props => {
  const { children, isLoading, link, tag, removePermission = false, onCancel, onRemove } = props

  const dispatch = useDispatch()

  const theme = useSelector(getTheme)

  const onCancelHandler = useCallback((): void => {
    dispatch(peekActions.closeLast())
    if (onCancel) {
      onCancel()
    }
  }, [dispatch, onCancel])

  useEffect(() => {
    document.body.classList.add('app-no-scroll')
    return () => document.body.classList.remove('app-no-scroll')
  }, [])

  return (
    <Flex
      justify="end"
      className={clsx(`side-peek ${theme !== 'light' && 'side-peek--dark'}`)}
      onClick={e => e.currentTarget === e.target && onCancelHandler()}
    >
      <Flex className="side-peek__wrapper" vertical gap="middle">
        <Flex justify="space-between">
          <Flex gap={10} align="center">
            <Button icon={<CloseOutlined />} onClick={onCancelHandler} />
            {tag && tag}
          </Flex>
          <Flex gap={5}>
            <Button icon={<NotificationOutlined />} disabled />
            {link && (
              <CopyToClipboard text={link}>
                <Button
                  onClick={() => {
                    message.success({
                      content: 'Ссылка успешно скопирована',
                      className: 'side-peek__message',
                    })
                  }}
                  icon={<LinkOutlined />}
                />
              </CopyToClipboard>
            )}
            {onRemove && (
              <Tooltip title={!removePermission ? 'У вас нет разрешения на это действие' : ''}>
                <Button danger disabled={!removePermission || isLoading} icon={<DeleteOutlined />} onClick={onRemove} />
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex className="side-peek__content" vertical>
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
