import { all, spawn } from 'redux-saga/effects'

import { schedulePageModalCreateSagas } from './schedule-page-modal-create/schedule-page-modal-create.sagas'
import { schedulePageListSagas } from './schedule-page-list/schedule-page-list.sagas'
import { schedulePagePeekExerciseSagas } from './schedule-page-peek-exercise/schedule-page-peek-exercise.sagas'
import { schedulePageModalBookingSagas } from './schedule-page-modal-booking/schedule-page-modal-booking.sagas'
import { schedulePageModalCommentsSagas } from './schedule-page-modal-comments/schedule-page-modal-comments.sagas'
import { schedulePageModalWaitingSagas } from './schedule-page-modal-waiting/schedule-page-modal-waiting.sagas'
import { schedulePageModalBookFromWaitingSagas } from './schedule-page-modal-book-from-waiting/schedule-page-modal-book-from-waiting.sagas'

export function* schedulePageSagas() {
  yield all(
    [
      schedulePageModalCreateSagas,
      schedulePageListSagas,
      schedulePagePeekExerciseSagas,
      schedulePageModalBookingSagas,
      schedulePageModalCommentsSagas,
      schedulePageModalWaitingSagas,
      schedulePageModalBookFromWaitingSagas,
    ].map(saga => spawn(saga))
  )
}
