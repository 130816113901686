import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'
import { notification } from 'antd'

import { promotionsPageCodesCreateActions } from '../promotions-page-codes-create/promotions-page-codes-create.slice'
import { api } from '../../../../../api/api'
import { callApi } from '../../../../../utils/sagas.utils'
import {
  genPromotionsCodesPatternUpdateDTO,
  genPromotionsCodesUpdateDTO,
} from '../../../../../mapping/promotions.mapping'
import { getRouterState } from '../../../../common/router/router.selectors'
import { genPromotionsPageQueryParams } from '../../../../../pages/promotions-page/promotions-page.utils'
import { isDef } from '../../../../../types/lang.types'
import { modalActions } from '../../../../common/modal/modal.slice'
import { promotionsPageCodesEditActions } from './promotions-page-codes-edit.slice'

export function* fetchPromotionsCodesById(
  action: ReturnType<typeof promotionsPageCodesEditActions.fetchPromotionsCodesById>
) {
  try {
    const { id } = action.payload

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchPromotionsCodesById>> = yield callApi(
      api.promotionsService.fetchPromotionsCodesById,
      id
    )
    yield put(promotionsPageCodesEditActions.fetchPromotionsCodesByIdSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesEditActions.fetchPromotionsCodesByIdError(new Error()))
  }
}

export function* fetchPromotionsCodesPatternById(
  action: ReturnType<typeof promotionsPageCodesEditActions.fetchPromotionsCodesPatternById>
) {
  try {
    const { id } = action.payload

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchPromotionsCodesPatternById>> = yield callApi(
      api.promotionsService.fetchPromotionsCodesPatternById,
      id
    )
    yield put(promotionsPageCodesEditActions.fetchPromotionsCodesPatternByIdSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesEditActions.fetchPromotionsCodesPatternByIdError(new Error()))
  }
}

export function* updatePromotionsCodes(
  action: ReturnType<typeof promotionsPageCodesEditActions.updatePromotionsCodes>
) {
  try {
    const { id, data } = action.payload

    const promotionsCodeDTO = genPromotionsCodesUpdateDTO(data)

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    if (isDef(promotionsCodeDTO)) {
      yield callApi(api.promotionsService.updatePromotionsCodes, id, promotionsCodeDTO)
      yield put(promotionsPageCodesEditActions.updatePromotionsCodesSuccess())
      yield notification.success({
        message: 'Промокод успешно обновлен',
      })
      yield put(promotionsPageCodesCreateActions.fetchAllCodes({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesEditActions.updatePromotionsCodesError(new Error()))
  }
}

export function* updatePromotionsCodesPattern(
  action: ReturnType<typeof promotionsPageCodesEditActions.updatePromotionsCodesPattern>
) {
  try {
    const { id, data } = action.payload

    const promotionsCodeDTO = genPromotionsCodesPatternUpdateDTO(data)

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    if (isDef(promotionsCodeDTO)) {
      yield callApi(api.promotionsService.updatePromotionsCodesPattern, id, promotionsCodeDTO)
      yield put(promotionsPageCodesEditActions.updatePromotionsCodesPatternSuccess())
      yield notification.success({
        message: 'Промокод успешно обновлен',
      })
      yield put(promotionsPageCodesCreateActions.fetchAllCodes({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesEditActions.updatePromotionsCodesPatternError(new Error()))
  }
}

export function* generatePromotionsCodesPattern(
  action: ReturnType<typeof promotionsPageCodesEditActions.generatePromotionsCodesPattern>
) {
  try {
    const { count, id } = action.payload

    const response: Awaited<ReturnType<typeof api.promotionsService.generatePromotionsCodesPattern>> = yield callApi(
      api.promotionsService.generatePromotionsCodesPattern,
      count,
      id
    )

    yield put(promotionsPageCodesEditActions.generatePromotionsCodesPatternSuccess(response?.data))
    yield notification.success({
      message: 'Новые промокоды сгенерированы',
    })
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesEditActions.generatePromotionsCodesPatternError(new Error()))
  }
}

export function* promotionsPageCodesEditSagas() {
  yield takeLatest(promotionsPageCodesEditActions.updatePromotionsCodes.type, updatePromotionsCodes)
  yield takeLatest(promotionsPageCodesEditActions.fetchPromotionsCodesById.type, fetchPromotionsCodesById)
  yield takeLatest(promotionsPageCodesEditActions.fetchPromotionsCodesPatternById.type, fetchPromotionsCodesPatternById)
  yield takeLatest(promotionsPageCodesEditActions.updatePromotionsCodesPattern.type, updatePromotionsCodesPattern)
  yield takeLatest(promotionsPageCodesEditActions.generatePromotionsCodesPattern.type, generatePromotionsCodesPattern)
}
