import { AppLayoutTopBarMenuItem } from './app-layout-top-bar.types'

export function genSelectedKeys(items: AppLayoutTopBarMenuItem[], pathname: string, search: string): string[] {
  const result: string[] = []

  for (const item of items) {
    const activeSubItem = item.items.find(subItem => {
      if (subItem.path?.includes('?')) {
        return subItem.path === `${pathname}${search}`
      }
      return subItem.path === pathname
    })

    if (activeSubItem) {
      result.push(item.title, activeSubItem.title)
      break
    }
  }

  return result
}
