import { Button, Card, Flex, notification, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CreditCardOutlined,
  HistoryOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'

import { ExercisesPeekClientTabsProps } from './exercises-peek-client-tabs.types'
import { formatFullName } from '../../../../../format/text.format'
import { formatPhoneNumber } from '../../../../../format/phone.format'
import { ExercisesPeekSelectVisitConfirm } from '../exercises-peek-select-visit-confirm/exercises-peek-select-visit-confirm.component'
import { ExercisesPeekLinkedProducts } from '../exercises-peek-linked-products/exercises-peek-linked-products.component'
import { ExercisesPeekPaymentType } from '../exercises-peek-payment-type/exercises-peek-payment-type.component'
import { ExercisesPeekClientComments } from '../exercises-peek-client-comments/exercises-peek-client-comments.component'
import { ExercisesPeekPaymentDropdown } from '../exercises-peek-payment-dropdown/exercises-peek-payment-dropdown.component'
import { CustomCard } from '../../../../custom-card/custom-card.component'
import { useTheme } from '../../../../../hooks/use-theme.hook'
import './exercises-peek-client-tabs.styles.less'
import { peekActions } from '../../../../../store/common/peek/peek.slice'

export const ExercisesPeekClientTabs: React.FC<ExercisesPeekClientTabsProps> = props => {
  const { exerciseId, bookings, isBookingsLoading, maxClientsCount } = props
  const { clientBookingCreatePermission, clientBookingDeletePermission } = props
  const { onBookingHandler, onCommentHandler, onVisitHandler } = props
  const { onCreateTransactionHandler, onChangePaymentTypeHandler, onCancelBookingHandler } = props

  const { theme } = useTheme()

  const dispatch = useDispatch()
  const history = useHistory()

  const [activeKey, setActiveKey] = useState<string>()

  useEffect(() => {
    if (!activeKey || !bookings.find(booking => booking.id === activeKey)) {
      setActiveKey(bookings[0]?.id)
    }
  }, [activeKey, bookings])

  const activeClient = useMemo(() => {
    return activeKey ? bookings?.find(booking => booking.id === activeKey) : null
  }, [bookings, activeKey])

  const tabPanes = useMemo(() => {
    return bookings?.map(booking => {
      const isPaid = booking.paymentType !== 'ON_PLACE'
      const visitConfirmed = booking.visitConfirmed

      return (
        <Tabs.TabPane
          disabled={isBookingsLoading}
          tab={
            <Space size={4}>
              <Typography.Text strong delete={booking.isCancelled}>
                {booking.firstName} {booking.lastName}
              </Typography.Text>
              {!booking.isCancelled && (
                <Flex>
                  <Tooltip title={isPaid ? 'Оплачено' : 'Требуется оплата'}>
                    <div
                      className={clsx('exercises-peek-client-tabs__icon', {
                        'exercises-peek-client-tabs__icon--warning': !isPaid,
                      })}
                    >
                      <CreditCardOutlined />
                      {isPaid ? <CheckCircleFilled /> : <CloseCircleFilled />}
                    </div>
                  </Tooltip>
                  <Tooltip title={visitConfirmed ? 'Визит подтвержден' : 'Визит не подтвержден'}>
                    <div
                      className={clsx('exercises-peek-client-tabs__icon', {
                        'exercises-peek-client-tabs__icon--warning': !visitConfirmed,
                      })}
                    >
                      <HistoryOutlined />
                      {visitConfirmed ? <CheckCircleFilled /> : <CloseCircleFilled />}
                    </div>
                  </Tooltip>
                </Flex>
              )}
            </Space>
          }
          key={booking.id}
          closeIcon={null}
        />
      )
    })
  }, [bookings, isBookingsLoading])

  const onBooking = () => {
    if (maxClientsCount !== bookings.filter(booking => !booking.isCancelled).length && !isBookingsLoading) {
      if (clientBookingCreatePermission) {
        onBookingHandler()
      } else {
        notification.error({
          message: 'У вас нет разрешения на это действие',
        })
      }
    }
  }

  const onClient = () => {
    if (activeClient) {
      dispatch(peekActions.closeLast())
      history.push({
        pathname: `/clients/${activeClient.clientId}`,
      })
    }
  }

  return (
    <div
      className={clsx('exercises-peek-client-tabs', {
        'exercises-peek-client-tabs--cancelled': activeClient?.isCancelled,
        'exercises-peek-client-tabs--dark': theme === 'dark',
      })}
    >
      <Tabs
        className="exercises-peek-client-tabs__tabs"
        type="editable-card"
        activeKey={activeKey}
        addIcon={
          <PlusOutlined
            style={{
              opacity:
                maxClientsCount === bookings.filter(booking => !booking.isCancelled).length || isBookingsLoading
                  ? 0.5
                  : 1,
            }}
          />
        }
        onChange={setActiveKey}
        onEdit={onBooking}
      >
        {tabPanes}
      </Tabs>
      {activeClient && (
        <>
          <Card className="exercises-peek-client-tabs__card" size="small">
            <Flex gap={10} justify="space-between" align="flex-start">
              <Tag color="purple">{activeClient.categoryClient.name}</Tag>
              <Flex gap={10}>
                <ExercisesPeekPaymentType paymentType={activeClient.paymentType} />
                <ExercisesPeekSelectVisitConfirm
                  clientId={activeClient.id}
                  visitConfirmed={activeClient.visitConfirmed}
                  isLoading={isBookingsLoading}
                  isCancelled={activeClient.isCancelled}
                  onChangeVisitConfirmed={onVisitHandler}
                />
              </Flex>
            </Flex>
            <Flex vertical gap={10}>
              <Flex vertical gap={5}>
                <Space size={8}>
                  <Typography.Title className="exercises-peek-client-tabs__name" level={4} onClick={onClient}>
                    {formatFullName(activeClient.firstName, activeClient.lastName)}
                  </Typography.Title>
                  <Typography.Text type="secondary">{formatPhoneNumber(activeClient.phone)}</Typography.Text>
                </Space>
                <ExercisesPeekClientComments
                  clientComment={activeClient.clientComment}
                  comments={activeClient.comments}
                  isBookingsLoading={isBookingsLoading}
                  clientId={activeClient.id}
                  exerciseFormat="PERSONAL"
                  onCommentHandler={onCommentHandler}
                />
              </Flex>
              {activeClient.paymentType === 'ON_PLACE' && !activeClient.isCancelled && (
                <CustomCard customClassName="exercises-peek-client-tabs__unpaid-records">
                  <Flex vertical gap={10}>
                    <Typography.Text strong>Требуется оплата</Typography.Text>
                    <Flex
                      align="center"
                      justify="space-between"
                      className="exercises-peek-client-tabs__unpaid-records-item"
                    >
                      <Typography.Text strong>Оплата записи</Typography.Text>
                      <ExercisesPeekPaymentDropdown
                        exerciseFormat="PERSONAL"
                        bookingId={activeClient.id}
                        phone={activeClient.phone}
                        clientId={activeClient.clientId}
                        exerciseId={exerciseId}
                        onChangePaymentType={onChangePaymentTypeHandler}
                        onCreateTransaction={onCreateTransactionHandler}
                      />
                    </Flex>
                  </Flex>
                </CustomCard>
              )}
              <ExercisesPeekLinkedProducts
                phone={activeClient.phone}
                clientId={activeClient.clientId}
                linkedProducts={activeClient.linkedProducts}
                isLoading={isBookingsLoading}
                isCancelled={activeClient.isCancelled}
                onCreateTransaction={onCreateTransactionHandler}
              />
            </Flex>
          </Card>
          <Card className="exercises-peek-client-tabs__card exercises-peek-client-tabs__card--bottom" size="small">
            <Flex justify="space-between">
              <Button disabled>Чат с гостем</Button>
              {!activeClient?.isCancelled && (
                <Tooltip title={!clientBookingDeletePermission ? 'У вас нет разрешения на это действие' : ''}>
                  <Button
                    danger
                    disabled={isBookingsLoading || !clientBookingDeletePermission}
                    onClick={() => onCancelBookingHandler(activeClient.id)}
                  >
                    Отменить запись
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Card>
        </>
      )}
    </div>
  )
}
