import { put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { genPaginationParamsDTO } from '../../../../../utils/pagination.utils'
import { getFileLink } from '../../../../common/files/files.selectors'
import { getContractDTO } from '../../../../../mapping/contracts.mapping'
import { isDef } from '../../../../../types/lang.types'
import { modalActions } from '../../../../common/modal/modal.slice'
import { contractsInfoPageContractsCreateActions } from './contracts-info-page-contracts.slice'
import { callApi } from '../../../../../utils/sagas.utils'
import { api } from '../../../../../api/api'
import { contractsInfoPageDescriptionActions } from '../../contracts-info-page-description/contracts-info-page-description.slice'

export function* createContract(action: ReturnType<typeof contractsInfoPageContractsCreateActions.createContract>) {
  try {
    const link: string = yield select(getFileLink)
    const contractDTO = getContractDTO(action.payload, link)

    if (isDef(contractDTO)) {
      const { counterpartyId } = contractDTO
      yield callApi(api.contractsService.createContract, contractDTO)
      yield notification.success({
        message: 'Договор успешно создан',
      })
      yield put(modalActions.closeLast())
      yield put(
        contractsInfoPageContractsCreateActions.fetchAllContract({
          counterpartyId,
          showExpired: true,
          showNotStarted: true,
          size: 300,
        })
      )
    } else {
      yield put(contractsInfoPageContractsCreateActions.createContractError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsCreateActions.createContractError(new Error()))
  }
}

export function* paidContract(action: ReturnType<typeof contractsInfoPageContractsCreateActions.paidContract>) {
  try {
    const { id, data, counterpartyId } = action.payload

    yield callApi(api.contractsService.paidContract, id, { paymentAmount: data.paymentAmount })
    yield put(contractsInfoPageContractsCreateActions.paidContractSuccess())
    yield notification.success({
      message: 'Договор успешно оплачен',
    })
    yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id: counterpartyId }))
    yield put(modalActions.closeLast())
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsCreateActions.paidContractError(new Error()))
  }
}

export function* deleteContract(action: ReturnType<typeof contractsInfoPageContractsCreateActions.deleteContract>) {
  try {
    const { id, counterpartyId } = action.payload

    yield callApi(api.contractsService.deleteContract, id)
    yield put(contractsInfoPageContractsCreateActions.deleteContractSuccess())
    yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id: counterpartyId }))
    yield put(modalActions.closeLast())
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsCreateActions.paidContractError(new Error()))
  }
}

export function* fetchAllContract(action: ReturnType<typeof contractsInfoPageContractsCreateActions.fetchAllContract>) {
  try {
    const { size, page, counterpartyId, showExpired, showNotStarted } = action.payload

    const paramsPagination = genPaginationParamsDTO(page, size)

    const params = {
      counterpartyId,
      showExpired,
      showNotStarted,
      ...paramsPagination,
    }

    const response: Awaited<ReturnType<typeof api.contractsService.fetchAllContracts>> = yield callApi(
      api.contractsService.fetchAllContracts,
      params
    )

    yield put(contractsInfoPageContractsCreateActions.fetchAllContractSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsCreateActions.fetchAllContractError(new Error()))
  }
}

export function* contractsInfoPageContractsCreateSagas() {
  yield takeLatest(contractsInfoPageContractsCreateActions.createContract, createContract)
  yield takeLatest(contractsInfoPageContractsCreateActions.paidContract, paidContract)
  yield takeLatest(contractsInfoPageContractsCreateActions.deleteContract, deleteContract)
  yield takeLatest(contractsInfoPageContractsCreateActions.fetchAllContract, fetchAllContract)
}
