import * as React from 'react'

import { ContractModalEdit } from '../pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-modal-edit/contracts-info-modal-edit.component'
import { PromotionsCodesModalEdit } from '../pages/promotions-page/promotions-page-tabs/promotions-page-tabs-codes/promotions-page-codes-modal-edit/promotions-page-codes-modal-edit.component'
import { PromotionsSalesModalEdit } from '../pages/promotions-page/promotions-page-tabs/promotions-page-tabs-sales/promotions-page-sales-modal-edit/promotions-page-sales-modal-edit.component'
import { FranchisesFormModalTerminal } from '../components/franchises/franchises-form/franchises-form-modal-terminal/franchises-form-modal-terminal.component'
import { FranchisesFormModalTill } from '../components/franchises/franchises-form/franchises-form-modal-till/franchises-form-modal-till.component'
import { PromotionsSalesModalCreate } from '../pages/promotions-page/promotions-page-tabs/promotions-page-tabs-sales/promotions-page-sales-modal-create/promotions-page-sales-modal-create.component'
import { PromotionsCodesModalCreate } from '../pages/promotions-page/promotions-page-tabs/promotions-page-tabs-codes/promotions-page-codes-modal-create/promotions-page-codes-modal-create.component'
import { ContractsModalCreate } from '../pages/contracts-page/contracts-page-modal-create/contracts-modal-create.component'
import { ClientsModalCreate } from '../pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-clients/contracts-info-page-clients-create-modal.component'
import { ClientsEditPageBookingsActiveReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-bookings-active-receipts-modal/clients-edit-page-bookings-active-receipts-modal.component'
import { ClientsEditPageBookingsHistoryReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-bookings-history-receipts-modal/clients-edit-page-bookings-history-receipts-modal.component'
import { ClientsEditPagePurchasesReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-purchases-receipts-modal/clients-edit-page-purchases-receipts-modal.component'
import { ClientsEditPageSubscriptionsPauseModal } from '../pages/clients-edit-page/clients-edit-page-subscriptions-pause-modal/clients-edit-page-subscriptions-pause-modal.component'
import { ClientsEditPageSubscriptionsReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-subscriptions-receipts-modal/clients-edit-page-subscriptions-receipts-modal.component'
import { CustomFieldsPageModalCreate } from '../pages/custom-fields-page/custom-fields-page-modal-create/custom-fields-page-modal-create.component'
import { CustomFieldsPageModalEdit } from '../pages/custom-fields-page/custom-fields-page-modal-edit/custom-fields-page-modal-edit.component'
import { ScheduleManagementPageModalConfirm } from '../pages/schedule-management-page/schedule-management-page-modal-confirm/schedule-management-page-modal-confirm.component'
import { ScheduleManagementPageModalCreate } from '../pages/schedule-management-page/schedule-management-page-modal-create/schedule-management-page-modal-create.component'
import { ScheduleManagementPageModalEdit } from '../pages/schedule-management-page/schedule-management-page-modal-edit/schedule-management-page-modal-edit.component'
import { ScheduleManagementPageModalError } from '../pages/schedule-management-page/schedule-management-page-modal-error/schedule-management-page-modal-error.component'
import { SchedulePageModalConfirm } from '../pages/schedule-page/schedule-page-modal-confirm/schedule-page-modal-confirm.component'
import { SchedulePageModalCreate } from '../pages/schedule-page/schedule-page-modal-create/schedule-page-modal-create.component'
import { SchedulePageModalError } from '../pages/schedule-page/schedule-page-modal-error/schedule-page-modal-error.component'
import { TransactionsCreatePageModalConfirm } from '../pages/transactions-create-page/transactions-create-page-modal-confirm/transactions-create-page-modal-confirm.component'
import { TransactionsCreatePageModalWaiting } from '../pages/transactions-create-page/transactions-create-page-modal-waiting/transactions-create-page-modal-waiting.component'
import { TransactionsCreatePageModalProducts } from '../pages/transactions-create-page/transactions-create-page-modal-products/transactions-create-page-modal-products.component'
import { TransactionsPageModalRefund } from '../pages/transactions-page/transactions-page-modal-refund/transactions-page-modal-refund.component'
import { TransactionsPageReceiptsModal } from '../pages/transactions-page/transactions-page-receipts-modal/transactions-page-receipts-modal.component'
import { AppModal } from '../types/modal.types'
import { EmployeesScheduleEditModalConfirm } from '../pages/employees/employees-schedule-edit/ui/modals/employees-schedule-edit-modal-confirm.component'
import { ScheduleLongtermPageModalConfirm } from '../pages/schedule-longterm-page/schedule-longterm-page-modal-confirm/schedule-longterm-page-modal-confirm.component'
import { ScheduleLongtermPageModalEdit } from '../pages/schedule-longterm-page/schedule-longterm-page-modal-edit/schedule-longterm-page-modal-edit.component'
import { ScheduleLongtermPageModalProgress } from '../pages/schedule-longterm-page/schedule-longterm-page-modal-progress/schedule-longterm-page-modal-progress.component'
import { ScheduleLongtermPageModalConflicts } from '../pages/schedule-longterm-page/schedule-longterm-page-modal-conflicts/schedule-longterm-page-modal-conflicts.component'
import { ScheduleLongtermPageModalAdd } from '../pages/schedule-longterm-page/schedule-longterm-page-modal-add/schedule-longterm-page-modal-add.component'
import { ScheduleLongtermPageModalCreate } from '../pages/schedule-longterm-page/schedule-longterm-page-modal-create/schedule-longterm-page-modal-create.component'
import { ScheduleManagementPageModalProgress } from '../pages/schedule-management-page/schedule-management-page-modal-progress/schedule-management-page-modal-progress.component'
import { ScheduleManagementPageModalAdd } from '../pages/schedule-management-page/schedule-management-page-modal-add/schedule-management-page-modal-add.component'
import { ScheduleManagementPageModalConflicts } from '../pages/schedule-management-page/schedule-management-page-modal-conflicts/schedule-management-page-modal-conflicts.component'
import { PositionsEditPageModalConfirm } from '../pages/positions-edit-page/positions-edit-page-modal-confirm/positions-edit-page-modal-confirm.component'
import { ContractModalCreate } from '../pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-page-modal-create/contracts-info-modal-create.component'
import { ContractsPaidModal } from '../pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-modal-paid.component'
import { ContractModalConfirm } from '../pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-modal-confirm.component'
import { CounterpartiesModalDelete } from '../pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-description/contracts-info-tabs-description-delete.component'
import { ClientsEditPageConfirmModal } from '../pages/clients-edit-page/clients-edit-page-confirm-modal/clients-edit-page-confirm-modal.component'
import { FranchisesEditPageModalConfirm } from '../pages/franchises-edit-page/franchises-edit-page-modal-confirm/franchises-edit-page-modal-confirm.component'
import { SchedulePageModalBooking } from '../pages/schedule-page/schedule-page-modal-booking/schedule-page-modal-booking.component'
import { SchedulePageModalComments } from '../pages/schedule-page/schedule-page-modal-comments/schedule-page-modal-comments.component'
import { SchedulePageModalWaiting } from '../pages/schedule-page/schedule-page-modal-waiting/schedule-page-modal-waiting.component'
import { SchedulePageModalQrReader } from '../pages/schedule-page/schedule-page-modal-qr-reader/schedule-page-modal-qr-reader.component'
import { SchedulePageModalBookFromWaiting } from '../pages/schedule-page/schedule-page-modal-book-from-waiting/schedule-page-modal-book-from-waiting.component'

export function genAppModalComponent(modal: AppModal): React.FC<any> {
  switch (modal) {
    // region ---- schedule page
    case AppModal.SCHEDULE_PAGE_MODAL_CREATE:
      return SchedulePageModalCreate
    case AppModal.SCHEDULE_PAGE_MODAL_CONFIRM:
      return SchedulePageModalConfirm
    case AppModal.SCHEDULE_PAGE_MODAL_BOOKING:
      return SchedulePageModalBooking
    case AppModal.SCHEDULE_PAGE_MODAL_COMMENTS:
      return SchedulePageModalComments
    case AppModal.SCHEDULE_PAGE_MODAL_WAITING:
      return SchedulePageModalWaiting
    case AppModal.SCHEDULE_PAGE_MODAL_BOOK_FROM_WAITING:
      return SchedulePageModalBookFromWaiting
    case AppModal.SCHEDULE_PAGE_MODAL_QR_READER:
      return SchedulePageModalQrReader
    case AppModal.SCHEDULE_PAGE_MODAL_ERROR:
      return SchedulePageModalError
    // endregion

    // region ---- schedule management page
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CREATE:
      return ScheduleManagementPageModalCreate
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT:
      return ScheduleManagementPageModalEdit
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM:
      return ScheduleManagementPageModalConfirm
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR:
      return ScheduleManagementPageModalError
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS:
      return ScheduleManagementPageModalProgress
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_ADD:
      return ScheduleManagementPageModalAdd
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFLICTS:
      return ScheduleManagementPageModalConflicts
    // endregion

    // region ---- schedule longterm page
    case AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM:
      return ScheduleLongtermPageModalConfirm
    case AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_EDIT:
      return ScheduleLongtermPageModalEdit
    case AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS:
      return ScheduleLongtermPageModalProgress
    case AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFLICTS:
      return ScheduleLongtermPageModalConflicts
    case AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_ADD:
      return ScheduleLongtermPageModalAdd
    case AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CREATE:
      return ScheduleLongtermPageModalCreate
    // endregion

    // region ---- employees schedule
    case AppModal.EMPLOYEES_SCHEDULE_EDIT_MODAL_CONFIRM:
      return EmployeesScheduleEditModalConfirm
    // endregion

    // region ---- transactions page
    case AppModal.TRANSACTIONS_PAGE_RECEIPTS_MODAL:
      return TransactionsPageReceiptsModal
    case AppModal.TRANSACTIONS_PAGE_REFUND_MODAL:
      return TransactionsPageModalRefund
    // endregion

    // region ---- contracts info page
    case AppModal.CONTRACTS_INFO_PAGE_CLIENT_MODAL_CREATE:
      return ClientsModalCreate
    case AppModal.CONTRACTS_INFO_PAGE_CONTRACT_MODAL_CREATE:
      return ContractModalCreate
    case AppModal.CONTRACTS_INFO_PAGE_CONTRACT_MODAL_EDIT:
      return ContractModalEdit
    case AppModal.CONTRACTS_INFO_PAGE_PAID_MODAL:
      return ContractsPaidModal
    case AppModal.CONTRACTS_INFO_PAGE_CONFIRM_MODAL:
      return ContractModalConfirm
    case AppModal.CONTRACTS_INFO_PAGE_DELETE_MODAL:
      return CounterpartiesModalDelete
    // endregion

    // region ---- contracts page
    case AppModal.CONTRACTS_PAGE_COUNTERPARTY_MODAL_CREATE:
      return ContractsModalCreate
    // endregion

    // region ---- contracts page
    case AppModal.PROMOTIONS_PAGE_SALES_MODAL_CREATE:
      return PromotionsSalesModalCreate
    case AppModal.PROMOTIONS_PAGE_CODES_MODAL_CREATE:
      return PromotionsCodesModalCreate
    case AppModal.PROMOTIONS_PAGE_CODES_MODAL_EDIT:
      return PromotionsCodesModalEdit
    case AppModal.PROMOTIONS_PAGE_SALES_MODAL_EDIT:
      return PromotionsSalesModalEdit
    // endregion

    // region ---- transactions create page
    case AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_PRODUCTS:
      return TransactionsCreatePageModalProducts
    case AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_CONFIRM:
      return TransactionsCreatePageModalConfirm
    case AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_WAITING:
      return TransactionsCreatePageModalWaiting
    // endregion

    // region ---- clients edit page
    case AppModal.CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL:
      return ClientsEditPageBookingsActiveReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_BOOKINGS_HISTORY_RECEIPTS_MODAL:
      return ClientsEditPageBookingsHistoryReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL:
      return ClientsEditPageSubscriptionsPauseModal
    case AppModal.CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL:
      return ClientsEditPageSubscriptionsReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_PURCHASES_RECEIPTS_MODAL:
      return ClientsEditPagePurchasesReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_CONFIRM_MODAL:
      return ClientsEditPageConfirmModal
    // endregion

    // region ---- custom fields page
    case AppModal.CUSTOM_FIELDS_PAGE_MODAL_EDIT:
      return CustomFieldsPageModalEdit
    case AppModal.CUSTOM_FIELDS_PAGE_MODAL_CREATE:
      return CustomFieldsPageModalCreate
    // endregion

    // region ---- positions edit page
    case AppModal.POSITIONS_EDIT_PAGE_MODAL_CONFIRM:
      return PositionsEditPageModalConfirm
    // endregion

    case AppModal.FRANCHISES_PAGE_TERMINAL_FORM:
      return FranchisesFormModalTerminal

    case AppModal.FRANCHISES_PAGE_TILL_FORM:
      return FranchisesFormModalTill

    case AppModal.FRANCHISES_EDIT_PAGE_MODAL_CONFIRM:
      return FranchisesEditPageModalConfirm
  }
}
