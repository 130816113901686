import React from 'react'
import { Flex, Tag, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import './exercises-peek-linked-products-list.styles.less'

interface Props {
  linkedProducts?: Array<{ name: string }>
  isLoading?: boolean
  bordered?: boolean
  isCancelled: boolean
  onAddProduct: () => void
}

export const ExercisesPeekLinkedProductsList: React.FC<Props> = ({
  linkedProducts,
  isLoading,
  bordered = false,
  isCancelled,
  onAddProduct,
}) => {
  return (
    <Flex className="exercises-peek-linked-products-list" justify="flex-start">
      {linkedProducts?.map((item, index) => (
        <Tag className="exercises-peek-linked-products-list__item" key={index} color="green" bordered={bordered}>
          {item.name}
        </Tag>
      ))}
      {!isCancelled && (
        <Button size="small" disabled={isLoading} icon={<PlusCircleOutlined />} onClick={onAddProduct} />
      )}
    </Flex>
  )
}
