import * as React from 'react'
import { Button, Modal } from 'antd'

import { ExercisesBookFromWaitingModalProps } from './exercises-book-from-waiting-modal.types'
import { useExercisesBookFromWaitingModal } from './exercises-book-from-waiting-modal.hook'
import { ExercisesBookFromWaitingModalForm } from './exercises-book-from-waiting-modal-form/exercises-book-from-waiting-modal-form.component'

export const ExercisesBookFromWaitingModal: React.FC<ExercisesBookFromWaitingModalProps> = props => {
  const { form, placesOptions, paymentTypesOptions, loading, name } = props
  const { onCancel, onSave } = props

  const { isValid, onFieldsChange } = useExercisesBookFromWaitingModal(form)

  const onSaveHandler = React.useCallback((): void => {
    const values = form.getFieldsValue()
    onSave(values)
  }, [form, onSave])

  return (
    <Modal
      title={`Записать гостя ${name}`}
      open={true}
      onCancel={onCancel}
      footer={
        <Button type="primary" loading={loading} onClick={onSaveHandler} disabled={!isValid}>
          Записать
        </Button>
      }
    >
      <ExercisesBookFromWaitingModalForm
        form={form}
        loading={loading}
        placesOptions={placesOptions}
        paymentTypesOptions={paymentTypesOptions}
        onFieldsChange={onFieldsChange}
      />
    </Modal>
  )
}
