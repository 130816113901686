import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../../types/lang.types'
import { Pagination } from '../../../../../api/types/api.types'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { FetchPromotionsSalesPayload, SelectedStudiosData } from './promotions-page-sales-create.types'

export interface PromotionsPageSalesCreateState {
  sales: Nullable<Pagination<PromotionsApi.PromotionsSalesShort>>
  selectedStudios: SelectedStudiosData[]
  isLoading: boolean
  isLoaded: boolean
  isCreating: boolean
  isCreated: boolean
  error: Nullable<Error>
}

const initialState: PromotionsPageSalesCreateState = {
  sales: null,
  selectedStudios: [],
  isLoading: false,
  isLoaded: false,
  isCreating: false,
  isCreated: false,
  error: null,
}

export const { actions: promotionsPageSalesCreateActions, reducer: promotionsPageSalesCreateReducer } = createSlice({
  name: '@@promotions-page-sales-create',
  initialState,
  reducers: {
    fetchAllSales: (
      state: Draft<PromotionsPageSalesCreateState>,
      _: PayloadAction<Partial<FetchPromotionsSalesPayload>>
    ) => {
      state.isLoading = true
    },
    fetchAllSalesSuccess: (
      state: Draft<PromotionsPageSalesCreateState>,
      action: PayloadAction<Nullable<Pagination<PromotionsApi.PromotionsSalesShort>>>
    ) => {
      state.sales = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllSalesError: (state: Draft<PromotionsPageSalesCreateState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeSales: (state: Draft<PromotionsPageSalesCreateState>, _: PayloadAction<{ id: string }>) => {
      state.isLoading = true
    },
    removeSalesSuccess: (state: Draft<PromotionsPageSalesCreateState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removeSalesError: (state: Draft<PromotionsPageSalesCreateState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    createSales: (state: Draft<PromotionsPageSalesCreateState>, _: PayloadAction<PromotionsApi.PromotionDTO>) => {
      state.isCreating = true
    },
    createSalesSuccess: (state: Draft<PromotionsPageSalesCreateState>, _: PayloadAction<void>) => {
      state.isCreating = false
      state.isCreated = true
    },
    createSalesError: (state: Draft<PromotionsPageSalesCreateState>, action: PayloadAction<Error>) => {
      state.isCreating = false
      state.isCreated = true
      state.error = action.payload
    },
    setSelectedStudios: (
      state: Draft<PromotionsPageSalesCreateState>,
      action: PayloadAction<SelectedStudiosData[]>
    ) => {
      state.selectedStudios = action.payload
    },
    resetSelectedStudios: (state: Draft<PromotionsPageSalesCreateState>) => {
      state.selectedStudios = []
    },
    reset: () => initialState,
  },
})
