import { FC, useMemo } from 'react'
import { Flex, Tag } from 'antd'

import { SidePeek } from '../../side-peek/side-peek.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { ExercisesPeekProps } from './exercises-peek.types'
import { ExercisesPeekHeader } from './ui/exercises-peek-header/exercises-peek-header.component'
import { ExercisesPeekClientTabs } from './ui/exercises-peek-client-tabs/exercises-peek-client-tabs.component'
import { ExercisesPeekTabs } from './ui/exercises-peek-tabs/exercises-peek-tabs.component'
import { ExercisesPeekClientList } from './ui/exercises-peek-client-list/exercises-peek-client-list.component'
import { ExercisesPeekEmtyBookings } from './ui/exercises-peek-emty-bookings/exercises-peek-emty-bookings.component'

export const ExercisesPeek: FC<ExercisesPeekProps> = props => {
  const {
    exercise,
    isLoading,
    isLoaded,
    bookings,
    bookingsTotalElements,
    isBookingsLoading,
    exercisePath,
    history,
    isHistoryLoading,
    bookingsSize,
    historyPaginationConfig,
    masterServicesOptions,
    directionsOptions,
    isTrainersLoading,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    form,
    clientsInWaitingList,
    clientBookingCreatePermission,
    clientBookingDeletePermission,
    exerciseUpdatePermission,
    exerciseDeletePermission,
    fetchHistory,
    onChangeHistoryPage,
    onChangeHistoryPageSize,
    onLoadMoreBookings,
    onBooking,
    onComment,
    onVisit,
    onRefetchTrainers,
    onCreateTransaction,
    onChangePaymentType,
    onCancelBooking,
    onEditExerciseComment,
    onEditExercise,
    onCancelExercise,
    onCancelPeek,
  } = props

  const sidePeekTag = useMemo(() => {
    if (isDef(exercise?.currentExerciseIndex) && isDef(exercise?.totalExercises)) {
      return (
        <Tag color="geekblue">
          Длинная запись, {exercise.currentExerciseIndex + 1} из {exercise.totalExercises}
        </Tag>
      )
    }
  }, [exercise])

  const renderContent = () => {
    if (!isLoaded || isLoading) {
      return <PageLoader />
    }

    if (!exercise) {
      return <PageEmpty description="Запись не найдена" />
    }

    const {
      id,
      type,
      timeFrom,
      timeTo,
      trainers,
      direction,
      studiosRoom,
      timetableComment,
      comment,
      clientsCount,
      maxClientsCount,
      confirmedCount,
    } = exercise

    const isGroupFormat = type.format === 'GROUP'

    const shouldShowEmptyState = !isDefAndNotEmpty(bookings)

    const sortedBookings = isGroupFormat
      ? bookings
      : bookings?.sort((a, b) => {
          if (a.isCancelled === b.isCancelled) return 0
          return a.isCancelled ? 1 : -1
        })

    return (
      <Flex vertical gap={16}>
        <ExercisesPeekHeader
          timeFrom={timeFrom}
          timeTo={timeTo}
          trainers={trainers}
          direction={direction.title}
          room={studiosRoom.title}
          timetableComment={timetableComment}
          exerciseComment={comment}
          onEditCommentHandler={onEditExerciseComment}
        />
        {shouldShowEmptyState ? (
          <ExercisesPeekEmtyBookings
            isLoading={isBookingsLoading}
            clientBookingCreatePermission={clientBookingCreatePermission}
            onBookingHandler={onBooking}
          />
        ) : isGroupFormat ? (
          <ExercisesPeekClientList
            exerciseId={id}
            bookings={bookings || []}
            isBookingsLoading={isBookingsLoading}
            maxClientsCount={maxClientsCount}
            clientsCount={clientsCount}
            clientsInWaitingList={clientsInWaitingList}
            confirmedCount={confirmedCount}
            bookingsSize={bookingsSize}
            bookingsTotalElements={bookingsTotalElements}
            clientBookingCreatePermission={clientBookingCreatePermission}
            clientBookingDeletePermission={clientBookingDeletePermission}
            onLoadMoreBookingsHandler={onLoadMoreBookings}
            onBookingHandler={onBooking}
            onCommentHandler={onComment}
            onVisitHandler={onVisit}
            onCreateTransactionHandler={onCreateTransaction}
            onChangePaymentTypeHandler={onChangePaymentType}
            onCancelBookingHandler={onCancelBooking}
          />
        ) : (
          <ExercisesPeekClientTabs
            exerciseId={id}
            bookings={sortedBookings || []}
            isBookingsLoading={isBookingsLoading}
            maxClientsCount={maxClientsCount}
            clientBookingCreatePermission={clientBookingCreatePermission}
            clientBookingDeletePermission={clientBookingDeletePermission}
            onBookingHandler={onBooking}
            onCommentHandler={onComment}
            onVisitHandler={onVisit}
            onCreateTransactionHandler={onCreateTransaction}
            onChangePaymentTypeHandler={onChangePaymentType}
            onCancelBookingHandler={onCancelBooking}
          />
        )}
        <ExercisesPeekTabs
          exercise={exercise}
          form={form}
          isLoading={isLoading || isTrainersLoading}
          history={history}
          isHistoryLoading={isHistoryLoading}
          historyPaginationConfig={historyPaginationConfig}
          masterServicesOptions={masterServicesOptions}
          directionsOptions={directionsOptions}
          trainersOptions={trainersOptions}
          studiosOptions={studiosOptions}
          studiosRoomsOptions={studiosRoomsOptions}
          exerciseUpdatePermission={exerciseUpdatePermission}
          fetchHistory={fetchHistory}
          onChangeHistoryPageHandler={onChangeHistoryPage}
          onChangeHistoryPageSizeHandler={onChangeHistoryPageSize}
          onEdit={onEditExercise}
          handleRefetchTrainers={onRefetchTrainers}
        />
      </Flex>
    )
  }

  return (
    <SidePeek
      isLoading={!isLoaded || isLoading}
      link={exercisePath}
      tag={sidePeekTag}
      removePermission={exerciseDeletePermission}
      onRemove={onCancelExercise}
      onCancel={onCancelPeek}
    >
      {renderContent()}
    </SidePeek>
  )
}
