import React from 'react'
import { Button, Flex, Typography } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { formatRubleCurrency } from '../../../../../format/number.format'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import style from './contracts-info-contract-card.module.scss'
import { formatDateToHumanize } from '../../../../../format/date.format'

interface ContractsInfoContractCardArchiveProps {
  contract: ContractsApi.Contracts
}

export const ContractsInfoContractCardArchive = ({ contract }: ContractsInfoContractCardArchiveProps) => {
  return (
    <Flex vertical gap={'large'} className={style.cardContainer} justify={'center'}>
      <Flex vertical>
        <Typography.Text>До {formatDateToHumanize(contract.validUntil)}</Typography.Text>
        <Flex justify={'space-between'}>
          <Flex vertical style={{ width: '100%' }}>
            <Flex>
              <Flex vertical justify={'space-between'} style={{ flex: 1 }}>
                <Typography.Title level={5} className={style.cardTitle}>
                  Договор {contract.name}
                </Typography.Title>
                <Typography.Text type={'secondary'}>
                  {contract.type === 'POSTPAY' ? 'Постоплата' : `Предоплата`}
                </Typography.Text>
              </Flex>
              {contract.link && (
                <a href={contract.link} target="_blank" download rel="noreferrer">
                  <Button size="small" icon={<DownloadOutlined />} />
                </a>
              )}
            </Flex>
            <Flex className={style.contractBalanceBadgeArchive} gap={'small'} justify={'center'}>
              <Flex vertical className={style.balanceBadge}>
                <span className={style.badgeText}>Лимит</span>
                <span className={style.badgeNumber}>{formatRubleCurrency(contract.maxTotalAmount)}</span>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
