import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { SearchPromotionsProductParams } from '../promotions-page-sales-create/promotions-page-sales-create.types'
import { ProductsApi } from '../../../../../api/types/products-api.types'
import { Nullable } from '../../../../../types/lang.types'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { StudiosApi } from '../../../../../api/types/studios-api.types'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { OrganizationsApi } from '../../../../../api/types/organizations-api.types'
import { ClientsCategoriesApi } from '../../../../../api/types/clients-categories-api.types'
import { Pagination } from '../../../../../api/types/api.types'
import {
  FetchPromotionsModalData,
  FetchPromotionsSalesByIdPayload,
  UpdatePromotionsSalesPayload,
} from './promotions-page-sales-edit.types'

export interface PromotionsPageSalesEditState {
  sale: Nullable<PromotionsApi.Promotion>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  products: Nullable<ProductsApi.Product[]>
  counterparties: Nullable<Pagination<ContractsApi.Counterparties>>
  franchises: Nullable<Pagination<OrganizationsApi.Organization>>
  categories: Nullable<Pagination<ClientsCategoriesApi.ClientCategory>>
  isModalLoading: boolean
  isModalLoaded: boolean
  isFetchingById: boolean
  isFetchedById: boolean
  isEditing: boolean
  isEdited: boolean
  error: Nullable<Error>
}

const initialState: PromotionsPageSalesEditState = {
  sale: null,
  products: null,
  counterparties: null,
  franchises: null,
  categories: null,
  studios: null,
  isModalLoading: false,
  isModalLoaded: false,
  isFetchingById: false,
  isFetchedById: false,
  isEditing: false,
  isEdited: false,
  error: null,
}

export const { actions: promotionsPageSalesEditActions, reducer: promotionsPageSalesEditReducer } = createSlice({
  name: '@@promotions-page-sales-edit',
  initialState,
  reducers: {
    fetchPromotionsModalData: (state: Draft<PromotionsPageSalesEditState>, _: PayloadAction<void>) => {
      state.isModalLoading = true
    },
    fetchPromotionsModalDataSuccess: (
      state: Draft<PromotionsPageSalesEditState>,
      action: PayloadAction<FetchPromotionsModalData>
    ) => {
      const { counterparties, franchises, categories, studios } = action.payload

      state.studios = studios
      state.franchises = franchises
      state.counterparties = counterparties
      state.categories = categories
      state.isModalLoading = false
      state.isModalLoaded = true
    },
    fetchPromotionsModalDataError: (state: Draft<PromotionsPageSalesEditState>, action: PayloadAction<Error>) => {
      state.isModalLoading = false
      state.isModalLoaded = true
      state.error = action.payload
    },
    fetchPromotionsSalesById: (
      state: Draft<PromotionsPageSalesEditState>,
      _: PayloadAction<FetchPromotionsSalesByIdPayload>
    ) => {
      state.isFetchingById = true
    },
    fetchPromotionsSalesByIdSuccess: (
      state: Draft<PromotionsPageSalesEditState>,
      action: PayloadAction<Nullable<PromotionsApi.Promotion>>
    ) => {
      state.sale = action.payload
      state.isFetchingById = false
      state.isFetchedById = true
    },
    fetchPromotionsSalesByIdError: (state: Draft<PromotionsPageSalesEditState>, action: PayloadAction<Error>) => {
      state.isFetchingById = false
      state.isFetchedById = true
      state.error = action.payload
    },
    updatePromotionsSales: (
      state: Draft<PromotionsPageSalesEditState>,
      _: PayloadAction<UpdatePromotionsSalesPayload>
    ) => {
      state.isEditing = true
    },
    updatePromotionsSalesSuccess: (state: Draft<PromotionsPageSalesEditState>, _: PayloadAction<void>) => {
      state.isEditing = false
      state.isEdited = true
    },
    searchPromotionsProducts: (
      state: Draft<PromotionsPageSalesEditState>,
      _: PayloadAction<Partial<SearchPromotionsProductParams>>
    ) => {
      state.isEditing = true
    },
    searchPromotionsProductsSuccess: (
      state: Draft<PromotionsPageSalesEditState>,
      action: PayloadAction<Nullable<ProductsApi.Product[]>>
    ) => {
      state.products = action.payload
      state.isEditing = false
      state.isEdited = true
    },
    searchPromotionsProductsError: (state: Draft<PromotionsPageSalesEditState>, action: PayloadAction<Error>) => {
      state.isEditing = false
      state.isEdited = true
      state.error = action.payload
    },
    resetPromotionsProducts: (state: Draft<PromotionsPageSalesEditState>) => {
      state.products = null
    },
    updatePromotionsSalesError: (state: Draft<PromotionsPageSalesEditState>, action: PayloadAction<Error>) => {
      state.isEditing = false
      state.isEdited = true
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
