import { UsergroupAddOutlined } from '@ant-design/icons'
import { Flex, Typography, Button, Tooltip } from 'antd'

import { CustomCard } from '../../../../custom-card/custom-card.component'
import './exercises-peek-emty-bookings.styles.less'

interface Props {
  isLoading: boolean
  clientBookingCreatePermission: boolean
  onBookingHandler: () => void
}

export const ExercisesPeekEmtyBookings: React.FC<Props> = props => {
  const { isLoading, clientBookingCreatePermission, onBookingHandler } = props

  return (
    <CustomCard customClassName="exercises-peek-emty-bookings">
      <Flex vertical align="center" gap={10}>
        <UsergroupAddOutlined style={{ fontSize: '45px', color: '#00000073' }} />
        <Typography.Text strong>В записи ещё нет гостей</Typography.Text>
        <Tooltip title={!clientBookingCreatePermission ? 'У вас нет разрешения на это действие' : ''}>
          <Button disabled={isLoading || !clientBookingCreatePermission} onClick={onBookingHandler}>
            Добавить гостя
          </Button>
        </Tooltip>
      </Flex>
    </CustomCard>
  )
}
