import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../../app.store'
import { genCounterpartiesContractFormValues } from '../../../../../mapping/contracts-info-mapping'

export const getCounterpartyContractInternal = (state: AppState) =>
  state.contractsInfoPage.contractsInfoPageContractsEdit.contract
export const getCounterpartyContractFormValue = createSelector(
  getCounterpartyContractInternal,
  genCounterpartiesContractFormValues
)
export const getCounterpartyContractsIsLoadingById = (state: AppState) =>
  state.contractsInfoPage.contractsInfoPageContractsEdit.isLoadingById

export const getCounterpartyContractIsEditing = (state: AppState) =>
  state.contractsInfoPage.contractsInfoPageContractsEdit.isEditing
