import * as React from 'react'
import { Avatar, Button, Flex, Input, Modal, Space, Tag, Typography } from 'antd'
import { useState } from 'react'
import { UserOutlined } from '@ant-design/icons'

import { PageLoader } from '../../page/page-loader/page-loader.component'
import { ExercisesCommentsModalProps } from './exercises-comments-modal.types'
import './exercises-comments-modal.styles.less'

export const ExercisesCommentsModal: React.FC<ExercisesCommentsModalProps> = props => {
  const { loading, comments, onSave, onCancel } = props

  const [comment, setComment] = useState('')

  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }

  const onSaveHandler = React.useCallback((): void => {
    onSave(comment)
  }, [comment, onSave])

  return (
    <Modal className="exercises-comments-modal" open={true} footer={null} onCancel={onCancel}>
      {loading ? (
        <PageLoader className="exercises-waiting-clients-modal__loader" />
      ) : (
        <Flex vertical gap={10}>
          <Space size={5} className="exercises-comments-modal__title">
            <Typography.Text>Комментарии о клиенте</Typography.Text>
            <Tag className="exercises-comments-modal__tag">{comments?.length || 0}</Tag>
          </Space>
          {comments?.map(comment => (
            <Flex gap={16} className="exercises-comments-modal__comment" key={comment.id}>
              <Avatar src={comment.photo} icon={<UserOutlined />} />
              <Flex vertical gap={4}>
                <Typography.Text strong>
                  {comment.employeeName} {comment.createDate}
                </Typography.Text>
                <Typography.Text type="secondary">{comment.comment}</Typography.Text>
              </Flex>
            </Flex>
          ))}
          <Input.TextArea
            className="exercises-comments-modal__text-area"
            placeholder="Текст комментария"
            value={comment}
            onChange={onChangeComment}
          />
          <Button className="exercises-comments-modal__button" disabled={!comment.trim()} onClick={onSaveHandler}>
            Добавить комментарий
          </Button>
        </Flex>
      )}
    </Modal>
  )
}
