import { Button, Col, DatePicker, Flex, Form, Input, Row, Select, Tag, Tooltip, Typography } from 'antd'
import { CalendarOutlined, ContactsOutlined, InboxOutlined, ThunderboltOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import { ExercisesPeekOverviewProps } from './exercises-peek-overview.types'
import './exercises-peek-overview.styles.less'
import { InputTimeRange } from '../../../../controls/input-time-range/input-time-range.component'
import { formatDate, formatTime } from '../../../../../format/date.format'
import { useExercisesPeekOverview } from './exercises-peek-overview.hook'

export const ExercisesPeekOverview: React.FC<ExercisesPeekOverviewProps> = props => {
  const {
    exercise,
    form,
    isLoading,
    studiosOptions,
    studiosRoomsOptions,
    trainersOptions,
    masterServicesOptions,
    directionsOptions,
    exerciseUpdatePermission,
    onEdit,
    handleRefetchTrainers,
  } = props

  const {
    timeToValidationRules,
    studiosRoomsValidationRules,
    subServiceValidationRules,
    maxClientsCountValidationRules,
    isFormTouched,
    buttonRef,
    studioId,
    masterServiceId,
    onChangeStudioHandler,
    onChangemMsterServiceHandler,
    onChangeStudioRoomHandler,
    onChangeDateHandler,
    onChangeTimeHandler,
    onValuesChange,
    onEditExercise,
  } = useExercisesPeekOverview({ form, clientsCount: exercise.clientsCount, onEdit, handleRefetchTrainers })

  return (
    <Form
      layout="vertical"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      form={form}
      disabled={isLoading}
      onValuesChange={onValuesChange}
    >
      <Flex vertical gap={16} className="exercises-peek-overview">
        <Typography.Title level={3} className="exercises-peek-overview__title">
          Карточка {exercise.type.format === 'PERSONAL' && 'персональной'} записи
        </Typography.Title>
        <Row>
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <InboxOutlined className="exercises-peek-overview__icon" />
              Категория записи
            </Typography.Text>
          </Col>
          <Col>
            <Tag color="magenta">{exercise.type.name}</Tag>
          </Col>
        </Row>
        {exercise.type.format === 'GROUP' ? (
          <Row align="top">
            <Col style={{ width: '250px' }}>
              <Typography.Text type="secondary">
                <CalendarOutlined className="exercises-peek-overview__icon" />
                Групповое направление
              </Typography.Text>
            </Col>
            <Col className="exercises-peek-overview__column">
              <Form.Item name="direction" className="exercises-peek-overview__form-item">
                <Select options={directionsOptions} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row align="top">
            <Col style={{ width: '250px' }}>
              <Typography.Text type="secondary">
                <ThunderboltOutlined className="exercises-peek-overview__icon" />
                Услуга
              </Typography.Text>
            </Col>
            <Col className="exercises-peek-overview__column">
              <Flex vertical gap={10}>
                <Form.Item name="masterService" className="exercises-peek-overview__form-item">
                  <Select options={masterServicesOptions} onChange={onChangemMsterServiceHandler} />
                </Form.Item>
                <Form.Item
                  name="direction"
                  className="exercises-peek-overview__form-item"
                  rules={subServiceValidationRules}
                >
                  <Select
                    options={directionsOptions?.filter(subService => subService.masterServiceId === masterServiceId)}
                  />
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        )}
        <Row align="top">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="exercises-peek-overview__icon" />
              Исполнитель
            </Typography.Text>
          </Col>
          <Col className="exercises-peek-overview__column">
            <Row align="middle" gutter={10}>
              <Col span={24}>
                <Form.Item label="Исполнитель" name="trainers" className="exercises-peek-overview__form-item">
                  <Select
                    mode="multiple"
                    placeholder="Исполнители"
                    filterOption={(input, option) => {
                      const label = option?.label ? String(option.label) : ''
                      return label.toLowerCase().includes(input.toLowerCase())
                    }}
                  >
                    {trainersOptions
                      ?.filter(option => !option.filteredOut)
                      ?.map(option => (
                        <Select.Option key={option.value} value={option.value} label={option.label}>
                          <Typography style={{ color: 'black' }}>{option.label}</Typography>
                        </Select.Option>
                      ))}
                    {trainersOptions?.some(option => option.filteredOut) && (
                      <Select.OptGroup label="Не работают">
                        {trainersOptions
                          ?.filter(option => option.filteredOut)
                          .map(option => (
                            <Select.Option key={option.value} value={option.value} label={option.label}>
                              <Typography style={{ color: 'grey' }}>{option.label}</Typography>
                            </Select.Option>
                          ))}
                      </Select.OptGroup>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align="top">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="exercises-peek-overview__icon" />
              Время и место записи
            </Typography.Text>
          </Col>
          <Col className="exercises-peek-overview__column">
            <Flex vertical gap={10}>
              <Row align="middle" gutter={10}>
                <Col span={12}>
                  <Form.Item label="Студия" name="studioId" className="exercises-peek-overview__form-item">
                    <Select options={studiosOptions} onChange={onChangeStudioHandler} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Зал"
                    name="roomId"
                    className="exercises-peek-overview__form-item"
                    required={false}
                    rules={studiosRoomsValidationRules}
                  >
                    <Select
                      options={studiosRoomsOptions?.filter(room => room.studioId === studioId)}
                      onChange={onChangeStudioRoomHandler}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" gutter={10}>
                <Col span={12} flex={1}>
                  <Form.Item label="Дата" name="date" className="exercises-peek-overview__form-item">
                    <DatePicker
                      placeholder="Дата"
                      format="D MMMM"
                      allowClear={false}
                      disabled={isLoading}
                      onChange={onChangeDateHandler}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Время"
                    name="time"
                    className="exercises-peek-overview__form-item"
                    rules={[timeToValidationRules]}
                  >
                    <InputTimeRange
                      className="exercises-peek-overview__time-range"
                      value={[null, null]}
                      placeholder={['Время начала', 'Время окончания']}
                      loading={isLoading}
                      onChange={onChangeTimeHandler}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Flex>
          </Col>
        </Row>
        <Row align="top">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="exercises-peek-overview__icon" />
              Вместимость
            </Typography.Text>
          </Col>
          <Col className="exercises-peek-overview__column">
            <Flex vertical gap={10}>
              <Form.Item
                label="Количество человек"
                name="maxClientsCount"
                required={false}
                rules={[{ required: true, message: 'Укажите вместимость' }, ...maxClientsCountValidationRules]}
              >
                <Input type="numeric" style={{ width: '64px' }} />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row align="top">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <ContactsOutlined className="exercises-peek-overview__icon" />
              Создал запись
            </Typography.Text>
          </Col>
          <Col className="exercises-peek-overview__column">
            <Flex vertical>
              <Typography.Text strong>{exercise.createdBy?.name}</Typography.Text>
              {exercise.createdAt && (
                <Typography.Text type="secondary" strong>
                  {formatDate(exercise.createdAt, 'DD MMMM')} в {formatTime(exercise.createdAt)}
                </Typography.Text>
              )}
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '250px' }} />
          <Col className="exercises-peek-overview__column">
            <div ref={buttonRef}>
              <Tooltip title={!exerciseUpdatePermission ? 'У вас нет разрешения на это действие' : ''}>
                <Button
                  type="primary"
                  className={clsx('exercises-peek-overview__button', {
                    'exercises-peek-overview__button--hidden': !isFormTouched,
                  })}
                  loading={isLoading}
                  disabled={isLoading || !exerciseUpdatePermission}
                  onClick={onEditExercise}
                >
                  Применить изменения
                </Button>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </Flex>
    </Form>
  )
}
