import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'
import { notification } from 'antd'

import { genPaginationParamsDTO } from '../../../../../utils/pagination.utils'
import { api } from '../../../../../api/api'
import { callApi } from '../../../../../utils/sagas.utils'
import { getRouterState } from '../../../../common/router/router.selectors'
import { genPromotionsPageQueryParams } from '../../../../../pages/promotions-page/promotions-page.utils'
import { isDef, NNumber } from '../../../../../types/lang.types'
import { getCurrentStudioOffset } from '../../../../common/layout/layout.selectors'
import { genPromotionsCodesDTO, genPromotionsCodesPatternDTO } from '../../../../../mapping/promotions.mapping'
import { modalActions } from '../../../../common/modal/modal.slice'
import { promotionsPageCodesCreateActions } from './promotions-page-codes-create.slice'

export function* fetchAllCodes(action: ReturnType<typeof promotionsPageCodesCreateActions.fetchAllCodes>) {
  try {
    const { size, page, isShowPast = true } = action.payload

    const paginationParams = genPaginationParamsDTO(page, size)
    const params = {
      ...paginationParams,
      showPast: isShowPast,
    }

    const response: Awaited<ReturnType<typeof api.promotionsService.fetchAllPromotionsCodes>> = yield callApi(
      api.promotionsService.fetchAllPromotionsCodes,
      params
    )

    yield put(promotionsPageCodesCreateActions.fetchAllCodesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesCreateActions.fetchAllCodesError(new Error()))
  }
}

export function* removeCodes(action: ReturnType<typeof promotionsPageCodesCreateActions.removeCodes>) {
  try {
    const { id } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    yield callApi(api.promotionsService.removePromotionsCodes, id)

    yield put(promotionsPageCodesCreateActions.removeCodesSuccess())
    yield put(promotionsPageCodesCreateActions.fetchAllCodes({ ...params }))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesCreateActions.removeCodesError(new Error()))
  }
}

export function* removePatternCodes(action: ReturnType<typeof promotionsPageCodesCreateActions.removePatternCodes>) {
  try {
    const { id } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    yield callApi(api.promotionsService.removePromotionsPatternCodes, id)

    yield put(promotionsPageCodesCreateActions.removePatternCodesSuccess())
    yield put(promotionsPageCodesCreateActions.fetchAllCodes({ ...params }))
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesCreateActions.removePatternCodesError(new Error()))
  }
}

export function* createCodes(action: ReturnType<typeof promotionsPageCodesCreateActions.createCodes>) {
  try {
    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)
    const studioOffset: NNumber = yield select(getCurrentStudioOffset)

    const promotionsCodeDTO = genPromotionsCodesDTO(action.payload, studioOffset)

    if (isDef(promotionsCodeDTO)) {
      yield callApi(api.promotionsService.createPromotionsCodes, promotionsCodeDTO)
      yield put(promotionsPageCodesCreateActions.createCodesSuccess())

      yield notification.success({
        message: 'Промокод успешно создан',
      })

      yield put(promotionsPageCodesCreateActions.fetchAllCodes({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesCreateActions.createCodesError(new Error()))
  }
}

export function* createCodesPattern(action: ReturnType<typeof promotionsPageCodesCreateActions.createCodesPattern>) {
  try {
    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genPromotionsPageQueryParams(search)

    const studioOffset: NNumber = yield select(getCurrentStudioOffset)
    const promotionsCodePatternDTO = genPromotionsCodesPatternDTO(action.payload, studioOffset)

    if (isDef(promotionsCodePatternDTO)) {
      yield callApi(api.promotionsService.createPromotionsCodesPattern, promotionsCodePatternDTO)

      yield put(promotionsPageCodesCreateActions.createCodesPatternSuccess())

      yield notification.success({
        message: 'Промокод успешно создан',
      })

      yield put(promotionsPageCodesCreateActions.fetchAllCodes({ ...params }))
      yield put(modalActions.closeLast())
    }
  } catch (e) {
    console.error(e)
    yield put(promotionsPageCodesCreateActions.createCodesPatternError(new Error()))
  }
}

export function* promotionsPageCodesCreateSagas() {
  yield takeLatest(promotionsPageCodesCreateActions.fetchAllCodes.type, fetchAllCodes)
  yield takeLatest(promotionsPageCodesCreateActions.removeCodes.type, removeCodes)
  yield takeLatest(promotionsPageCodesCreateActions.removePatternCodes.type, removePatternCodes)
  yield takeLatest(promotionsPageCodesCreateActions.createCodes.type, createCodes)
  yield takeLatest(promotionsPageCodesCreateActions.createCodesPattern.type, createCodesPattern)
}
