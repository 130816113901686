import React, { useState } from 'react'
import { Button, DatePicker, Divider, Flex, Form, Input, Modal, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { genDefaultDateFormat } from '../../../../../format/date.format'
import { PromotionsFormConditions } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-conditions.component'
import { PromotionsFormLimitation } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-limitation/promotions-create-form-limitation.component'
import { PromotionsFormProducts } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-products/promotions-create-form-products.component'
import { ClickableLabelSwitch } from '../../../../../components/forms/clickableLabelSwitch/ClickableLabelSwitch.component'
import { usePromotionsSalesCreate } from './promotions-page-sales-modal-create.hook'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { PromotionsSalesModalCreateProps } from './promotions-page-sales-modal-create.types'

export const PromotionsSalesModalCreate = ({ handleOk, handleCancel }: PromotionsSalesModalCreateProps) => {
  const [form] = useForm<PromotionsApi.PromotionDTO>()
  const { onSearchHandler, isCreating } = usePromotionsSalesCreate()
  const [isUnlimitedSales, setIsUnlimitedSales] = useState(true)

  function handleChangePromotionTime() {
    setIsUnlimitedSales(prev => !prev)
    if (isUnlimitedSales) {
      form.setFieldValue('activeFrom', '')
      form.setFieldValue('activeTo', '')
    }
  }

  return (
    <Modal
      title="Создание акции"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Создать акцию
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          layout="vertical"
          name="form_in_modal"
          onFinish={handleOk}
          form={form}
          initialValues={{
            products: [{}],
          }}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      <Flex gap={'large'} vertical>
        <Flex vertical gap={'middle'}>
          <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
            Основная информация
          </Typography.Title>
          <Form.Item
            style={{ width: '100%' }}
            name="name"
            label="Название промокампании"
            rules={[{ required: true, message: 'Введите название промокампании!' }]}
          >
            <Input style={{ width: '100%' }} size={'large'} />
          </Form.Item>
          {!isUnlimitedSales && (
            <Flex gap={'middle'}>
              <Form.Item
                style={{ width: '100%' }}
                name="activeFrom"
                label="Начало действия"
                rules={[{ required: true, message: 'Выберите дату начала!' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={isUnlimitedSales}
                  size={'large'}
                  format={genDefaultDateFormat()}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                name="activeTo"
                label="Конец действия"
                rules={[{ required: true, message: 'Выберите дату окончания!' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={isUnlimitedSales}
                  size={'large'}
                  format={genDefaultDateFormat()}
                />
              </Form.Item>
            </Flex>
          )}
          <ClickableLabelSwitch
            name={'isUnlimitedSales'}
            label={'Ограничить по времени'}
            form={form}
            onChange={handleChangePromotionTime}
          />
        </Flex>

        <PromotionsFormProducts onSearchHandler={onSearchHandler} form={form} />
        <PromotionsFormLimitation form={form} />
        <PromotionsFormConditions form={form} />
      </Flex>
    </Modal>
  )
}
