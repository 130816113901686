import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { FetchPromotionsCodesPayload } from './promotions-page-codes-create.types'
import { Nullable } from '../../../../../types/lang.types'
import { Pagination } from '../../../../../api/types/api.types'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'

export interface PromotionsPageCodesCreateState {
  codes: Nullable<Pagination<PromotionsApi.PromotionsCodesShort>>
  isLoading: boolean
  isLoaded: boolean
  isCreating: boolean
  isCreated: boolean
  error: Nullable<Error>
}

const initialState: PromotionsPageCodesCreateState = {
  codes: null,
  isLoading: false,
  isLoaded: false,
  isCreating: false,
  isCreated: false,
  error: null,
}

export const { actions: promotionsPageCodesCreateActions, reducer: promotionsPageCodesCreateReducer } = createSlice({
  name: '@@promotions-page-codes-create',
  initialState,
  reducers: {
    fetchAllCodes: (
      state: Draft<PromotionsPageCodesCreateState>,
      _: PayloadAction<Partial<FetchPromotionsCodesPayload>>
    ) => {
      state.isLoading = true
    },
    fetchAllCodesSuccess: (
      state: Draft<PromotionsPageCodesCreateState>,
      action: PayloadAction<Nullable<Pagination<PromotionsApi.PromotionsCodesShort>>>
    ) => {
      state.codes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllCodesError: (state: Draft<PromotionsPageCodesCreateState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeCodes: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<{ id: string }>) => {
      state.isLoading = true
    },
    removeCodesSuccess: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removeCodesError: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    removePatternCodes: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<{ id: string }>) => {
      state.isLoading = true
    },
    removePatternCodesSuccess: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removePatternCodesError: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    createCodes: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<PromotionsApi.CodeDTO>) => {
      state.isCreating = true
    },
    createCodesSuccess: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<void>) => {
      state.isCreating = false
      state.isCreated = true
    },
    createCodesError: (state: Draft<PromotionsPageCodesCreateState>, action: PayloadAction<Error>) => {
      state.isCreating = true
      state.isCreated = true
      state.error = action.payload
    },
    createCodesPattern: (
      state: Draft<PromotionsPageCodesCreateState>,
      _: PayloadAction<PromotionsApi.CodePatternDTO>
    ) => {
      state.isCreating = true
    },
    createCodesPatternSuccess: (state: Draft<PromotionsPageCodesCreateState>, _: PayloadAction<void>) => {
      state.isCreating = false
      state.isCreated = true
    },
    createCodesPatternError: (state: Draft<PromotionsPageCodesCreateState>, action: PayloadAction<Error>) => {
      state.isCreating = false
      state.isCreated = true
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
