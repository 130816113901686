import { ColumnsType } from 'antd/lib/table'
import { Badge } from 'antd'
import React from 'react'
import { PresetStatusColorType } from 'antd/es/_util/colors'

import { formatRubleCurrency } from '../../../format/number.format'
import { PromotionsApi } from '../../../api/types/promotions-api.types'
import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { PromotionsSalesTableRow } from './promotions-sales-table.types'
import { formatDateToHumanize } from '../../../format/date.format'
import { PromotionsSalesTableActions } from './promotions-sales-table-action/promotions-sales-table-action.component'

export interface PromotionsSalesServicesTableActions {
  onEdit?: (id: string) => void
  onRemove: (id: string) => void
}

interface PromotionSalesBadgeStatus {
  text: string
  badgeStatus: PresetStatusColorType
}

const promotionsSalesTableBadgeObj: Record<PromotionsApi.PromotionsSalesStatus, PromotionSalesBadgeStatus> = {
  FINISHED: { text: 'Истекла', badgeStatus: 'default' },
  NOT_STARTED: { text: 'В ожидании', badgeStatus: 'processing' },
  ACTIVE: { text: 'Активен', badgeStatus: 'success' },
}

const formatDateRange = (dateActiveFrom: string, dateActiveTo: string) => {
  if (!dateActiveFrom && !dateActiveTo) {
    return 'Бессрочный'
  }

  const formattedDateFrom = formatDateToHumanize(dateActiveFrom)
  const formattedDateTo = formatDateToHumanize(dateActiveTo)

  return `${formattedDateFrom} - ${formattedDateTo}`.trim()
}

export function genPromotionsSalesTableColumns({
  onEdit,
  onRemove,
}: PromotionsSalesServicesTableActions): ColumnsType<PromotionsSalesTableRow> {
  return [
    {
      title: 'Название',
      dataIndex: 'promotionsName',
      key: 'promotionsName',
      render: (_, { promotionsName }) => <TableCellText text={promotionsName} />,
    },
    {
      title: 'Студии',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (_, { studios, hasStudioLimitation }) => (
        <TableCellText text={hasStudioLimitation ? studios.map(studio => studio.name).join(', ') : 'Все'} />
      ),
    },
    {
      title: 'Срок действия',
      dataIndex: 'dateActive',
      key: 'dateActive',
      render: (_, { dateActiveTo, dateActiveFrom }) => (
        <TableCellText text={formatDateRange(dateActiveFrom, dateActiveTo)} />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => (
        <Badge
          style={{ whiteSpace: 'nowrap' }}
          status={promotionsSalesTableBadgeObj[status].badgeStatus}
          text={promotionsSalesTableBadgeObj[status].text}
        />
      ),
    },
    {
      title: 'Скидка',
      dataIndex: 'sales',
      key: 'sales',
      render: (_, { sales, salesType }) => (
        <TableCellText
          whiteSpace={'no-wrap'}
          text={salesType === 'FIXED' ? formatRubleCurrency(sales) : `${sales} %`}
        />
      ),
    },
    {
      title: 'Операции',
      key: 'action',
      render: (_, { promotionsName, id }) => (
        <PromotionsSalesTableActions id={id} name={promotionsName} onEdit={onEdit} onRemove={onRemove} />
      ),
    },
  ]
}
