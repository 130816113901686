import { Days, PaymentType } from '@api/types/api.types'
import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'

import { BreaksApi } from '../api/types/breaks.types'
import { ExercisesApi } from '../api/types/exercises-api.types'
import { ExercisesTimetableApi } from '../api/types/exercises-timetable-api.types'
import { ExercisesFormValuesDTO } from '../components/exercises/exercises-form/exercises-form.types'
import { TimetableDataItem } from '../components/exercises/exercises-table/exercises-table.types'
import { ScheduleModalConflictsExerciseValues } from '../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.types'
import { formatDate, formatDayjsToDate, genDefaultTimeFormat } from '../format/date.format'
import { formatFormValueToPhoneNumber } from '../format/phone.format'
import { formatPaymentType } from '../format/text.format'
import { isDef, isDefAndNotEmpty, NNumber, NString, Nullable } from '../types/lang.types'
import { TimetableItemType } from '../types/timetable.types'
import {
  mapColoredDictionaryItemToEntityItem,
  mapDictionaryItemsListToEntityItemsList,
  mapDictionaryItemToEntityItem,
  mapPaymentTypeToApi,
  mapPaymentTypeToClient,
} from './api.mapping'
import { mapBreaksToTimetableDataItems } from './breaks.mapping'
import { ExercisesTypesApi } from '../api/types/exercises-types-api.types'
import { ExercisesPeekFormValues } from '../components/exercises/exercises-peek/ui/exercises-peek-overview/exercises-peek-overview.types'
import {
  ExercisesPeekBooking,
  ExercisesPeekExercise,
} from '../components/exercises/exercises-peek/exercises-peek.types'
import { ExercisesBookingFormValues } from '../components/exercises/exercises-booking-modal/exercises-booking-modal-form/exercises-booking-modal-form.types'
import { ExercisesBookFromWaitingFormValues } from '../components/exercises/exercises-book-from-waiting-modal/exercises-book-from-waiting-modal-form/exercises-book-from-waiting-modal-form.types'
import { ExercisesBookingComment } from '../components/exercises/exercises-comments-modal/exercises-comments-modal.types'

export function genExercisesCreateDTO(
  studioRoomId: string,
  values: Nullable<ExercisesFormValuesDTO>,
  date: string
): Nullable<ExercisesApi.ExercisesCreateDTO> {
  if (isDef(values)) {
    const { direction, subService, type, time, trainers, maxClientsCount } = values

    if (isDef(time.start) && isDef(time.end)) {
      const dateFrom = date + time.start.substring(date.length)
      const dateTo = date + time.end.substring(date.length)

      if (dayjs(dateFrom).isValid() && dayjs(dateTo).isValid()) {
        return {
          direction: isDef(direction) ? direction : subService,
          type: type,
          timeFrom: dateFrom,
          timeTo: dateTo,
          trainers,
          maxClientsCount,
          roomId: studioRoomId,
          clientId: values?.clientId,
        }
      }
    }
  }

  return null
}

export function mapExercisesToTimetableDataItems(
  exercises: Nullable<ExercisesApi.Exercise[]>
): Nullable<TimetableDataItem[]> {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce<TimetableDataItem[]>((acc, exercise) => {
      const { timeFrom, timeTo } = exercise

      const studiosRoom = mapColoredDictionaryItemToEntityItem(exercise.room)

      if (isDef(timeFrom) && isDef(timeTo) && isDef(studiosRoom)) {
        acc.push({
          id: exercise.id,
          timeFrom,
          timeTo,
          direction: mapDictionaryItemToEntityItem(exercise.direction),
          type: exercise.type,
          trainers: mapDictionaryItemsListToEntityItemsList(exercise.trainers),
          studiosRoom,
          clientsCounts: exercise.clientsCount,
          maxClientsCount: exercise.maxClientsCount,
          confirmedCount: exercise.confirmedCount,
          comment: exercise.comment,
          clientComment: exercise.masterServiceBooking?.clientComment,
          isFilled: exercise?.isFilled ?? false,
          isHidden: exercise?.isHidden ?? false,
          paymentType: exercise?.masterServiceBooking?.paymentType,
          masterServiceStatus: exercise?.masterServiceBooking?.status,
          guest: {
            phone: exercise?.masterServiceBooking?.client?.phone,
            fullName: `${exercise?.masterServiceBooking?.client?.firstName ?? ''} ${
              exercise?.masterServiceBooking?.client?.lastName ?? ''
            }`,
            category: exercise?.masterServiceBooking?.client?.category?.name,
          },
          recordType: exercise?.type?.name,
          isMasterService: exercise?.direction?.isMasterService ?? false,
          timetableItemType: TimetableItemType.EXERCISE,
          timetableComment: exercise.timetableComment,
        })
      } else {
        console.error('Invalid exercise timeFrom or timeTo', exercise)
      }

      return acc
    }, [])
  }
  return null
}

export function mapTimetableItemsToTimetableDataItems(
  exercises: Nullable<ExercisesApi.Exercise[]>,
  breaks: Nullable<BreaksApi.Break[]>,
  clientBookingReadPermission: boolean,
  timetableReadPermission: boolean
): Nullable<TimetableDataItem[]> {
  const filteredExercises = isDefAndNotEmpty(exercises)
    ? exercises.filter(activity => {
        if (activity.timetableId !== null) {
          return timetableReadPermission
        } else {
          return clientBookingReadPermission
        }
      })
    : null

  const exercisesTableDataItems = mapExercisesToTimetableDataItems(filteredExercises)
  const breaksTableDataItems = mapBreaksToTimetableDataItems(breaks)

  return [...(exercisesTableDataItems || []), ...(breaksTableDataItems || [])]
}

export function mapExerciseToSchedulePagePeekExercise(
  exercise: Nullable<ExercisesApi.Exercise>,
  exerciseLinked?: Nullable<ExercisesApi.ExerciseLinked[]>
): Nullable<ExercisesPeekExercise> {
  if (isDef(exercise)) {
    const {
      timeFrom,
      timeTo,
      comment,
      commentModifiedAt,
      commentModifiedBy,
      createdAt,
      createdBy,
      maxClientsCount,
      clientsCount,
      confirmedCount,
      organizationId,
      studio,
      timetableComment,
    } = exercise

    const direction = mapDictionaryItemToEntityItem(exercise.direction)
    const type = exercise.type
    const studiosRoom = mapColoredDictionaryItemToEntityItem(exercise.room)
    const trainers = mapDictionaryItemsListToEntityItemsList(exercise.trainers)
    const totalExercises = isDefAndNotEmpty(exerciseLinked) ? exerciseLinked.length : undefined
    const currentExerciseIndex = exerciseLinked?.findIndex(el => el.id === exercise.id)
    /**
     * @todo Trainers check should be added after API is implemented
     */
    if (isDef(direction) && isDef(type) && isDef(trainers) && isDef(studiosRoom))
      return {
        id: exercise.id,
        timeFrom,
        timeTo,
        direction,
        type,
        trainers,
        studiosRoom,
        comment,
        commentModifiedAt,
        commentModifiedBy,
        createdAt,
        createdBy,
        maxClientsCount,
        clientsCount,
        confirmedCount,
        organizationId,
        studio,
        timetableComment,
        totalExercises,
        currentExerciseIndex,
      }
  }

  return null
}

export function mapExerciseToSchedulePagePeekExerciseBookings(
  exerciseBookings: Nullable<ExercisesApi.ExerciseBooking[]>
): ExercisesPeekBooking[] | undefined {
  if (isDefAndNotEmpty(exerciseBookings)) {
    return exerciseBookings.reduce<ExercisesPeekBooking[]>((acc, exerciseBooking) => {
      const {
        id,
        client,
        visitConfirmed,
        spot,
        isTrial,
        isCancelled,
        comments,
        clientComment,
        linkedProducts,
        receipts,
        createdAt,
      } = exerciseBooking
      const { id: clientId, firstName, lastName, photo, phone, category } = client

      const paymentType = mapPaymentTypeToClient(exerciseBooking.paymentType)

      if (isDef(paymentType)) {
        acc.push({
          id,
          clientId,
          paymentType,
          isCancelled,
          isTrial,
          visitConfirmed,
          placement: spot,
          phone: `+${phone}`,
          photo,
          firstName,
          lastName,
          comments: comments.map(comment => ({
            ...comment,
            employeeName: `${comment.employee.lastName ?? ''} ${comment.employee.firstName?.[0] ?? ''}`,
            photo: comment.employee.phone,
          })),
          clientComment,
          linkedProducts,
          categoryClient: category,
          receipts,
          createdAt,
        })
      }

      return acc.sort((a, b) => (dayjs(b.createdAt).isBefore(dayjs(a.createdAt)) ? -1 : 1))
    }, [])
  }
}

export function mapExercisesSpotsToOptions(
  exercisesSpots: Nullable<ExercisesApi.ExerciseSpot[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercisesSpots)) {
    return exercisesSpots.map(
      (exercisesSpot: ExercisesApi.ExerciseSpot): DefaultOptionType => ({
        value: exercisesSpot,
        label: exercisesSpot,
      })
    )
  }
}

export function genExerciseBookingCreateDTO(
  values: ExercisesBookingFormValues
): Nullable<ExercisesApi.ExerciseBookingCreateDTO> {
  const { phone, place } = values
  const paymentType = mapPaymentTypeToApi(
    values.paymentType === 'CREATE_TRANSACTION' ? PaymentType.ON_PLACE : values.paymentType
  )

  if (isDef(paymentType)) {
    return {
      phone: formatFormValueToPhoneNumber(phone).replace(/\D/g, ''),
      paymentType,
      spot: place,
      studioId: values?.studioId,
    }
  }

  return null
}

export function genExerciseBookFromWaitingCreateDTO(
  values: ExercisesBookFromWaitingFormValues,
  phone: string
): Nullable<ExercisesApi.ExerciseBookingCreateDTO> {
  const { place } = values
  const paymentType = mapPaymentTypeToApi(values.paymentType)

  if (isDef(paymentType)) {
    return {
      phone,
      paymentType,
      spot: place,
    }
  }

  return null
}

export function genExercisesComments(
  exercisesComments: Nullable<ExercisesApi.ExerciseBookingComment[]>
): ExercisesBookingComment[] | undefined {
  if (isDefAndNotEmpty(exercisesComments)) {
    return exercisesComments.reduce<ExercisesBookingComment[]>((acc, exercisesComment) => {
      const { id, createDate, employee, comment } = exercisesComment
      const { firstName, lastName, photo } = employee
      const employeeName = `${firstName} ${lastName}`

      acc.push({
        id,
        createDate: formatDate(createDate, 'DD MMMM в HH:mm'),
        comment,
        photo,
        employeeName,
      })

      return acc
    }, [])
  }
}

export function mapExercisesToTrainersOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { trainers } = exercise
      if (isDefAndNotEmpty(trainers)) {
        trainers.forEach(trainer => {
          if (!acc.find(option => option.value === trainer.id)) {
            const { id, name } = trainer
            acc.push({
              value: id,
              label: name,
            })
          }
        })
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesToDirectionsOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { direction } = exercise
      if (isDef(direction)) {
        if (!acc.find(option => option.value === direction.id)) {
          const { id, name } = direction
          acc.push({
            value: id,
            label: name,
          })
        }
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesToRecordsOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { type } = exercise
      if (isDef(type)) {
        if (!acc.find(option => option.value === type.id)) {
          const { id, name } = type
          acc.push({
            value: id,
            label: name,
          })
        }
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesToPaymentTypesOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { masterServiceBooking } = exercise
      if (isDef(masterServiceBooking)) {
        if (!acc.find(option => option.value === masterServiceBooking.paymentType)) {
          const { paymentType } = masterServiceBooking
          acc.push({
            value: paymentType,
            label: formatPaymentType(paymentType),
          })
        }
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function genExerciseUpdateDTO(
  values: ExercisesApi.Exercise,
  roomId: string,
  timeFrom: string,
  timeTo: string
): ExercisesApi.ExerciseUpdateDTO {
  const { direction, maxClientsCount, trainers, comment, type, studio } = values
  return {
    studioId: studio.id,
    roomId: roomId,
    timeFrom,
    timeTo,
    direction: Number(direction?.id),
    maxClientsCount,
    trainers: isDefAndNotEmpty(trainers) ? trainers.map(trainer => trainer.id) : [],
    comment,
    type: type.id,
  }
}

export function mapConflictsFormValuesToExerciseCreateDTO(
  timetableId: string,
  values: ScheduleModalConflictsExerciseValues,
  timeFrom: string,
  directionId: number,
  typeId: number,
  timeslots: ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO,
  studioOffset?: number
): ExercisesApi.ExercisesCreateDTO | undefined {
  const getMaxClientsCount = (): number | null => {
    dayjs.locale('en')

    const date = dayjs.utc(timeFrom)
    const dayOfWeek = date.format('dddd').toUpperCase()

    const slots = timeslots[dayOfWeek as Days]

    if (!slots) {
      return null
    }

    const timeFromFormatted = date.add(studioOffset || 0, 'hour').format('HH:mm')

    for (const slot of slots) {
      if (slot.timeFrom === timeFromFormatted) {
        return slot.maxClientsCount
      }
    }

    return null
  }

  const maxClientsCount = getMaxClientsCount()
  const trainers: string[] | undefined = isDefAndNotEmpty(values.trainers)
    ? values.trainers.map(trainer => trainer.id)
    : undefined

  if (maxClientsCount) {
    return {
      ...values,
      direction: directionId,
      type: typeId,
      maxClientsCount,
      timetableId,
      trainers,
    }
  }
}

export function mapConflictsFormValuesToExerciseUpdateDTO(
  values: ScheduleModalConflictsExerciseValues,
  studioId: string,
  directionId?: number
): ExercisesApi.ExerciseUpdateDTO | undefined {
  if (directionId) {
    const trainers: string[] | undefined = isDefAndNotEmpty(values.trainers)
      ? values.trainers.map(trainer => trainer.id)
      : undefined

    return {
      ...values,
      direction: directionId,
      studioId,
      trainers,
    }
  }
}

export function mapExercisesFormValuesToBreaksCreateDTO(
  exercisesFormValuesDTO: Nullable<ExercisesFormValuesDTO>,
  date: string,
  roomName: string
): Nullable<BreaksApi.BreakCreateDTO[]> {
  if (isDef(exercisesFormValuesDTO) && isDefAndNotEmpty(exercisesFormValuesDTO.rooms)) {
    const { time, rooms } = exercisesFormValuesDTO
    const timeFrom = date + time.start.substring(date.length)
    const timeTo = date + time.end.substring(date.length)

    return rooms.map(room => ({
      title: '',
      timeFrom,
      timeTo,
      roomId: room,
      comment: `Закрыто в рамках записи в пространстве ${roomName}`,
    }))
  }
  return null
}

export function genExercisesPeekFormValues(
  exercise: Nullable<ExercisesApi.Exercise>,
  exercisesType: Nullable<ExercisesTypesApi.ExerciseType>,
  studioOffset: NNumber
): Nullable<ExercisesPeekFormValues> {
  if (isDef(exercise) && isDef(studioOffset)) {
    const { direction, type, trainers, timeFrom, timeTo, maxClientsCount, studio, room } = exercise

    const date = dayjs(timeFrom).utcOffset(studioOffset, false)
    const masterService = exercisesType?.masterServices?.find(masterService =>
      masterService.subServices.find(subService => subService.direction.id === direction?.id)
    )?.id

    if (isDef(direction) && isDef(trainers) && isDef(type)) {
      return {
        type: type.id,
        masterService,
        direction: direction.id,
        trainers: trainers.map(trainer => trainer.id),
        studioId: studio.id,
        roomId: room.id,
        maxClientsCount: maxClientsCount,
        date: date,
        time: [
          dayjs(timeFrom).utcOffset(studioOffset, false).format(genDefaultTimeFormat()),
          dayjs(timeTo).utcOffset(studioOffset, false).format(genDefaultTimeFormat()),
        ],
      }
    }
  }

  return null
}

export function genExercisesPeekUpdateDTO(
  values: Nullable<ExercisesPeekFormValues>,
  comment: NString,
  studioOffset: number
): Nullable<ExercisesApi.ExerciseUpdateDTO> {
  if (isDef(values)) {
    const { date, time, studioId, roomId, direction, trainers, maxClientsCount, type } = values
    const [timeFrom, timeTo] = time

    if (isDef(timeFrom) && isDef(timeTo)) {
      const timeFromDTO = dayjs(timeFrom, genDefaultTimeFormat()).utcOffset(studioOffset, true).format()

      const timeToDTO =
        isDef(timeTo) && timeTo !== '00:00'
          ? dayjs(timeTo, genDefaultTimeFormat()).utcOffset(studioOffset, true).format()
          : dayjs(timeFrom, genDefaultTimeFormat()).add(1, 'hours').utcOffset(studioOffset, true).format()

      const dateDTO = formatDayjsToDate(date)

      const dateFrom = dateDTO + timeFromDTO.substring(dateDTO.length)
      const dateTo = dateDTO + timeToDTO.substring(dateDTO.length)

      if (dayjs(dateFrom).isValid() && dayjs(dateTo).isValid()) {
        return {
          timeFrom: dateFrom,
          timeTo: dateTo,
          direction,
          studioId,
          roomId,
          maxClientsCount,
          trainers,
          comment,
          type,
        }
      }
    }
  }

  return null
}
