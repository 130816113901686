import { Flex, Typography } from 'antd'
import { useEffect } from 'react'

import './exercises-peek-history.styles.less'
import { ExercisesPeekHistoryProps } from './exercises-peek-history.types'
import { PageLoader } from '../../../../page/page-loader/page-loader.component'
import { PageEmpty } from '../../../../page/page-empty/page-empty.component'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { HistoryList } from '../../../../history-list/history-list.component'
import { HistoryListType } from '../../../../history-list/history-list.types'

export const ExercisesPeekHistory: React.FC<ExercisesPeekHistoryProps> = props => {
  const { isLoading, exerciseHistory, fetchHistory, ...rest } = props

  const renderContent = () => {
    if (isLoading) return <PageLoader />
    if (!isDefAndNotEmpty(exerciseHistory)) return <PageEmpty description="История не найдена" />
    return <HistoryList history={exerciseHistory} type={HistoryListType.EXERCISE} {...rest} />
  }

  useEffect(() => {
    fetchHistory()
  }, [fetchHistory])

  return (
    <Flex vertical gap={16}>
      <Typography.Title level={3} className="exercises-peek-history__title">
        История действий
      </Typography.Title>
      {renderContent()}
    </Flex>
  )
}
