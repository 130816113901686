import { all, call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'

import { api } from '../../../../api/api'
import { genExerciseBookingCreateDTO } from '../../../../mapping/exercises.mapping'
import { isDef } from '../../../../types/lang.types'
import { modalActions } from '../../../common/modal/modal.slice'
import { schedulePageModalBookingActions } from './schedule-page-modal-booking.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { formatFormValueToPhoneNumber } from '../../../../format/phone.format'
import { reFetchBookings, reFetchExercise } from '../schedule-page-peek-exercise/schedule-page-peek-exercise.sagas'
import { peekActions } from '../../../common/peek/peek.slice'

export function* fetchExercisesSpots(action: ReturnType<typeof schedulePageModalBookingActions.fetchExercisesSpots>) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesService.fetchSpots>> = yield callApi(
      api.exercisesService.fetchSpots,
      action.payload
    )

    yield put(schedulePageModalBookingActions.fetchExercisesSpotsSuccess(response.data))
  } catch (e) {
    yield put(schedulePageModalBookingActions.fetchExercisesSpotsError(new Error()))
  }
}

export function* createBooking(action: ReturnType<typeof schedulePageModalBookingActions.createBooking>) {
  try {
    const { exerciseId, exercisesBookingFormValues, clientDTO } = action.payload
    const { clientId, paymentType, phone } = exercisesBookingFormValues

    const exerciseBookingCreateDTO = genExerciseBookingCreateDTO(exercisesBookingFormValues)

    if (isDef(exerciseBookingCreateDTO)) {
      if (clientDTO) {
        if (!clientId) {
          yield callApi(api.clientsService.create, clientDTO)
        } else {
          yield callApi(api.clientsService.update, clientId, clientDTO)
        }
      }

      yield callApi(api.exercisesService.createBooking, exerciseId, exerciseBookingCreateDTO)
      yield put(schedulePageModalBookingActions.createBookingSuccess())

      yield all([call(reFetchBookings, exerciseId), call(reFetchExercise, exerciseId)])

      yield put(modalActions.closeLast())

      if (paymentType === 'CREATE_TRANSACTION') {
        const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)
        yield put(peekActions.closeLast())
        yield put(
          push({
            pathname: `/transactions/${studioId}/create`,
            state: {
              phone: formatFormValueToPhoneNumber(phone),
              exerciseId,
              clientId,
            },
          })
        )
      }
    } else {
      yield put(schedulePageModalBookingActions.createBookingError(new Error()))
    }
  } catch (e) {
    yield put(schedulePageModalBookingActions.createBookingError(new Error()))
  }
}

export function* schedulePageModalBookingSagas() {
  yield takeLatest(schedulePageModalBookingActions.fetchExercisesSpots, fetchExercisesSpots)
  yield takeLatest(schedulePageModalBookingActions.createBooking, createBooking)
}
