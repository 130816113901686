import { combineReducers } from '@reduxjs/toolkit'

import { schedulePageListReducer } from './schedule-page-list/schedule-page-list.slice'
import { schedulePageModalCreateReducer } from './schedule-page-modal-create/schedule-page-modal-create.slice'
import { schedulePagePeekExerciseReducer } from './schedule-page-peek-exercise/schedule-page-peek-exercise.slice'
import { schedulePageModalBookingReducer } from './schedule-page-modal-booking/schedule-page-modal-booking.slice'
import { schedulePageModalCommentsReducer } from './schedule-page-modal-comments/schedule-page-modal-comments.slice'
import { schedulePageModalWaitingReducer } from './schedule-page-modal-waiting/schedule-page-modal-waiting.slice'
import { schedulePageModalBookFromWaitingReducer } from './schedule-page-modal-book-from-waiting/schedule-page-modal-book-from-waiting.slice'

export const schedulePageReducer = combineReducers({
  schedulePageList: schedulePageListReducer,
  schedulePageModalCreate: schedulePageModalCreateReducer,
  schedulePagePeekExercise: schedulePagePeekExerciseReducer,
  schedulePageModalBooking: schedulePageModalBookingReducer,
  schedulePageModalComments: schedulePageModalCommentsReducer,
  schedulePageModalWaiting: schedulePageModalWaitingReducer,
  schedulePageModalBookFromWaiting: schedulePageModalBookFromWaitingReducer,
})
