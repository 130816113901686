import { ScheduleAboutPeek } from '../../../components/schedule/schedule-about-peek/schedule-about-peek.component'
import { useScheduleManagementPagePeekAbout } from './schedule-management-page-peek-about.hook'
import { ScheduleManagementPagePeekAboutProps } from './schedule-management-page-peek-about.types'

export const ScheduleManagementPagePeekAbout: React.FC<ScheduleManagementPagePeekAboutProps> = props => {
  const { scheduleId, studioId } = props

  const {
    isTimetableLoading,
    isExercisesLoading,
    isCommentLoading,
    isCapacityLoading,
    schedule,
    exercises,
    exercisesFilter,
    totalElements,
    pagination,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    weekdaysOptions,
    timeFromOptions,
    timeToOptions,
    clientsOptions,
    schedulePath,
    timetableUpdatePermission,
    clientBookingReadPermission,
    clientBookingDeletePermission,
    onChangePageHandler,
    onEditCommentHandler,
    onEditCapacityHandler,
    onClientHandler,
    onExerciseHandler,
    onEditExercisesHandler,
    onAddExercisesHandler,
    onRemoveExerciseHandler,
    onCancelPeekHandler,
    onSelectFilterHandler,
    onResetFilterHandler,
  } = useScheduleManagementPagePeekAbout(scheduleId, studioId)

  return (
    <ScheduleAboutPeek
      isTimetableLoading={isTimetableLoading}
      isExercisesLoading={isExercisesLoading}
      isCommentLoading={isCommentLoading}
      isCapacityLoading={isCapacityLoading}
      studioId={studioId}
      schedule={schedule}
      exercises={exercises}
      exercisesFilter={exercisesFilter}
      totalExercises={totalElements}
      pagination={pagination}
      trainersOptions={trainersOptions}
      studiosOptions={studiosOptions}
      roomsOptions={roomsOptions}
      weekdaysOptions={weekdaysOptions}
      timeFromOptions={timeFromOptions}
      timeToOptions={timeToOptions}
      clientsOptions={clientsOptions}
      schedulePath={schedulePath}
      timetableUpdatePermission={timetableUpdatePermission}
      clientBookingReadPermission={clientBookingReadPermission}
      clientBookingDeletePermission={clientBookingDeletePermission}
      onChangePage={onChangePageHandler}
      onEditComment={onEditCommentHandler}
      onEditCapacity={onEditCapacityHandler}
      onClient={onClientHandler}
      onExercise={onExerciseHandler}
      onEditExercises={onEditExercisesHandler}
      onAddExercises={onAddExercisesHandler}
      onRemoveExercise={onRemoveExerciseHandler}
      onCancelPeek={onCancelPeekHandler}
      onSelectFilter={onSelectFilterHandler}
      onResetFilter={onResetFilterHandler}
    />
  )
}
