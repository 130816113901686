import { all, call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genExerciseBookFromWaitingCreateDTO } from '../../../../mapping/exercises.mapping'
import { isDef } from '../../../../types/lang.types'
import { modalActions } from '../../../common/modal/modal.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { schedulePageModalBookFromWaitingActions } from './schedule-page-modal-book-from-waiting.slice'
import {
  reFetchBookings,
  reFetchClientsInWaitingList,
  reFetchExercise,
} from '../schedule-page-peek-exercise/schedule-page-peek-exercise.sagas'

export function* fetchExercisesSpots(
  action: ReturnType<typeof schedulePageModalBookFromWaitingActions.fetchExercisesSpots>
) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesService.fetchSpots>> = yield callApi(
      api.exercisesService.fetchSpots,
      action.payload
    )

    yield put(schedulePageModalBookFromWaitingActions.fetchExercisesSpotsSuccess(response.data))
  } catch (e) {
    yield put(schedulePageModalBookFromWaitingActions.fetchExercisesSpotsError(new Error()))
  }
}

export function* createBooking(action: ReturnType<typeof schedulePageModalBookFromWaitingActions.createBooking>) {
  try {
    const { exerciseId, exercisesBookingFormValues, phone } = action.payload

    const exerciseBookingCreateDTO = genExerciseBookFromWaitingCreateDTO(exercisesBookingFormValues, phone)

    if (isDef(exerciseBookingCreateDTO)) {
      yield callApi(api.exercisesService.createBooking, exerciseId, exerciseBookingCreateDTO)
      yield put(schedulePageModalBookFromWaitingActions.createBookingSuccess())

      yield all([
        call(reFetchExercise, exerciseId),
        call(reFetchBookings, exerciseId),
        call(reFetchClientsInWaitingList, exerciseId),
      ])

      yield put(modalActions.closeLast())
    } else {
      yield put(schedulePageModalBookFromWaitingActions.createBookingError(new Error()))
    }
  } catch (e) {
    yield put(schedulePageModalBookFromWaitingActions.createBookingError(new Error()))
  }
}

export function* schedulePageModalBookFromWaitingSagas() {
  yield takeLatest(schedulePageModalBookFromWaitingActions.fetchExercisesSpots, fetchExercisesSpots)
  yield takeLatest(schedulePageModalBookFromWaitingActions.createBooking, createBooking)
}
