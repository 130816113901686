import { Button, Flex } from 'antd'
import clsx from 'clsx'

import { useSchedulePageClosingModeControls } from './schedule-page-closing-mode-controls.hook'
import './schedule-page-closing-mode-controls.styles.less'

export const SchedulePageClosingModeControls: React.FC = () => {
  const { theme, slotClosingMode, handleCloseSlotClosingMode, handleSaveBreaks } = useSchedulePageClosingModeControls()

  if (slotClosingMode) {
    return (
      <div className={clsx(`schedule-page-closing-mode-controls schedule-page-closing-mode-controls--${theme}`)}>
        <Flex gap="large">
          <Button
            className="schedule-page-closing-mode-controls__button-save"
            type="dashed"
            size="large"
            onClick={handleSaveBreaks}
          >
            Завершить режим быстрого закрытия слотов
          </Button>
          <Button
            className="schedule-page-closing-mode-controls__button-cancel"
            type="dashed"
            size="large"
            onClick={handleCloseSlotClosingMode}
          >
            Отмена
          </Button>
        </Flex>
      </div>
    )
  }
}
