import { Modal } from 'antd'
import * as React from 'react'

import { ExercisesBookingModalProps } from './exercises-booking-modal.types'
import { ExercisesBookingModalForm } from './exercises-booking-modal-form/exercises-booking-modal-form.component'

export const ExercisesBookingModal: React.FC<ExercisesBookingModalProps> = props => {
  const { form, placesOptions, loading, exerciseId } = props
  const { onCancel, onSave } = props

  return (
    <Modal title="Какого гостя записать?" open={true} onCancel={onCancel} footer={null}>
      <ExercisesBookingModalForm
        form={form}
        loading={loading}
        placesOptions={placesOptions}
        exerciseId={exerciseId}
        onSave={onSave}
      />
    </Modal>
  )
}
