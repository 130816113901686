import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { genSchedulePageListSlotClosingMode } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { schedulePageListActions } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import { getTheme } from '../../../store/common/layout/layout.selectors'

export function useSchedulePageClosingModeControls() {
  const dispatch = useDispatch()

  const theme = useSelector(getTheme)
  const slotClosingMode = useSelector(genSchedulePageListSlotClosingMode)

  const handleCloseSlotClosingMode = useCallback(() => {
    dispatch(schedulePageListActions.updateSlotClosingMode())
    dispatch(schedulePageListActions.updateBreaksInClosingMode([]))
  }, [dispatch])

  const handleSaveBreaks = useCallback(() => {
    dispatch(schedulePageListActions.updateBreaks())
  }, [dispatch])

  return {
    theme,
    slotClosingMode,
    handleCloseSlotClosingMode,
    handleSaveBreaks,
  }
}
