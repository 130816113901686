import React from 'react'
import { Select, SelectProps } from 'antd'

import { useSelectManagers } from './select-managers.hook'

export interface SelectManagersProps extends SelectProps {
  onChange?: (managerId: string) => void
  className?: string
}

export const SelectManagers: React.FC<SelectManagersProps> = ({ onChange, className, ...restProps }) => {
  const { managers, isLoading } = useSelectManagers()

  return (
    <Select
      loading={isLoading}
      disabled={isLoading}
      options={managers}
      className={className}
      onChange={onChange}
      {...restProps}
    />
  )
}
