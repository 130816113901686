import { all, spawn } from 'redux-saga/effects'

import { promotionsPageCodesEditSagas } from './promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.sagas'
import { promotionsPageSalesCreateSagas } from './promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.sagas'
import { promotionsPageSalesEditSagas } from './promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.sagas'
import { promotionsPageCodesCreateSagas } from './promotions-page-codes/promotions-page-codes-create/promotions-page-codes-create.sagas'

export function* promotionsPageSagas() {
  yield all(
    [
      promotionsPageCodesEditSagas,
      promotionsPageCodesCreateSagas,
      promotionsPageSalesCreateSagas,
      promotionsPageSalesEditSagas,
    ].map(saga => spawn(saga))
  )
}
