import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { genScheduleLongtermEditPagePath, genScheduleLongtermPagePath } from '../../../format/path.format'
import { useStudio } from '../../../hooks/use-studios.hook'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { peekActions } from '../../../store/common/peek/peek.slice'
import {
  getClientBookingDeletePermission,
  getClientBookingReadPermission,
  getTimetableUpdatePermission,
} from '../../../store/common/permissions/permissions.selectors'
import {
  genScheduleLongtermPagePeekAboutIsTimetableLoading,
  genScheduleLongtermPagePeekAboutSchedule,
  genScheduleLongtermPagePeekAboutExercises,
  genScheduleLongtermPagePeekAboutExercisesTotalElement,
  genScheduleLongtermPagePeekAboutExercisesFilter,
  genScheduleLongtermPagePeekAboutIsExercisesLoading,
  genScheduleLongtermPagePeekAboutStudiosOptions,
  genScheduleLongtermPagePeekAboutFilterTrainersOptions,
  genScheduleLongtermPagePeekAboutFilterStudiosRoomsOptions,
  genScheduleLongtermPagePeekAboutIsCommentLoading,
  genScheduleLongtermPagePeekAboutWeekdaysOptions,
  genScheduleLongtermPagePeekAboutWeekdaysTimeFromOptions,
  genScheduleLongtermPagePeekAboutWeekdaysTimeToOptions,
  genScheduleLongtermPagePeekAboutIsCapacityLoading,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPagePeekAboutActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.slice'
import { AppModal } from '../../../types/modal.types'
import { genListPaginationConfig } from '../../../utils/pagination.utils'
import { useScheduleLongtermPageParams } from '../schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import { AppPeek } from '../../../types/peek.types'

export function useScheduleLongtermPagePeekAbout(scheduleId: string, studioId: string) {
  const { studioOffset } = useStudio()
  const dispatch = useDispatch()

  const params = useScheduleLongtermPageParams()

  const { push } = useHistory()

  const schedule = useSelector(genScheduleLongtermPagePeekAboutSchedule)
  const exercises = useSelector(genScheduleLongtermPagePeekAboutExercises)
  const exercisesFilter = useSelector(genScheduleLongtermPagePeekAboutExercisesFilter)
  const totalElements = useSelector(genScheduleLongtermPagePeekAboutExercisesTotalElement)
  const trainersOptions = useSelector(genScheduleLongtermPagePeekAboutFilterTrainersOptions)
  const studiosOptions = useSelector(genScheduleLongtermPagePeekAboutStudiosOptions)
  const roomsOptions = useSelector(genScheduleLongtermPagePeekAboutFilterStudiosRoomsOptions)
  const weekdaysOptions = useSelector(genScheduleLongtermPagePeekAboutWeekdaysOptions)
  const timeFromOptions = useSelector(genScheduleLongtermPagePeekAboutWeekdaysTimeFromOptions)
  const timeToOptions = useSelector(genScheduleLongtermPagePeekAboutWeekdaysTimeToOptions)
  const isTimetableLoading = useSelector(genScheduleLongtermPagePeekAboutIsTimetableLoading)
  const isExercisesLoading = useSelector(genScheduleLongtermPagePeekAboutIsExercisesLoading)
  const isCommentLoading = useSelector(genScheduleLongtermPagePeekAboutIsCommentLoading)
  const isCapacityLoading = useSelector(genScheduleLongtermPagePeekAboutIsCapacityLoading)

  const timetableUpdatePermission = useSelector(getTimetableUpdatePermission)
  const clientBookingReadPermission = useSelector(getClientBookingReadPermission)
  const clientBookingDeletePermission = useSelector(getClientBookingDeletePermission)

  const [page, setPage] = useState(1)
  const [size, setSize] = useState(25)

  const pagination = useMemo(() => genListPaginationConfig(page, size, totalElements), [page, size, totalElements])
  const schedulePath = useMemo(() => {
    return window.location.host + genScheduleLongtermEditPagePath(studioId, scheduleId)
  }, [studioId, scheduleId])

  const onEditCommentHandler = useCallback(
    (comment: string) => {
      dispatch(scheduleLongtermPagePeekAboutActions.updateComment({ scheduleId, comment }))
    },
    [dispatch, scheduleId]
  )

  const onEditCapacityHandler = useCallback(
    (capacity: number) => {
      dispatch(scheduleLongtermPagePeekAboutActions.updateCapacity({ studioId, scheduleId, capacity }))
    },
    [dispatch, scheduleId, studioId]
  )

  const onClientHandler = (clientId: string) => {
    dispatch(peekActions.closeLast())
    push({
      pathname: `/clients/${clientId}`,
    })
  }

  const onChangePageHandler = useCallback(
    (page: number, pageSize: number): void => {
      setPage(page)
      setSize(pageSize)
      dispatch(
        scheduleLongtermPagePeekAboutActions.fetchExercises({
          timetableId: scheduleId,
          params: {
            page: page - 1,
            size: pageSize,
            sort: 'timeFrom,asc',
          },
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onExerciseHandler = useCallback(
    (studioId: string, scheduleId: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_PAGE_PEEK_EXERCISE,
          props: { studioId, exerciseId: scheduleId },
        })
      )
    },
    [dispatch]
  )

  const onEditExercisesHandler = useCallback((): void => {
    const phone = schedule?.client?.phone

    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_EDIT,
        props: { studioId, scheduleId, phone },
      })
    )
  }, [dispatch, schedule?.client?.phone, scheduleId, studioId])

  const onAddExercisesHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_ADD,
        props: { scheduleId, studioId, studioOffset },
      })
    )
  }, [dispatch, scheduleId, studioId, studioOffset])

  const onRemoveExerciseHandler = useCallback(
    (exerciseId: string): void => {
      const onSubmit = () => {
        dispatch(scheduleLongtermPagePeekAboutActions.removeExercise({ exerciseId, scheduleId }))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена занятия',
            text: 'Вы уверены, что хотите отменить занятие?',
            onSubmit,
          },
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onCancelPeekHandler = useCallback((): void => {
    push(genScheduleLongtermPagePath(studioId, params))
  }, [params, push, studioId])

  const onSelectFilterHandler = useCallback(
    (name: string, value?: string | string[]) => {
      dispatch(scheduleLongtermPagePeekAboutActions.setExercisesFilter({ name, value }))
    },
    [dispatch]
  )

  const onResetFilterHandler = useCallback(() => {
    dispatch(scheduleLongtermPagePeekAboutActions.resetExercisesFilter())
  }, [dispatch])

  useEffect(() => {
    dispatch(scheduleLongtermPagePeekAboutActions.fetchPeekData(scheduleId))
  }, [dispatch, scheduleId])

  useEffect(() => {
    if (Object.keys(exercisesFilter).length !== 0) {
      dispatch(
        scheduleLongtermPagePeekAboutActions.fetchExercises({
          timetableId: scheduleId,
          params: {
            page: page - 1,
            size,
            sort: 'timeFrom,asc',
          },
        })
      )
    }
  }, [dispatch, exercisesFilter, page, size])

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPagePeekAboutActions.reset())
    }
  }, [dispatch])

  return {
    isTimetableLoading,
    isExercisesLoading,
    isCommentLoading,
    isCapacityLoading,
    schedule,
    exercises,
    exercisesFilter,
    totalElements,
    pagination,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    weekdaysOptions,
    timeFromOptions,
    timeToOptions,
    schedulePath,
    timetableUpdatePermission,
    clientBookingReadPermission,
    clientBookingDeletePermission,
    onChangePageHandler,
    onEditCommentHandler,
    onEditCapacityHandler,
    onClientHandler,
    onExerciseHandler,
    onEditExercisesHandler,
    onAddExercisesHandler,
    onRemoveExerciseHandler,
    onCancelPeekHandler,
    onSelectFilterHandler,
    onResetFilterHandler,
  }
}
