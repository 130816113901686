import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { schedulePageListActions } from '../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import {
  genSchedulePageListIsLoaded,
  genSchedulePageListIsLoading,
  genSchedulePageListTimetable,
} from '../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { useStudio } from '../../hooks/use-studios.hook'
import { useSchedulePageParams } from './schedule-page-hooks/schedule-page-params.hook'
import { getStudiosWithViewPermissions } from '../../store/common/permissions/permissions.selectors'
import { peekActions } from '../../store/common/peek/peek.slice'
import { AppPeek } from '../../types/peek.types'

export function useSchedulePage() {
  const params = useSchedulePageParams()
  const { studioId: selectedStudioId } = useStudio()
  const { date, studioId, exerciseId, ...pageParams } = params
  const safeDate = date ? date : dayjs().format('YYYY-MM-DD')

  const dispatch = useDispatch()

  const isLoading = useSelector(genSchedulePageListIsLoading)
  const isLoaded = useSelector(genSchedulePageListIsLoaded)
  const currentTimetable = useSelector(genSchedulePageListTimetable)
  const allowedStudios = useSelector(getStudiosWithViewPermissions)

  useEffect(() => {
    const dateParams = { dateFrom: safeDate, dateTo: safeDate }
    if (currentTimetable === 'weekly') {
      const targetDate = dayjs(safeDate)
      dateParams.dateFrom = targetDate.startOf('isoWeek').format('YYYY-MM-DD')
      dateParams.dateTo = targetDate.endOf('isoWeek').format('YYYY-MM-DD')
    }
    const isStudioAllowed = allowedStudios?.includes(studioId)
    if (isStudioAllowed) {
      dispatch(schedulePageListActions.fetchPageData({ studioId, ...pageParams, ...dateParams }))
    }
  }, [params, currentTimetable, safeDate, allowedStudios])

  useEffect(() => {
    if (exerciseId) {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_PAGE_PEEK_EXERCISE,
          props: { exerciseId, studioId },
        })
      )
    }
  }, [dispatch, exerciseId, studioId])

  useEffect(() => {
    return () => {
      dispatch(schedulePageListActions.reset())
    }
  }, [dispatch])

  return {
    studioId,
    selectedStudioId,
    allowedStudios,
    isLoading,
    isLoaded,
  }
}
