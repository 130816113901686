import { Alert, Button, Flex, Input, Typography } from 'antd'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { EntityItem, isDefAndNotEmpty, NString } from '../../../../../types/lang.types'
import { useStudio } from '../../../../../hooks/use-studios.hook'
import { formatDate, formatTimeDifference, formatToUTCHours } from '../../../../../format/date.format'
import { useExercisesPeekHeader } from './exercises-peek-header.hook'
import './exercises-peek-header.styles.less'
import { peekActions } from '../../../../../store/common/peek/peek.slice'

interface Props {
  timeFrom: string
  timeTo: string
  trainers: EntityItem<string>[]
  direction: string
  room: string
  timetableComment: NString
  exerciseComment: NString
  onEditCommentHandler: (comment: string) => void
}

export const ExercisesPeekHeader: React.FC<Props> = props => {
  const { timeFrom, timeTo, trainers, direction, room, timetableComment, exerciseComment } = props
  const { onEditCommentHandler } = props

  const {
    comment,
    isEditing,
    handleEditCommentClick,
    handleAddCommentClick,
    handleInputCommentChange,
    handleSaveCommentClick,
  } = useExercisesPeekHeader({ exerciseComment, onEditCommentHandler })

  const { studioOffset } = useStudio()
  const dispatch = useDispatch()
  const history = useHistory()

  const formattedDate = formatDate(timeFrom, 'D MMMM')
  const formattedTimeFrom = formatToUTCHours(timeFrom, 'HH:mm', studioOffset)
  const formattedTimeTo = formatToUTCHours(timeTo, 'HH:mm', studioOffset)

  const durationInHours = formatTimeDifference(timeFrom, timeTo)

  const onTrainerHandler = (trainerId: string) => {
    dispatch(peekActions.closeLast())
    history.push({
      pathname: `/employees/${trainerId}`,
    })
  }

  return (
    <Flex vertical gap={5}>
      {timetableComment && <Alert type="info" showIcon message={timetableComment} />}
      {!comment && !isEditing ? (
        <Button htmlType="button" type="dashed" onClick={handleAddCommentClick} style={{ alignSelf: 'baseline' }}>
          Добавить комментарий к записи
        </Button>
      ) : (
        <Alert
          type="info"
          showIcon
          message={
            <Flex align="center" justify="space-between">
              {isEditing ? (
                <Input
                  value={comment}
                  onChange={handleInputCommentChange}
                  autoFocus
                  style={{ flex: 1, marginRight: 8 }}
                />
              ) : (
                <span>{comment}</span>
              )}
              {isEditing ? (
                <Button
                  type="text"
                  icon={<SaveOutlined style={{ color: '#1677FF' }} />}
                  onClick={handleSaveCommentClick}
                />
              ) : (
                <Button
                  type="text"
                  icon={<EditOutlined style={{ color: '#1677FF' }} />}
                  onClick={handleEditCommentClick}
                />
              )}
            </Flex>
          }
        />
      )}
      <Flex vertical>
        <Typography.Text type="secondary">
          {formattedDate}, с {formattedTimeFrom} до {formattedTimeTo}, {durationInHours}, пространство: {room}
        </Typography.Text>
        <Typography.Title className="exercises-peek-header__direction" level={3}>
          {direction}
        </Typography.Title>
        {isDefAndNotEmpty(trainers) && (
          <span className="exercises-peek-header__trainers">
            Исполнители{' '}
            {trainers.map((trainer, index) => (
              <Typography.Link key={trainer.id} onClick={() => onTrainerHandler(trainer.id)}>
                {trainer.title}
                {index < trainers.length - 1 && ', '}
              </Typography.Link>
            ))}
          </span>
        )}
      </Flex>
    </Flex>
  )
}
