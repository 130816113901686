import React from 'react'
import { Flex } from 'antd'

import style from './contracts-modal-container.module.scss'

interface ContractsModalContainerProps {
  children: React.ReactNode
}

export const ContractsModalContainer = ({ children }: ContractsModalContainerProps) => {
  return (
    <Flex vertical className={style.formContainer} gap={'small'}>
      {children}
    </Flex>
  )
}
