import { PaymentType } from '../../../../../types/payment.types'

export function getExercisesPeekPaymentTypeColor(paymentType: PaymentType): string {
  switch (paymentType) {
    case 'SUBSCRIPTION':
      return 'magenta'
    case 'ONE_TIME':
      return 'geekblue'
    case 'ON_PLACE':
      return 'orange'
    case 'RESERVED':
      return 'orange'
    case 'TRIAL':
      return 'green'
    default:
      return 'magenta'
  }
}
