import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { mapExerciseWaitingListToExercisesWaitingClientItems } from '../../../../mapping/clients.mapping'

const getWaitingList = (state: AppState) => state.schedulePage.schedulePageModalWaiting.waitingList

export const getSchedulePageModalWaitingIsLoading = (state: AppState) =>
  state.schedulePage.schedulePageModalWaiting.isLoading

export const getSchedulePageModalWaitingIsLoaded = (state: AppState) =>
  state.schedulePage.schedulePageModalWaiting.isLoaded

export const getSchedulePageModalWaitingList = createSelector(getWaitingList, waitingList =>
  mapExerciseWaitingListToExercisesWaitingClientItems(waitingList?.content)
)
