import { CloseCircleOutlined } from '@ant-design/icons'
import { Input, Space, Typography } from 'antd'
import { useState, useEffect } from 'react'

import { DESCTOP_WIDTH } from '../../../../../../constants/breakpoints'
import { formatSearchPhoneNumber } from '../../../../../../format/phone.format'
import { useDebouncedValue } from '../../../../../../hooks/use-debounced-value.hook'
import useScreenWidth from '../../../../../../hooks/use-screen-width.hook'

interface Props {
  onSearch: (value: string) => void
  onQrCode: () => void
}

export const ExercisesPeekClientListSearch = ({ onSearch, onQrCode }: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  const windowSize = useScreenWidth()
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300)

  useEffect(() => {
    onSearch(debouncedSearchTerm)
  }, [debouncedSearchTerm, onSearch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchTerm('')
  }

  const handleQrCode = () => {
    onQrCode()
  }

  return (
    <Input.Search
      size="large"
      placeholder="Поиск в группе"
      onChange={handleSearch}
      value={formatSearchPhoneNumber(searchTerm)}
      suffix={<Space>{searchTerm && <CloseCircleOutlined onClick={handleClearSearch} />}</Space>}
      addonBefore={
        (windowSize?.width ?? 0) < DESCTOP_WIDTH && <Typography.Text onClick={handleQrCode}>QR-сканер</Typography.Text>
      }
    />
  )
}
