import { put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { contractsInfoPageContractsEditActions } from './contracts-info-page-contracts-edit.slice'
import { callApi } from '../../../../../utils/sagas.utils'
import { contractsInfoPageContractsCreateActions } from '../contracts-info-page-contracts-create/contracts-info-page-contracts.slice'
import { getFileLink } from '../../../../common/files/files.selectors'
import { genCounterpartiesContractUpdateFormValues } from '../../../../../mapping/contracts-info-mapping'
import { api } from '../../../../../api/api'
import { modalActions } from '../../../../common/modal/modal.slice'

export function* updateContract(action: ReturnType<typeof contractsInfoPageContractsEditActions.updateContract>) {
  try {
    const link: string = yield select(getFileLink)
    const { id, counterpartyId, data } = action.payload

    const updateData = genCounterpartiesContractUpdateFormValues(data, link || data.link)

    yield callApi(api.contractsService.updateContract, id, updateData)

    yield notification.success({
      message: 'Договор успешно изменен',
    })
    yield put(contractsInfoPageContractsEditActions.updateContractSuccess())
    yield put(
      contractsInfoPageContractsCreateActions.fetchAllContract({
        counterpartyId,
        showExpired: true,
        showNotStarted: true,
        size: 300,
      })
    )
    yield put(modalActions.closeLast())
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsEditActions.updateContractError(new Error()))
  }
}

export function* fetchContractById(action: ReturnType<typeof contractsInfoPageContractsEditActions.fetchContractById>) {
  try {
    const { id } = action.payload

    const response: Awaited<ReturnType<typeof api.contractsService.fetchContractById>> = yield callApi(
      api.contractsService.fetchContractById,
      id
    )

    yield put(contractsInfoPageContractsEditActions.fetchContractByIdSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsEditActions.fetchContractByIdError(new Error()))
  }
}

export function* contractsInfoPageContractsEditSagas() {
  yield takeLatest(contractsInfoPageContractsEditActions.updateContract, updateContract)
  yield takeLatest(contractsInfoPageContractsEditActions.fetchContractById, fetchContractById)
}
