import { modalActions } from '@store/common/modal/modal.slice'
import { useWatch } from 'antd/es/form/Form'
import { FormInstance } from 'rc-field-form'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DefaultOptionType } from 'antd/es/select'

import { AppModal } from '../../../../types/modal.types'
import { api } from '../../../../api/api'
import { mapProductsToOptions } from '../../../../mapping/promotions.mapping'

export const useFranchiseFormPrintingReceipts = ({ form }: { form: FormInstance }) => {
  const dispatch = useDispatch()

  // online
  const onlineTillValue = useWatch('onlineTillEnabled', form)
  const onlineTillId = useWatch('onlineTill', form)
  const [onlineTillEnabledSwitchValue, setOnlineTillEnabledSwitchValue] = useState(false)
  const onChangeOnlineTillValueEnabledSwitchValue = () => {
    form.setFieldValue('onlineTillEnabled', !onlineTillValue)
    setOnlineTillEnabledSwitchValue(prev => !prev)
  }
  useEffect(() => {
    if (onlineTillValue && !onlineTillEnabledSwitchValue) {
      form.setFieldValue('onlineTillEnabled', true)
      setOnlineTillEnabledSwitchValue(prev => !prev)
    }
  }, [onlineTillValue, form])

  // offline
  const offlineTillValue = useWatch('offlineTillEnabled', form)
  const [offlineTillEnabledSwitchValue, setOfflineTillEnabledSwitchValue] = useState(false)
  const onChangeOfflineTillValueEnabledSwitchValue = () => {
    form.setFieldValue('offlineTillEnabled', !offlineTillValue)
    setOfflineTillEnabledSwitchValue(prev => !prev)
  }
  useEffect(() => {
    if (offlineTillValue && !offlineTillEnabledSwitchValue) {
      form.setFieldValue('offlineTillEnabled', true)
      setOfflineTillEnabledSwitchValue(prev => !prev)
    }
  }, [offlineTillValue, form])

  const paymentOptions = [
    {
      label: 'Наличные',
      value: 'CASH',
    },
    {
      label: 'Онлайн платежи',
      value: 'ONLINE',
    },
    {
      label: 'По карте',
      value: 'CARD',
    },
  ]
  const openModalCreateTill = () => {
    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_PAGE_TILL_FORM,
      })
    )
  }

  const openModalEditTill = () => {
    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_PAGE_TILL_FORM,
        props: {
          idEditedTill: onlineTillId,
        },
      })
    )
  }

  const vatTypeOptions = [
    {
      label: 'БЕЗ НДС',
      value: 'NONE',
    },
    {
      label: '0%',
      value: 'VAT0',
    },
    {
      label: '5%',
      value: 'VAT5',
    },
    {
      label: '5%/105',
      value: 'VAT105',
    },
    {
      label: '7%',
      value: 'VAT7',
    },
    {
      label: '7%/107',
      value: 'VAT107',
    },
    {
      label: '10%',
      value: 'VAT10',
    },
    {
      label: '10%/110',
      value: 'VAT110',
    },
    {
      label: '20%',
      value: 'VAT20',
    },
    {
      label: '20%/120',
      value: 'VAT120',
    },
  ]
  const snoTypeOptions = [
    {
      label: 'Общая система налогообложения (ОСНО)',
      value: 'osn',
    },
    {
      label: 'Упрощенная система налогообложения "Доходы" (УСН)',
      value: 'usn_income',
    },
    {
      label: 'Упрощенная система налогообложения "Доходы минус расходы" (УСН)',
      value: 'usn_income_outcome',
    },
    {
      label: 'Единый сельскохозяйственный налог (ЕСХН)',
      value: 'esn',
    },
    {
      label: 'Патентная система налогообложения (ПСН)',
      value: 'patent',
    },
  ]

  const [productOptions, setProductOptions] = useState<DefaultOptionType[]>()
  const [isProductsLoading, setIsProductsLoading] = useState(false)

  const onSearchProducts = (value: string): void => {
    setIsProductsLoading(true)

    api.promotionsService
      .searchPromotionsProduct({ name: value })
      .then(res => {
        const options = mapProductsToOptions(res.data)
        setProductOptions(options)
      })
      .finally(() => setIsProductsLoading(false))
  }

  const onResetProducts = () => {
    console.log('onResetProducts')
    setProductOptions([])
  }

  return {
    paymentOptions,
    openModalCreateTill,
    openModalEditTill,
    onChangeOnlineTillValueEnabledSwitchValue,
    onlineTillEnabledSwitchValue,
    onChangeOfflineTillValueEnabledSwitchValue,
    offlineTillEnabledSwitchValue,
    onlineTillValue,
    vatTypeOptions,
    snoTypeOptions,
    productOptions,
    isProductsLoading,
    onSearchProducts,
    onResetProducts,
  }
}
