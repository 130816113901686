import { Button, Dropdown, Flex, Tag, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'

import { CustomCard } from '../../../../../custom-card/custom-card.component'
import { ExercisesPeekClientListItemProps } from './exercises-peek-client-list-item.types'
import { formatFullName } from '../../../../../../format/text.format'
import { formatPhoneNumber } from '../../../../../../format/phone.format'
import { ExercisesPeekSelectVisitConfirm } from '../../exercises-peek-select-visit-confirm/exercises-peek-select-visit-confirm.component'
import { ExercisesPeekLinkedProductsList } from '../../exercises-peek-linked-products-list/exercises-peek-linked-products-list.component'
import './exercises-peek-client-list-item.styles.less'
import { ExercisesPeekClientComments } from '../../exercises-peek-client-comments/exercises-peek-client-comments.component'
import { isDefAndNotEmpty } from '../../../../../../types/lang.types'
import { ExercisesPeekPaymentDropdown } from '../../exercises-peek-payment-dropdown/exercises-peek-payment-dropdown.component'
import { peekActions } from '../../../../../../store/common/peek/peek.slice'

export const ExercisesPeekClientListItem: React.FC<ExercisesPeekClientListItemProps> = props => {
  const {
    exerciseId,
    booking,
    isBookingsLoading,
    clientBookingDeletePermission,
    onVisitHandler,
    onCreateTransactionHandler,
    onCommentHandler,
    onCancelBookingHandler,
    onChangePaymentTypeHandler,
  } = props

  const dispatch = useDispatch()
  const history = useHistory()

  const menuItems = [
    ...(!booking.isCancelled
      ? [
          {
            key: 'ON_CANCEL',
            label: <Typography.Text disabled={!clientBookingDeletePermission}>Отменить запись</Typography.Text>,
          },
        ]
      : []),
    { key: 'ON_COMMENT', label: 'Оставить комментарий' },
    { key: 'ON_NOTIFY', label: <Typography.Text disabled>Написать клиенту</Typography.Text> },
  ]

  const handleMenuClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'ON_CANCEL':
        return clientBookingDeletePermission && onCancelBookingHandler(booking.id)
      case 'ON_COMMENT':
        return onCommentHandler(booking.id)
      case 'ON_NOTIFY':
        return
    }
  }

  const menu = {
    items: menuItems,
    onClick: handleMenuClick,
  }

  const handleAddProduct = useCallback(() => {
    onCreateTransactionHandler(booking.phone, booking.clientId)
  }, [booking.clientId, booking.phone, onCreateTransactionHandler])

  const handleClientClick = () => {
    dispatch(peekActions.closeLast())
    history.push({
      pathname: `/clients/${booking.clientId}`,
    })
  }

  return (
    <CustomCard
      customClassName={clsx('exercises-peek-client-list-item', {
        'exercises-peek-client-list-item--cancelled': booking.isCancelled,
      })}
    >
      <Flex vertical gap={15}>
        <Flex justify="space-between" align="flex-start">
          <Flex vertical gap={8}>
            <Typography.Title className="exercises-peek-client-list-item__name" level={4} onClick={handleClientClick}>
              {formatFullName(booking.firstName, booking.lastName)}
            </Typography.Title>
            <Flex align="center">
              {booking.isTrial && <Tag color="blue">Пробник</Tag>}
              <Typography.Text className="exercises-peek-client-list-item__phone" type="secondary">
                {formatPhoneNumber(booking.phone)}
              </Typography.Text>
            </Flex>
          </Flex>
          <Flex gap={10}>
            <ExercisesPeekPaymentDropdown
              exerciseFormat="GROUP"
              paymentType={booking.paymentType}
              bookingId={booking.id}
              phone={booking.phone}
              clientId={booking.clientId}
              exerciseId={exerciseId}
              isDisabled={booking.isCancelled}
              onChangePaymentType={onChangePaymentTypeHandler}
              onCreateTransaction={onCreateTransactionHandler}
            />
            <ExercisesPeekSelectVisitConfirm
              clientId={booking.id}
              visitConfirmed={booking.visitConfirmed}
              isLoading={isBookingsLoading}
              isCancelled={booking.isCancelled}
              onChangeVisitConfirmed={onVisitHandler}
            />
            <Dropdown menu={menu} trigger={['click']}>
              <Button size="large" icon={<EllipsisOutlined />} />
            </Dropdown>
          </Flex>
        </Flex>
        <Flex vertical gap={10}>
          {isDefAndNotEmpty(booking.comments) && (
            <ExercisesPeekClientComments
              comments={booking.comments}
              isBookingsLoading={isBookingsLoading}
              clientId={booking.id}
              exerciseFormat="GROUP"
              onCommentHandler={onCommentHandler}
            />
          )}
          <Flex justify="space-between" align="flex-start" gap={20}>
            <ExercisesPeekLinkedProductsList
              linkedProducts={booking.linkedProducts}
              isLoading={isBookingsLoading}
              bordered
              isCancelled={booking.isCancelled}
              onAddProduct={handleAddProduct}
            />
            <Tag className="exercises-peek-client-list-item__tag">Место {booking.placement}</Tag>
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  )
}
