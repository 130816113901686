import { DefaultOptionType } from 'antd/es/select'
import { useState } from 'react'

import { api } from '../../../../../api/api'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { genPaymentTypesOptions } from '../../../../../utils/payment.utils'
import { PaymentType } from '../../../../../api/types/api.types'
import { ExercisesPeekPaymentDropdownProps } from './exercises-peek-payment-dropdown.types'

interface Props extends ExercisesPeekPaymentDropdownProps {}

export function useExercisesPeekPaymentDropdown(props: Props) {
  const { exerciseId, bookingId, onChangePaymentType, onCreateTransaction, phone, clientId } = props

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentTypes, setPaymentTypes] = useState<DefaultOptionType[]>([])

  const handleChangePaymentType = (paymentType: PaymentType) => {
    onChangePaymentType(paymentType, bookingId)
  }

  const handleCreateTransaction = () => {
    onCreateTransaction(phone, clientId, exerciseId)
  }

  const handleButtonCreateTransaction = async () => {
    if (exerciseId) {
      setIsLoading(true)

      const phoneNumber = phone?.replace(/\D/g, '')

      await api.exercisesService
        .fetchPaymentTypes(exerciseId, phoneNumber)
        .then(res => {
          const options = genPaymentTypesOptions(res.data?.filter(type => type !== PaymentType.ON_PLACE))
          setPaymentTypes(options)
          if (isDefAndNotEmpty(options)) {
            setDropdownMenuOpen(true)
          } else {
            handleCreateTransaction()
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  return {
    dropdownMenuOpen,
    isLoading,
    paymentTypes,
    setDropdownMenuOpen,
    handleCreateTransaction,
    handleChangePaymentType,
    handleButtonCreateTransaction,
  }
}
