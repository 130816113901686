import { Flex, Typography, Button, Space } from 'antd'
import clsx from 'clsx'

import { formatDate } from '../../../../../format/date.format'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { ExercisesPeekClientCommentsProps } from './exercises-peek-client-comments.types'
import './exercises-peek-client-comments.styles.less'
import { useTheme } from '../../../../../hooks/use-theme.hook'

export const ExercisesPeekClientComments: React.FC<ExercisesPeekClientCommentsProps> = props => {
  const { clientComment, comments, isBookingsLoading, clientId, exerciseFormat, onCommentHandler } = props

  const { theme } = useTheme()

  return (
    <Flex
      vertical
      gap={5}
      className={clsx('exercises-peek-client-comments', {
        'exercises-peek-client-comments--dark': theme === 'dark',
      })}
    >
      {clientComment && (
        <Flex vertical className="exercises-peek-client-comments__comment">
          <Typography.Text type="secondary" strong className="exercises-peek-client-comments__comment-title">
            Комментарий от клиента
          </Typography.Text>
          <Typography.Text>{clientComment}</Typography.Text>
        </Flex>
      )}
      {isDefAndNotEmpty(comments) ? (
        <Flex
          gap={10}
          className="exercises-peek-client-comments__comment exercises-peek-client-comments__comment--colored"
          align="center"
        >
          <Flex vertical>
            <Typography.Text strong className="exercises-peek-client-comments__comment-title">
              Комм. от сотрудника {comments[comments.length - 1].employeeName} в{' '}
              {formatDate(comments[comments.length - 1].createDate, 'HH:mm, DD MMMM YYYY')}
            </Typography.Text>
            <Typography.Text>{comments[comments.length - 1].comment || ''}</Typography.Text>
          </Flex>
          <Button
            type="dashed"
            size="small"
            className="exercises-peek-client-comments__dashed-button"
            disabled={isBookingsLoading}
            onClick={() => onCommentHandler(clientId)}
          >
            {comments.length === 1 ? 'Добавить комментарий' : 'Посмотреть все'}
          </Button>
        </Flex>
      ) : (
        exerciseFormat === 'PERSONAL' && (
          <div
            className="exercises-peek-client-comments__comment exercises-peek-client-comments__comment--colored"
            style={{ alignSelf: 'baseline' }}
          >
            <Space size={10}>
              Хотите оставить комментарий?
              <Button
                type="dashed"
                size="small"
                className="exercises-peek-client-comments__dashed-button"
                disabled={isBookingsLoading}
                onClick={() => onCommentHandler(clientId)}
              >
                Написать
              </Button>
            </Space>
          </div>
        )
      )}
    </Flex>
  )
}
