import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import {
  genExercisesPeekFormValues,
  mapExerciseToSchedulePagePeekExerciseBookings,
  mapExerciseToSchedulePagePeekExercise,
} from '../../../../mapping/exercises.mapping'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { mapTrainersToExercisesForm } from '../../../../mapping/trainers.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'
import {
  mapExercisesTypeToDirectionsOptions,
  mapExercisesTypeToOptions,
} from '../../../../mapping/exercises-types.mapping'

const genExerciseInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.exercise
const genExerciseLinkedInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.exerciseLinked
const genExerciseBookingsInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.exerciseBookings
const genScheduleAuditInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.scheduleAudit

const getTrainersInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.trainers
const getStudiosInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.studios
const getExercisesTypeInternal = (state: AppState) => state.schedulePage.schedulePagePeekExercise.exercisesType

export const genSchedulePagePeekExerciseIsLoading = (state: AppState): boolean =>
  state.schedulePage.schedulePagePeekExercise.isExerciseLoading

export const genSchedulePagePeekExerciseIsLoaded = (state: AppState): boolean =>
  state.schedulePage.schedulePagePeekExercise.isExerciseLoaded

export const genSchedulePagePeekTrainersIsLoading = (state: AppState): boolean =>
  state.schedulePage.schedulePagePeekExercise.isTrainersLoading

export const genSchedulePagePeekBookingsIsLoading = (state: AppState): boolean =>
  state.schedulePage.schedulePagePeekExercise.isBookingsLoading

export const genSchedulePagePeekExercise = createSelector(
  genExerciseInternal,
  genExerciseLinkedInternal,
  mapExerciseToSchedulePagePeekExercise
)

export const genSchedulePagePeekExerciseBookings = createSelector(genExerciseBookingsInternal, exerciseBookings =>
  mapExerciseToSchedulePagePeekExerciseBookings(exerciseBookings?.content)
)

export const genSchedulePagePeekBookingsTotalElements = (state: AppState) =>
  state.schedulePage.schedulePagePeekExercise.exerciseBookings?.totalElements

export const genSchedulePagePeekExerciseClientsInWaitingList = (state: AppState) =>
  state.schedulePage.schedulePagePeekExercise.clientsInWaitingList

export const genSchedulePagePeekExerciseHistoryIsLoading = (state: AppState) =>
  state.schedulePage.schedulePagePeekExercise.isScheduleAuditLoading

export const genSchedulePagePeekExerciseHistoryTotalElements = createSelector(
  genScheduleAuditInternal,
  audit => audit?.totalElements
)

export const genSchedulePagePeekExerciseHistory = createSelector(
  genScheduleAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

export const genSchedulePagePeekExerciseFormValues = createSelector(
  genExerciseInternal,
  getExercisesTypeInternal,
  getCurrentStudioOffset,
  (exercise, exercisesType, studioOffset) => genExercisesPeekFormValues(exercise, exercisesType, studioOffset)
)

const getFilteredStudios = createSelector(getStudiosInternal, genExerciseInternal, (studios, exercise) =>
  studios?.content?.filter(
    studio => studio.organization.id === exercise?.organizationId || studio.id === exercise?.studio.id
  )
)

export const genSchedulePagePeekExercisTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)

export const genSchedulePagePeekExercisStudiosOptions = createSelector(getFilteredStudios, mapStudiosToOptions)

export const genSchedulePagePeekExercisStudiosRoomsOptions = createSelector(
  getFilteredStudios,
  mapStudiosToRoomsOptions
)

export const genSchedulePagePeekExercisTypesOptions = createSelector(getExercisesTypeInternal, exercisesTypes => {
  return mapExercisesTypeToOptions(exercisesTypes)
})

export const genSchedulePagePeekExercisDirectionsOptions = createSelector(getExercisesTypeInternal, exercisesTypes => {
  return mapExercisesTypeToDirectionsOptions(exercisesTypes)
})
