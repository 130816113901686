import { useMemo } from 'react'
import { Space, Typography, Badge, Select } from 'antd'
import clsx from 'clsx'

import { genVisitConfirmOptions } from './exercises-peek-select-visit-confirm.utils'
import './exercises-peek-select-visit-confirm.styles.less'
import { useTheme } from '../../../../../hooks/use-theme.hook'

interface Props {
  clientId: string
  visitConfirmed: boolean
  isLoading: boolean
  isCancelled?: boolean
  onChangeVisitConfirmed: (clientId: string, confirmed: boolean) => void
}

export const ExercisesPeekSelectVisitConfirm: React.FC<Props> = props => {
  const { visitConfirmed, clientId, isLoading, isCancelled, onChangeVisitConfirmed } = props

  const { theme } = useTheme()

  const options = useMemo(genVisitConfirmOptions, [])

  const handleChange = (confirmed: boolean) => {
    onChangeVisitConfirmed(clientId, confirmed)
  }

  if (!isCancelled) {
    return (
      <Select
        className="exercises-peek-select-visit-confirm"
        size="large"
        options={options}
        value={visitConfirmed}
        disabled={isLoading}
        loading={isLoading}
        onChange={handleChange}
      />
    )
  }

  return (
    <Space
      size={8}
      className={clsx('exercises-peek-select-visit-confirm exercises-peek-select-visit-confirm--cancelled', {
        'exercises-peek-select-visit-confirm--dark': theme === 'dark',
      })}
    >
      <Badge color="red" />
      <Typography.Text strong>Отмена записи</Typography.Text>
    </Space>
  )
}
