import { createSelector } from '@reduxjs/toolkit'

import { mapManagersToDropdown } from '../../../mapping/contracts.mapping'
import { AppState } from '../../app.store'

const getManagersInternal = (state: AppState) => state.selectManagers.managers?.content || []
export const getSelectManagersIsLoading = (state: AppState) => state.selectManagers.isLoading
export const getSelectManagersIsLoaded = (state: AppState) => state.selectManagers.isLoaded
export const getSelectManagersIsFetching = (state: AppState) => state.selectManagers.isFetching
export const getManagersOptions = createSelector(getManagersInternal, mapManagersToDropdown)
