import { createSelector } from '@reduxjs/toolkit'

import { getCurrentStudioOffset } from '../../../../common/layout/layout.selectors'
import {
  genPromotionsCodesPatternFormValue,
  genPromotionsEditOrganization,
} from '../../../../../mapping/promotions.mapping'
import { AppState } from '../../../../app.store'

const getStudiosInternal = (state: AppState) => state.promotionsPage.promotionsPageEditSales.studios
const getPromotionsCodeInternal = (state: AppState) => state.promotionsPage.promotionsPageCodesEdit.code
export const getPromotionsCodeIsModalLoading = (state: AppState) =>
  state.promotionsPage.promotionsPageCodesEdit.isModalLoading
const getPromotionsCodesPatternInternal = (state: AppState) => state.promotionsPage.promotionsPageCodesEdit.codePattern
export const getPromotionsCodeIsEditing = (state: AppState) => state.promotionsPage.promotionsPageCodesEdit.isEditing
export const getCodesEditSelectedFranchises = createSelector(
  getPromotionsCodeInternal,
  getStudiosInternal,
  (promotion, studios) => genPromotionsEditOrganization(promotion?.limitedByStudioIds, studios?.content)
)
export const getCodesPatternEditSelectedFranchises = createSelector(
  getPromotionsCodesPatternInternal,
  getStudiosInternal,
  (promotion, studios) => genPromotionsEditOrganization(promotion?.limitedByStudioIds, studios?.content)
)
export const getPromotionsCodesPatternFormValue = createSelector(
  getPromotionsCodesPatternInternal,
  getCurrentStudioOffset,
  (codesPatter, offset) => genPromotionsCodesPatternFormValue(codesPatter, offset)
)
