import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { mapExercisesSpotsToOptions } from '../../../../mapping/exercises.mapping'

const genExerciseSpotsInternal = (state: AppState): Nullable<ExercisesApi.ExerciseSpot[]> =>
  state.schedulePage.schedulePageModalBookFromWaiting.exercisesSpots

export const genSchedulePageModalBookFromWaitingIsLoading = (state: AppState): boolean =>
  state.schedulePage.schedulePageModalBookFromWaiting.isLoading

export const genSchedulePageModalBookFromWaitingExercisesSpots = createSelector(
  genExerciseSpotsInternal,
  mapExercisesSpotsToOptions
)
