import { createSelector } from '@reduxjs/toolkit'

import { getCurrentStudioOffset } from '../../../../common/layout/layout.selectors'
import { AppState } from '../../../../app.store'
import { genPromotionsCodesFormValue, mapCodesToPromotionsCodesTable } from '../../../../../mapping/promotions.mapping'

export const getPromotionsCodesInternal = (state: AppState) => state.promotionsPage.promotionsPageCodesCreate.codes

const getPromotionsCodeInternal = (state: AppState) => state.promotionsPage.promotionsPageCodesEdit.code
export const getPromotionsCodesIsLoading = (state: AppState) => state.promotionsPage.promotionsPageCodesCreate.isLoading
export const getPromotionsCodeIsCreating = (state: AppState) =>
  state.promotionsPage.promotionsPageCodesCreate.isCreating
export const getPromotionsCodesIsLoaded = (state: AppState) => state.promotionsPage.promotionsPageCodesCreate.isLoaded
export const getPromotionsCodesFormValue = createSelector(
  getPromotionsCodeInternal,
  getCurrentStudioOffset,
  (codes, offset) => genPromotionsCodesFormValue(codes, offset)
)
export const getPromotionsCodesProductsList = createSelector(getPromotionsCodeInternal, code => code?.products)
export const getPromotionsCodeTotalElements = createSelector(getPromotionsCodesInternal, codes => codes?.totalElements)

export const getPromotionsCodesForTableRow = createSelector(getPromotionsCodesInternal, codes =>
  mapCodesToPromotionsCodesTable(codes?.content)
)
