import { Tabs } from 'antd'

import { ExercisesPeekTabsProps } from './exercises-peek-tabs.types'
import { ExercisesPeekHistory } from '../exercises-peek-history/exercises-peek-history.component'
import { ExercisesPeekOverview } from '../exercises-peek-overview/exercises-peek-overview.component'
import './exercises-peek-tabs.styles.less'

export const ExercisesPeekTabs: React.FC<ExercisesPeekTabsProps> = props => {
  const {
    history,
    isHistoryLoading,
    historyPaginationConfig,
    fetchHistory,
    onChangeHistoryPageHandler,
    onChangeHistoryPageSizeHandler,
    ...rest
  } = props

  const tabs = [
    {
      label: 'О записи',
      key: 'overview',
      children: <ExercisesPeekOverview {...rest} />,
    },
    {
      label: 'История действий',
      key: 'history',
      children: (
        <ExercisesPeekHistory
          exerciseHistory={history}
          isLoading={isHistoryLoading}
          pagination={historyPaginationConfig}
          fetchHistory={fetchHistory}
          onChangePage={onChangeHistoryPageHandler}
          onChangePageSize={onChangeHistoryPageSizeHandler}
        />
      ),
    },
  ]

  return <Tabs className="exercises-peek-tabs" defaultActiveKey="overview" items={tabs} />
}
