import React from 'react'
import { Checkbox } from 'antd'

import { PromotionsCodesTable } from '../../../../components/promotions/promotions-codes-table/promotions-codes-table.component'
import { ContractsTabsContainer } from '../../../../components/contracts/contracts-tabs-container/contracts-tabs-container.component'
import { useCodesTabs } from './promotions-page-tabs-codes.hook'

export const CodesTabs = () => {
  const {
    isLoading,
    codes,
    pagination,
    onVisiblePastCodesHandler,
    onChangePageSizeHandler,
    onRemovePatternHandler,
    onChangePageHandler,
    onRemoveHandler,
    onEditCodesHandler,
    onEditCodesPatternHandler,
  } = useCodesTabs()

  return (
    <ContractsTabsContainer>
      <Checkbox onChange={e => onVisiblePastCodesHandler(e.target.checked)}>Скрывать прошедшие</Checkbox>
      <PromotionsCodesTable
        codes={codes}
        isLoading={isLoading}
        pagination={pagination}
        onChangePageSize={onChangePageSizeHandler}
        onChangePage={onChangePageHandler}
        onRemovePattern={onRemovePatternHandler}
        onRemove={onRemoveHandler}
        onEditPattern={onEditCodesPatternHandler}
        onEdit={onEditCodesHandler}
      />
    </ContractsTabsContainer>
  )
}
