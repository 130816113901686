import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { Pagination } from '../../../../api/types/api.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import {
  SchedulePageModalWaitingBookClient,
  SchedulePageModalWaitingFetchWaitingListPayload,
} from './schedule-page-modal-waiting.types'

export interface SchedulePageModalWaitingState {
  waitingList: Nullable<Pagination<ExercisesApi.ExerciseWaitListItem>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: SchedulePageModalWaitingState = {
  waitingList: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: schedulePageModalWaitingActions, reducer: schedulePageModalWaitingReducer } = createSlice({
  name: '@@schedule-page-modal-waiting',
  initialState,
  reducers: {
    fetchWaitingList: (
      state: Draft<SchedulePageModalWaitingState>,
      _: PayloadAction<SchedulePageModalWaitingFetchWaitingListPayload>
    ) => {
      state.isLoading = true
    },
    fetchWaitingListSuccess: (
      state: Draft<SchedulePageModalWaitingState>,
      action: PayloadAction<Nullable<Pagination<ExercisesApi.ExerciseWaitListItem>>>
    ) => {
      state.waitingList = action.payload
      state.isLoaded = true
      state.isLoading = false
    },
    fetchWaitingListError: (state: Draft<SchedulePageModalWaitingState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoaded = true
      state.isLoading = false
    },
    bookClient: (
      state: Draft<SchedulePageModalWaitingState>,
      _action: PayloadAction<SchedulePageModalWaitingBookClient>
    ) => {},

    reset: () => initialState,
  },
})
