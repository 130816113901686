import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, notification } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  genSchedulePagePeekExercise,
  genSchedulePagePeekExerciseBookings,
  genSchedulePagePeekExerciseHistoryIsLoading,
  genSchedulePagePeekExerciseHistory,
  genSchedulePagePeekExerciseHistoryTotalElements,
  genSchedulePagePeekExerciseIsLoading,
  genSchedulePagePeekExercisStudiosOptions,
  genSchedulePagePeekExercisStudiosRoomsOptions,
  genSchedulePagePeekExercisTrainersOptions,
  genSchedulePagePeekExercisTypesOptions,
  genSchedulePagePeekExerciseFormValues,
  genSchedulePagePeekExercisDirectionsOptions,
  genSchedulePagePeekBookingsIsLoading,
  genSchedulePagePeekExerciseIsLoaded,
  genSchedulePagePeekTrainersIsLoading,
  genSchedulePagePeekExerciseClientsInWaitingList,
  genSchedulePagePeekBookingsTotalElements,
} from '../../../store/pages/schedule-page/schedule-page-peek-exercise/schedule-page-peek-exercise.selectors'
import { schedulePagePeekExerciseActions } from '../../../store/pages/schedule-page/schedule-page-peek-exercise/schedule-page-peek-exercise.slice'
import { genScheduleExercisePagePath, genSchedulePagePath } from '../../../format/path.format'
import { DEFAULT_PAGE_SIZE, genListPaginationConfig } from '../../../utils/pagination.utils'
import { ExercisesPeekFormValues } from '../../../components/exercises/exercises-peek/ui/exercises-peek-overview/exercises-peek-overview.types'
import { isDef, NString } from '../../../types/lang.types'
import { mapTrainersToExercisesFormTrainersOptions } from '../../../components/exercises/exercises-form/exercises-form.utils'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { peekActions } from '../../../store/common/peek/peek.slice'
import { PaymentType } from '../../../api/types/api.types'
import { syncKkmWithBackend } from '../../../utils/api/sync-kkm-with-backend/sync-kkm-with-backend.utils'
import { formatRublesPenniesCurrency } from '../../../format/number.format'
import { getKkmCommands } from '../../../utils/api/sync-kkm-with-backend/utils/get-kkm-commands.utils'
import { EXERCISE_PEEK_BOOKINS_SIZE } from './schedule-page-peek-exercise.types'
import { useSchedulePageParams } from '../schedule-page-hooks/schedule-page-params.hook'
import { AppPath } from '../../../types/path.types'
import {
  getClientBookingCreatePermission,
  getClientBookingDeletePermission,
  getExerciseDeletePermission,
  getExerciseUpdatePermission,
} from '../../../store/common/permissions/permissions.selectors'

export function useSchedulePagePeekExercise(studioId: string, exerciseId: string) {
  const dispatch = useDispatch()

  const history = useHistory()

  const params = useSchedulePageParams()

  const isSchedulePage = useRouteMatch(AppPath.SCHEDULE)?.isExact
  const isScheduleExercisePage = useRouteMatch(AppPath.SCHEDULE_EXERCISE)?.isExact

  const [form] = Form.useForm<ExercisesPeekFormValues>()

  const isLoading = useSelector(genSchedulePagePeekExerciseIsLoading)
  const isLoaded = useSelector(genSchedulePagePeekExerciseIsLoaded)
  const exercise = useSelector(genSchedulePagePeekExercise)
  const isTrainersLoading = useSelector(genSchedulePagePeekTrainersIsLoading)
  const bookings = useSelector(genSchedulePagePeekExerciseBookings)
  const bookingsTotalElements = useSelector(genSchedulePagePeekBookingsTotalElements)
  const isBookingsLoading = useSelector(genSchedulePagePeekBookingsIsLoading)
  const exerciseHistory = useSelector(genSchedulePagePeekExerciseHistory)
  const clientsInWaitingList = useSelector(genSchedulePagePeekExerciseClientsInWaitingList)
  const isHistoryLoading = useSelector(genSchedulePagePeekExerciseHistoryIsLoading)
  const historyTotalElements = useSelector(genSchedulePagePeekExerciseHistoryTotalElements)

  const allTrainersOptions = useSelector(genSchedulePagePeekExercisTrainersOptions)
  const studiosOptions = useSelector(genSchedulePagePeekExercisStudiosOptions)
  const roomsOptions = useSelector(genSchedulePagePeekExercisStudiosRoomsOptions)
  const masterServicesOptions = useSelector(genSchedulePagePeekExercisTypesOptions)
  const directionsOptions = useSelector(genSchedulePagePeekExercisDirectionsOptions)

  const formValues = useSelector(genSchedulePagePeekExerciseFormValues)

  const clientBookingCreatePermission = useSelector(getClientBookingCreatePermission)
  const clientBookingDeletePermission = useSelector(getClientBookingDeletePermission)
  const exerciseUpdatePermission = useSelector(getExerciseUpdatePermission)
  const exerciseDeletePermission = useSelector(getExerciseDeletePermission)

  const exercisePath = useMemo(() => {
    return window.location.host + genScheduleExercisePagePath(studioId, exerciseId)
  }, [studioId, exerciseId])

  const typeId = exercise?.type.id

  const [trainersOptions, setTrainersOptions] = useState<DefaultOptionType[] | undefined>([])

  const [bookingsSize, setBookingsSize] = useState(EXERCISE_PEEK_BOOKINS_SIZE)

  const [historyPage, setHistoryPage] = useState(0)
  const [historyPageSize, setHistoryPageSize] = useState(DEFAULT_PAGE_SIZE)

  const historyPaginationConfig = useMemo(
    () => genListPaginationConfig(historyPage, historyPageSize, historyTotalElements),
    [historyPage, historyPageSize, historyTotalElements]
  )

  const onLoadMoreBookingsHandler = useCallback((): void => {
    setBookingsSize(prev => (prev += EXERCISE_PEEK_BOOKINS_SIZE))
    dispatch(
      schedulePagePeekExerciseActions.fetchBookings({
        exerciseId,
        size: bookingsSize + EXERCISE_PEEK_BOOKINS_SIZE,
      })
    )
  }, [bookingsSize, dispatch, exerciseId])

  const onChangeHistoryPageHandler = useCallback((page: number, pageSize: number): void => {
    setHistoryPage(page)
    setHistoryPageSize(pageSize)
  }, [])

  const onChangeHistoryPageSizeHandler = useCallback((pageSize: number): void => {
    setHistoryPageSize(pageSize)
  }, [])

  const fetchHistory = useCallback(() => {
    dispatch(
      schedulePagePeekExerciseActions.fetchAudit({
        id: exerciseId,
        page: historyPage,
        size: historyPageSize,
      })
    )
  }, [dispatch, historyPage, historyPageSize, exerciseId])

  const onBookingHandler = useCallback(() => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_PAGE_MODAL_BOOKING,
        props: { scheduleId: exerciseId },
      })
    )
  }, [dispatch, exerciseId])

  const onCommentHandler = useCallback(
    (bookingId: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_PAGE_MODAL_COMMENTS,
          props: { exerciseId: exerciseId, bookingId },
        })
      )
    },
    [dispatch, exerciseId]
  )

  const onVisitHandler = useCallback(
    (bookingId: string, confirm: boolean): void => {
      dispatch(
        schedulePagePeekExerciseActions.changeBookingVisitingConfirmation({
          exerciseId: exerciseId,
          bookingId,
          confirm,
          bookingsSize,
        })
      )
    },
    [bookingsSize, dispatch, exerciseId]
  )

  const onCreateTransactionHandler = useCallback(
    (phone: string, clientId: string, exerciseId?: string): void => {
      dispatch(peekActions.closeLast())
      history.push({
        pathname: `/transactions/${studioId}/create`,
        state: { phone, clientId, exerciseId },
      })
    },
    [dispatch, history, studioId]
  )

  const onChangePaymentTypeHandler = useCallback(
    (paymentType: PaymentType, bookingId: string): void => {
      dispatch(
        schedulePagePeekExerciseActions.changeBookingPaymentType({
          exerciseId,
          paymentType,
          bookingId,
          bookingsSize,
        })
      )
    },
    [bookingsSize, dispatch, exerciseId]
  )

  const onCancelBookingHandler = useCallback(
    async (bookingId: string): Promise<void> => {
      const kkmCommand = await getKkmCommands(api =>
        api.exercisesService.fetchCancelBooking(exerciseId as string, bookingId)
      )
      const refundSum = formatRublesPenniesCurrency(kkmCommand?.response?.cancellationOptions?.money?.refundSum ?? 0)
      const onSubmit = async () => {
        await syncKkmWithBackend(
          async () => ({
            data: {
              ...kkmCommand?.response,
              kkmCommandData: kkmCommand?.response?.cancellationOptions?.money?.kkmCommandData,
            },
          }),
          async (api, kkmCommandResponses) => {
            const response = await api.exercisesService.cancelBooking(exerciseId as string, bookingId, {
              kkmCommandResponses,
            })

            dispatch(schedulePagePeekExerciseActions.fetchExercise(exerciseId))
            dispatch(schedulePagePeekExerciseActions.fetchBookings({ exerciseId, size: bookingsSize }))

            return response
          },
          'Запись успешно отменена',
          'Ошибка отмены записи'
        )
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена записи клиента',
            text: `Вы уверены, что хотите отменить запись клиента на сумму ${refundSum}?`,
            onSubmit,
          },
        })
      )
    },
    [bookingsSize, dispatch, exerciseId]
  )

  const onRefetchTrainersHandler = useCallback(
    (roomId: string, timeFrom?: string, timeTo?: string) => {
      dispatch(
        schedulePagePeekExerciseActions.fetchTrainers({
          roomId,
          timeFrom,
          timeTo,
        })
      )
    },
    [dispatch]
  )

  const editExercise = useCallback(
    (values: ExercisesPeekFormValues, comment: NString) => {
      dispatch(
        schedulePagePeekExerciseActions.editExercise({
          exerciseId,
          exercisesEditFormValues: values,
          comment,
          successCallback: () => {
            notification.success({
              message: `Сохранено!`,
              description: <div>Запись успешно обновлена</div>,
            })
          },
        })
      )
    },
    [dispatch, exerciseId]
  )

  const onEditExerciseCommentHandler = useCallback(
    (comment: string): void => {
      if (formValues) {
        editExercise(formValues, comment)
      }
    },
    [editExercise, formValues]
  )

  const onEditExerciseHandler = useCallback(
    (values: ExercisesPeekFormValues): void => {
      editExercise(values, exercise?.comment)
    },
    [editExercise, exercise?.comment]
  )

  const onCancelExerciseHandler = useCallback((): void => {
    const onSubmit = () => dispatch(schedulePagePeekExerciseActions.removeExercise(exerciseId))

    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_PAGE_MODAL_CONFIRM,
        props: {
          title: 'Отмена записи',
          text: 'Вы уверены, что хотите отменить запись?',
          onSubmit,
        },
      })
    )
  }, [dispatch, exerciseId])

  const onCancelPeekHandler = useCallback((): void => {
    if (isSchedulePage) {
      history.push(genSchedulePagePath(studioId, { ...params }))
    }
    if (isScheduleExercisePage) {
      history.push(genSchedulePagePath(studioId, { ...params }))
    }
  }, [isSchedulePage, isScheduleExercisePage, history, studioId, params])

  useEffect(() => {
    if (typeId !== undefined) {
      setTrainersOptions(mapTrainersToExercisesFormTrainersOptions(allTrainersOptions, typeId))
    }
  }, [typeId, allTrainersOptions])

  useEffect(() => {
    dispatch(schedulePagePeekExerciseActions.fetchPeekData(exerciseId))
  }, [dispatch, exerciseId])

  useEffect(() => {
    if (isDef(formValues)) {
      form.setFieldsValue(formValues)
    }
  }, [form, formValues])

  useEffect(() => {
    return () => {
      dispatch(schedulePagePeekExerciseActions.reset())
    }
  }, [dispatch, exerciseId])

  return {
    isLoading,
    isLoaded,
    exercise,
    isTrainersLoading,
    bookings,
    isBookingsLoading,
    bookingsTotalElements,
    form,
    exercisePath,
    exerciseHistory,
    isHistoryLoading,
    historyPaginationConfig,
    bookingsSize,
    clientsInWaitingList,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    masterServicesOptions,
    directionsOptions,
    clientBookingCreatePermission,
    clientBookingDeletePermission,
    exerciseUpdatePermission,
    exerciseDeletePermission,
    fetchHistory,
    onChangeHistoryPageHandler,
    onChangeHistoryPageSizeHandler,
    onLoadMoreBookingsHandler,
    onBookingHandler,
    onCommentHandler,
    onVisitHandler,
    onCreateTransactionHandler,
    onChangePaymentTypeHandler,
    onRefetchTrainersHandler,
    onEditExerciseCommentHandler,
    onEditExerciseHandler,
    onCancelBookingHandler,
    onCancelExerciseHandler,
    onCancelPeekHandler,
  }
}
