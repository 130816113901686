import { TeamOutlined } from '@ant-design/icons'
import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'

import './schedule-page-header.styles.less'

interface Props {
  currentTimetable: 'weekly' | 'daily'
  date?: string
  totalClientsCounts: number
  totalMaxClientsCount: number
}
export const SchedulePageHeader: React.FC<Props> = props => {
  const { currentTimetable, date, totalClientsCounts, totalMaxClientsCount } = props

  const targetDate = dayjs(date)

  if (currentTimetable === 'daily') {
    return (
      <Flex gap={8} align="center" justify="center" className="schedule-page-header">
        <Typography.Text strong className="schedule-page-header__date">
          {targetDate.format('D MMMM')}
        </Typography.Text>

        <Flex className="schedule-page-header__guests" align="center" gap="2px">
          <TeamOutlined />
          {totalClientsCounts}/{totalMaxClientsCount}
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex gap={8} align="center" justify="center" className="schedule-page-header">
      <Typography.Text strong className="schedule-page-header__date">
        {targetDate.startOf('isoWeek').format('D MMMM')} - {targetDate.endOf('isoWeek').format('D MMMM')}
      </Typography.Text>
    </Flex>
  )
}
