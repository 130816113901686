import { combineReducers } from '@reduxjs/toolkit'

import { contractsInfoPageContractsEditReducer } from './contracts-info-page-contracts/contracts-info-page-contracts-edit/contracts-info-page-contracts-edit.slice'
import { contractsInfoPageContractsCreateReducer } from './contracts-info-page-contracts/contracts-info-page-contracts-create/contracts-info-page-contracts.slice'
import { contractsInfoPageClientsReducer } from './contracts-info-page-clients/contracts-info-page-clients.slice'
import { contractsInfoPageDescriptionReducer } from './contracts-info-page-description/contracts-info-page-description.slice'

export const contractsInfoPageReducer = combineReducers({
  contractsInfoPageClients: contractsInfoPageClientsReducer,
  contractsInfoPageDescription: contractsInfoPageDescriptionReducer,
  contractsInfoPageContractsCreate: contractsInfoPageContractsCreateReducer,
  contractsInfoPageContractsEdit: contractsInfoPageContractsEditReducer,
})
