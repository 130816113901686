import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { selectManagersActions } from '../../store/components/select-managers/select-managers.slice'
import {
  getManagersOptions,
  getSelectManagersIsFetching,
  getSelectManagersIsLoading,
} from '../../store/components/select-managers/select-managers.selectors'

export const useSelectManagers = () => {
  const dispatch = useDispatch()
  const managers = useSelector(getManagersOptions)
  const isLoading = useSelector(getSelectManagersIsLoading)
  const isFetching = useSelector(getSelectManagersIsFetching)

  React.useEffect(() => {
    if ((!managers || managers?.length === 0) && !isFetching) {
      dispatch(selectManagersActions.fetchAllManagers())
    }
  }, [managers, dispatch, isFetching, isLoading])

  return {
    managers,
    isLoading,
  }
}
