import React from 'react'
import { useDispatch } from 'react-redux'

import { contractsInfoPageContractsCreateActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-create/contracts-info-page-contracts.slice'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../../types/modal.types'

export const useContractInfoContractCard = (id: string, counterpartyId: string, contractSum: number) => {
  const dispatch = useDispatch()

  const onPaidContract = React.useCallback(
    (value: ContractsApi.ContractPaidParams) => {
      dispatch(contractsInfoPageContractsCreateActions.paidContract({ id, data: value, counterpartyId }))
    },
    [dispatch, id, counterpartyId]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onPaidContractHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_PAID_MODAL,
        props: {
          handleOk: onPaidContract,
          handleCancel: onCloseHandler,
          contractSum,
        },
      })
    )
  }, [contractSum, dispatch, onCloseHandler, onPaidContract])

  const onDeleteContract = React.useCallback(() => {
    dispatch(contractsInfoPageContractsCreateActions.deleteContract({ id, counterpartyId }))
  }, [dispatch, id, counterpartyId])

  const onDeleteContractHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_CONFIRM_MODAL,
        props: {
          handleOk: onDeleteContract,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, onCloseHandler, onDeleteContract])

  return {
    onPaidContractHandler,
    onDeleteContractHandler,
  }
}
