import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { genSchedulePageListTimetable } from '@store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { genHomePagePath, genSchedulePagePath } from '../../format/path.format'
import { isDef } from '../../types/lang.types'
import { SchedulePageFilter } from './schedule-page-filter/schedule-page-filter.component'
import { SchedulePageTable } from './schedule-page-table/schedule-page-table.component'
import { useSchedulePage } from './schedule-page.hook'
import { useSchedulePageTable } from './schedule-page-table/schedule-page-table.hook'
import { SchedulePageHeader } from './schedule-page-header/schedule-page-header.component'
import { SchedulePageClosingModeControls } from './schedule-page-closing-mode-controls/schedule-page-closing-mode-controls.component'

export const SchedulePage: React.FC = () => {
  const { studioId, selectedStudioId, allowedStudios, isLoaded, isLoading } = useSchedulePage()
  const schedulePageData = useSchedulePageTable()

  const currentTimetable = useSelector(genSchedulePageListTimetable)

  if (!isDef(studioId)) {
    if (isDef(selectedStudioId)) {
      return <Redirect to={genSchedulePagePath(selectedStudioId)} />
    }
    return <Redirect to={genHomePagePath()} />
  }

  if (!allowedStudios?.includes(studioId)) {
    notification.error({
      message: 'У вас нет разрешения на это действие',
    })

    if (isDef(selectedStudioId)) {
      return <Redirect to={genSchedulePagePath(selectedStudioId)} />
    }
    return <Redirect to={genHomePagePath()} />
  }

  if (!isLoaded || isLoading) {
    return <PageLoader />
  }

  const totalClientsCounts = (schedulePageData?.schedule ?? []).reduce(
    (total, event) => total + (event.clientsCounts ?? 0),
    0
  )
  const totalMaxClientsCount = (schedulePageData?.schedule ?? []).reduce(
    (total, event) => total + (event.maxClientsCount ?? 0),
    0
  )

  return (
    <>
      <SchedulePageFilter />
      <SchedulePageClosingModeControls />
      <SchedulePageHeader
        currentTimetable={currentTimetable}
        date={schedulePageData.date}
        totalClientsCounts={totalClientsCounts}
        totalMaxClientsCount={totalMaxClientsCount}
      />
      <SchedulePageTable {...schedulePageData} />
    </>
  )
}
