import { ExercisesPeek } from '../../../components/exercises/exercises-peek/exercises-peek.component'
import { useSchedulePagePeekExercise } from './schedule-page-peek-exercise.hook'
import { SchedulePagePeekExerciseProps } from './schedule-page-peek-exercise.types'

export const SchedulePagePeekExercise: React.FC<SchedulePagePeekExerciseProps> = props => {
  const { studioId, exerciseId } = props

  const {
    isLoading,
    isLoaded,
    exercise,
    bookings,
    isBookingsLoading,
    bookingsTotalElements,
    exercisePath,
    exerciseHistory,
    form,
    isHistoryLoading,
    historyPaginationConfig,
    bookingsSize,
    isTrainersLoading,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    masterServicesOptions,
    directionsOptions,
    clientsInWaitingList,
    clientBookingCreatePermission,
    clientBookingDeletePermission,
    exerciseUpdatePermission,
    exerciseDeletePermission,
    fetchHistory,
    onChangeHistoryPageHandler,
    onChangeHistoryPageSizeHandler,
    onLoadMoreBookingsHandler,
    onBookingHandler,
    onCommentHandler,
    onVisitHandler,
    onCreateTransactionHandler,
    onChangePaymentTypeHandler,
    onRefetchTrainersHandler,
    onCancelBookingHandler,
    onEditExerciseCommentHandler,
    onEditExerciseHandler,
    onCancelExerciseHandler,
    onCancelPeekHandler,
  } = useSchedulePagePeekExercise(studioId, exerciseId)

  return (
    <ExercisesPeek
      isLoading={isLoading}
      isLoaded={isLoaded}
      exercise={exercise}
      bookings={bookings}
      isBookingsLoading={isBookingsLoading}
      bookingsTotalElements={bookingsTotalElements}
      exercisePath={exercisePath}
      history={exerciseHistory}
      form={form}
      isHistoryLoading={isHistoryLoading}
      historyPaginationConfig={historyPaginationConfig}
      bookingsSize={bookingsSize}
      isTrainersLoading={isTrainersLoading}
      clientsInWaitingList={clientsInWaitingList}
      trainersOptions={trainersOptions}
      studiosOptions={studiosOptions}
      studiosRoomsOptions={roomsOptions}
      masterServicesOptions={masterServicesOptions}
      directionsOptions={directionsOptions}
      clientBookingCreatePermission={clientBookingCreatePermission}
      clientBookingDeletePermission={clientBookingDeletePermission}
      exerciseUpdatePermission={exerciseUpdatePermission}
      exerciseDeletePermission={exerciseDeletePermission}
      fetchHistory={fetchHistory}
      onChangeHistoryPage={onChangeHistoryPageHandler}
      onChangeHistoryPageSize={onChangeHistoryPageSizeHandler}
      onLoadMoreBookings={onLoadMoreBookingsHandler}
      onBooking={onBookingHandler}
      onComment={onCommentHandler}
      onVisit={onVisitHandler}
      onCreateTransaction={onCreateTransactionHandler}
      onChangePaymentType={onChangePaymentTypeHandler}
      onRefetchTrainers={onRefetchTrainersHandler}
      onCancelBooking={onCancelBookingHandler}
      onEditExerciseComment={onEditExerciseCommentHandler}
      onEditExercise={onEditExerciseHandler}
      onCancelExercise={onCancelExerciseHandler}
      onCancelPeek={onCancelPeekHandler}
    />
  )
}
