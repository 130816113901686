import * as React from 'react'

import { useSchedulePageModalWaiting } from './schedule-page-modal-waiting.hook'
import { SchedulePageModalWaitingProps } from './schedule-page-modal-waiting.types'
import { ExercisesWaitingClientsModal } from '../../../components/exercises/exercises-waiting-clients-modal/exercises-waiting-clients-modal.component'

export const SchedulePageModalWaiting: React.FC<SchedulePageModalWaitingProps> = props => {
  const { exerciseId } = props

  const { waitingList, loading, onSearchHandler, onBookHandler, onCancelHandler } = useSchedulePageModalWaiting({
    exerciseId,
  })

  return (
    <ExercisesWaitingClientsModal
      clients={waitingList}
      loading={loading}
      onSearch={onSearchHandler}
      onBook={onBookHandler}
      onCancel={onCancelHandler}
    />
  )
}
