import React, { useState } from 'react'
import { Flex, Form, FormInstance, Input, Spin, Select, Button, Typography } from 'antd'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

import { Nullable } from '../../../../types/lang.types'
import { validateNumericInput } from '../../../../utils/form/validations-rules-form'
import { usePromotionsFormProducts } from './promotions-create-form-products.hook'
import { PromotionsApi } from '../../../../api/types/promotions-api.types'

interface PromotionsSalesTabProps {
  productsList?: PromotionsApi.PromotionsProduct[] | Nullable<PromotionsApi.PromotionsProduct[]>
  onSearchHandler: (name: string, type: PromotionsApi.PromotionProductType) => void
  form: FormInstance
}

export const PromotionsFormProducts = ({ onSearchHandler, form, productsList }: PromotionsSalesTabProps) => {
  const {
    isLoading,
    productsOptions,
    resetPromotionsProductsHandler,
    discountTypeOptions,
    discountTypeProductOptions,
  } = usePromotionsFormProducts(productsList)
  const [productType, setProductType] = useState<PromotionsApi.PromotionProductType>('GOODS')
  const DEFAULT_TABS_COUNT = 4

  function changeProductTypeHandler(type: PromotionsApi.PromotionProductType) {
    setProductType(type)
    form.setFieldValue('typeProduct', type)
  }

  function searchProductsHandler(value: string) {
    onSearchHandler(value, productType)
  }

  return (
    <Flex gap={'medium'} vertical>
      <Typography.Title level={4} style={{ margin: '0', marginBottom: '16px' }}>
        Действие акции
      </Typography.Title>
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const selectDiscountAfter = (
                <Form.Item name={[name, 'discountType']} noStyle initialValue="PERCENT">
                  <Select
                    size={'large'}
                    options={discountTypeOptions}
                    onChange={value => {
                      form.setFieldValue(['products', name, 'discount'], '')
                    }}
                  />
                </Form.Item>
              )

              return (
                <Flex
                  style={{
                    marginBottom: '10px',
                    padding: '20px',
                    background: 'rgba(240, 240, 240, 1)',
                    borderRadius: '8px',
                  }}
                  align="baseline"
                >
                  <Flex gap={'middle'} vertical style={{ flex: '1' }}>
                    <Form.Item
                      name={[name, 'type']}
                      label={'Тип продукта'}
                      style={{ width: '230px' }}
                      initialValue={productType}
                    >
                      <Select
                        size={'large'}
                        options={discountTypeProductOptions}
                        value={productType}
                        onChange={changeProductTypeHandler}
                      />
                    </Form.Item>
                    <Flex vertical wrap={'wrap'}>
                      <Form.Item
                        {...restField}
                        name={[name, 'productIds']}
                        label="Продукт"
                        style={{ flex: '1' }}
                        tooltip={'При пустом поле выбираются все продукты в категории'}
                      >
                        <Select
                          showSearch
                          placeholder="Любые"
                          maxTagTextLength={35}
                          mode="multiple"
                          size={'large'}
                          suffixIcon={<SearchOutlined />}
                          filterOption={false}
                          onSearch={searchProductsHandler}
                          onBlur={resetPromotionsProductsHandler}
                          options={productsOptions}
                          notFoundContent={isLoading && <Spin size="small" />}
                        />
                      </Form.Item>
                    </Flex>
                    <Form.Item
                      {...restField}
                      name={[name, 'discount']}
                      label={'Скидка'}
                      style={{ width: '100%' }}
                      rules={[
                        { required: true, message: 'Введите сумму скидки!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const discountType = getFieldValue(['products', name, 'discountType'])
                            if (discountType === 'PERCENT' && value > 100) {
                              return Promise.reject(new Error('Процентная скидка не может превышать 100%!'))
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input
                        addonAfter={selectDiscountAfter}
                        placeholder={'0'}
                        style={{ width: '140px' }}
                        size={'large'}
                        onKeyPress={validateNumericInput}
                      />
                    </Form.Item>
                  </Flex>
                  {fields.length > 1 && (
                    <Button icon={<CloseOutlined />} size="middle" danger onClick={() => remove(name)} />
                  )}
                </Flex>
              )
            })}
            {fields.length < DEFAULT_TABS_COUNT && (
              <Form.Item>
                <Button style={{ marginTop: '8px' }} type="dashed" size={'large'} onClick={() => add()} block>
                  Добавить продукт
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Flex>
  )
}
