import { Button, Dropdown, MenuProps } from 'antd'
import { useMemo } from 'react'

import { ExercisesPeekPaymentDropdownProps } from './exercises-peek-payment-dropdown.types'
import { useExercisesPeekPaymentDropdown } from './exercises-peek-payment-dropdown.hook'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { PaymentType } from '../../../../../api/types/api.types'
import { ExercisesPeekPaymentType } from '../exercises-peek-payment-type/exercises-peek-payment-type.component'

export const ExercisesPeekPaymentDropdown: React.FC<ExercisesPeekPaymentDropdownProps> = props => {
  const { exerciseFormat, paymentType, isDisabled } = props

  const {
    dropdownMenuOpen,
    isLoading,
    paymentTypes,
    setDropdownMenuOpen,
    handleCreateTransaction,
    handleChangePaymentType,
    handleButtonCreateTransaction,
  } = useExercisesPeekPaymentDropdown(props)

  const paymentTypesItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <div
            onClick={() => {
              setDropdownMenuOpen(false)
              handleCreateTransaction()
            }}
          >
            Создать транзакцию
          </div>
        ),
      },
      ...(isDefAndNotEmpty(paymentTypes)
        ? [
            {
              key: '2',
              type: 'group',
              label: 'В наличии у клиента',
              children: paymentTypes.map((option, index) => ({
                key: `1-${index}`,
                label: (
                  <div
                    onClick={() => {
                      setDropdownMenuOpen(false)
                      handleChangePaymentType(option.value as PaymentType)
                    }}
                  >
                    {option.label}
                  </div>
                ),
              })),
            },
          ]
        : []),
    ]
  }, [paymentTypes])

  return (
    <Dropdown
      open={isLoading ? false : dropdownMenuOpen}
      onOpenChange={(nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
          setDropdownMenuOpen(nextOpen)
        }
      }}
      menu={{ items: paymentTypesItems }}
      placement="bottom"
      arrow
      trigger={['click']}
    >
      {paymentType && exerciseFormat === 'GROUP' ? (
        <ExercisesPeekPaymentType
          isLoading={isLoading}
          paymentType={paymentType}
          isDisabled={isDisabled}
          handlePaymentType={handleButtonCreateTransaction}
        />
      ) : (
        <Button size="small" loading={isLoading} disabled={isLoading} onClick={handleButtonCreateTransaction}>
          Оплатить
        </Button>
      )}
    </Dropdown>
  )
}
