import { Modal } from 'antd'
import * as React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { PromotionsApi } from '../../../../api/types/promotions-api.types'
import { NString } from '../../../../types/lang.types'
import { PromotionsSalesCellAction } from '../../promotions-sales-table/promotions-sales-table-action/promotions-sales-cell-action.component'

const { confirm } = Modal

interface PromotionsCodesTableActionsProps {
  id: string
  name: NString
  promoCodesType: PromotionsApi.PromotionsCodesType
  onEdit: (id: string) => void
  onEditPattern: (id: string) => void
  onRemove: (id: string) => void
  onRemovePattern: (id: string, type: PromotionsApi.PromotionsCodesType) => void
}

export const PromotionsCodesTableActions = ({
  id,
  name,
  onEdit,
  onRemove,
  onRemovePattern,
  onEditPattern,
  promoCodesType,
}: PromotionsCodesTableActionsProps) => {
  const onEditHandler = React.useCallback(
    () => (promoCodesType === 'UNIQUE' ? onEdit(id) : onEditPattern(id)),
    [id, onEdit, onEditPattern, promoCodesType]
  )

  const handleRemoveCodes = React.useCallback(
    (id: string, type: PromotionsApi.PromotionsCodesType) => {
      if (type === 'UNIQUE') {
        onRemove(id)
      } else {
        onRemovePattern(id, type)
      }
    },
    [onRemove, onRemovePattern]
  )

  const onRemoveHandler = React.useCallback(() => {
    confirm({
      title: `Подтвердите удаление промокода ${name}`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите удалить промокод ${name}`,
      onOk: () => handleRemoveCodes(id, promoCodesType),
    })
  }, [handleRemoveCodes, id, name, promoCodesType])

  return <PromotionsSalesCellAction onEdit={onEditHandler} onRemove={onRemoveHandler} />
}
