import { Rule } from 'antd/es/form'
import dayjs from 'dayjs'

export function genExercisesPeekOverviewTimeFromValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите время начала' }]
}

export function genExercisesPeekOverviewTimeToValidationRules(): Rule {
  return {
    validator(_, value) {
      if (!value || value.length !== 2) {
        return Promise.reject('Выберите начальное и конечное время')
      }
      const [start, end] = value.map((time: string) => dayjs(time, 'HH:mm').valueOf())
      if (start >= end) {
        return Promise.reject('Заполните корректно время')
      }

      return Promise.resolve()
    },
  }
}

export function genExercisesPeekOverviewMaxClientsCountValidationRules(clientsCount: number): Rule[] {
  return [
    {
      validator(_, value) {
        if (value > 10_000) {
          return Promise.reject('Вместимость не может быть больше 10 000')
        }
        if (value < 0) {
          return Promise.reject('Вместимость не может быть меньше 0')
        }
        if (value < clientsCount) {
          return Promise.reject(`Вместимость не может быть меньше ${clientsCount}`)
        }

        return Promise.resolve()
      },
    },
  ]
}

export function genExercisesPeekOverviewStudiosRoomsValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите пространство' }]
}

export function genExercisesPeekOverviewSubServiceValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите направление' }]
}
