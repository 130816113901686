import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { schedulePagePeekExerciseActions } from '../../../store/pages/schedule-page/schedule-page-peek-exercise/schedule-page-peek-exercise.slice'

export function useSchedulePageModalQrReader(exerciseId: string) {
  const dispatch = useDispatch()

  const onCancelHandler = useCallback((): void => {
    dispatch(schedulePagePeekExerciseActions.fetchExercise(exerciseId))
    dispatch(
      schedulePagePeekExerciseActions.fetchBookings({
        exerciseId,
      })
    )
    dispatch(modalActions.closeLast())
  }, [dispatch, exerciseId])

  return {
    onCancelHandler,
  }
}
