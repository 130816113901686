import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { schedulePageModalCommentsActions } from '../../../store/pages/schedule-page/schedule-page-modal-comments/schedule-page-modal-comments.slice'
import {
  genSchedulePageModalBookingComments,
  genSchedulePageModalCommentsIsLoading,
} from '../../../store/pages/schedule-page/schedule-page-modal-comments/schedule-page-modal-comments.selectors'

export function useSchedulePageModalComments(exerciseId: string, bookingId: string) {
  const dispatch = useDispatch()

  const loading = useSelector(genSchedulePageModalCommentsIsLoading)
  const comments = useSelector(genSchedulePageModalBookingComments)

  React.useEffect((): void => {
    dispatch(schedulePageModalCommentsActions.fetchComments({ exerciseId, bookingId }))
  }, [bookingId, dispatch, exerciseId])

  const onSaveHandler = React.useCallback(
    (comment: string): void => {
      dispatch(
        schedulePageModalCommentsActions.createComment({
          exerciseId,
          bookingId,
          comment,
        })
      )
    },
    [bookingId, dispatch, exerciseId]
  )

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    loading,
    comments,

    onSaveHandler,
    onCancelHandler,
  }
}
