import { combineReducers } from '@reduxjs/toolkit'
import { promotionsPageCodesEditReducer } from '@store/pages/promotions-page/promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.slice'

import { promotionsPageCodesCreateReducer } from './promotions-page-codes/promotions-page-codes-create/promotions-page-codes-create.slice'
import { promotionsPageSalesEditReducer } from './promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.slice'
import { promotionsPageSalesCreateReducer } from './promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.slice'

export const promotionsPageReducer = combineReducers({
  promotionsPageCodesEdit: promotionsPageCodesEditReducer,
  promotionsPageCodesCreate: promotionsPageCodesCreateReducer,
  promotionsPageCreateSales: promotionsPageSalesCreateReducer,
  promotionsPageEditSales: promotionsPageSalesEditReducer,
})
