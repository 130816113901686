import * as React from 'react'

import { useSchedulePageModalBooking } from './schedule-page-modal-booking.hook'
import { SchedulePageModalBookingProps } from './schedule-page-modal-booking.types'
import { ExercisesBookingModal } from '../../../components/exercises/exercises-booking-modal/exercises-booking-modal.component'

export const SchedulePageModalBooking: React.FC<SchedulePageModalBookingProps> = props => {
  const { scheduleId } = props

  const { form, loading, placesOptions, onSaveHandler, onCancelHandler } = useSchedulePageModalBooking(scheduleId)

  return (
    <ExercisesBookingModal
      form={form}
      loading={loading}
      placesOptions={placesOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
      exerciseId={scheduleId}
    />
  )
}
