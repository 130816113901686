import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { SchedulePageModalWaitingProps } from './schedule-page-modal-waiting.types'
import {
  getSchedulePageModalWaitingIsLoading,
  getSchedulePageModalWaitingList,
} from '../../../store/pages/schedule-page/schedule-page-modal-waiting/schedule-page-modal-waiting.selectors'
import { schedulePageModalWaitingActions } from '../../../store/pages/schedule-page/schedule-page-modal-waiting/schedule-page-modal-waiting.slice'

export function useSchedulePageModalWaiting(props: SchedulePageModalWaitingProps) {
  const { exerciseId } = props

  const dispatch = useDispatch()

  const waitingList = useSelector(getSchedulePageModalWaitingList)
  const loading = useSelector(getSchedulePageModalWaitingIsLoading)

  React.useEffect((): void => {
    dispatch(
      schedulePageModalWaitingActions.fetchWaitingList({
        exerciseId,
      })
    )
  }, [dispatch, exerciseId])

  React.useEffect(() => {
    return () => {
      dispatch(schedulePageModalWaitingActions.reset())
    }
  }, [dispatch])

  const onSearchHandler = React.useCallback(
    (value: string): void => {
      dispatch(
        schedulePageModalWaitingActions.fetchWaitingList({
          exerciseId,
          search: value,
        })
      )
    },
    [dispatch, exerciseId]
  )

  const onBookHandler = React.useCallback(
    (phone: string, name: string): void => {
      dispatch(
        schedulePageModalWaitingActions.bookClient({
          exerciseId,
          phone,
          name,
        })
      )
    },
    [dispatch, exerciseId]
  )

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    waitingList,
    loading,

    onSearchHandler,
    onBookHandler,
    onCancelHandler,
  }
}
