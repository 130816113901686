import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { NNumber, Nullable } from '../../../../types/lang.types'
import {
  SchedulePagePeekExerciseFetchDictionariesSuccessPayload,
  SchedulePagePeekExerciseFetchAuditPayload,
  SchedulePagePeekExerciseFetchPeekDataSuccessPayload,
  SchedulePagePeekExerciseCancelBookingPayload,
  SchedulePagePeekExerciseChangeBookingVisitingConfirmationPayload,
  SchedulePagePeekExerciseEditExercisePayload,
  SchedulePagePeekExerciseChangeBookingPaymentTypePayload,
  SchedulePagePeekExerciseFetchDictionariesPayload,
  SchedulePagePeekExerciseFetchTrainersPayload,
  SchedulePagePeekExerciseFetchBookingsPayload,
} from './schedule-page-peek-exercise.types'
import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { ExercisesTypesApi } from '../../../../api/types/exercises-types-api.types'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'

interface SchedulePagePeekExerciseState {
  exercise: Nullable<ExercisesApi.Exercise>
  exerciseBookings: Nullable<Pagination<ExercisesApi.ExerciseBooking>>
  exerciseLinked: Nullable<ExercisesApi.ExerciseLinked[]>
  clientsInWaitingList: NNumber
  scheduleAudit: Nullable<Pagination<AuditApi.Audit>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  exercisesType: Nullable<ExercisesTypesApi.ExerciseType>
  isExerciseLoading: boolean
  isExerciseLoaded: boolean
  isBookingsLoading: boolean
  isScheduleAuditLoading: boolean
  isTrainersLoading: boolean
  error: Nullable<Error>
}

const initialState: SchedulePagePeekExerciseState = {
  exercise: null,
  exerciseBookings: null,
  exerciseLinked: null,
  clientsInWaitingList: null,
  scheduleAudit: null,
  trainers: null,
  studios: null,
  exercisesType: null,
  isExerciseLoading: false,
  isExerciseLoaded: false,
  isBookingsLoading: false,
  isScheduleAuditLoading: false,
  isTrainersLoading: false,
  error: null,
}

export const { actions: schedulePagePeekExerciseActions, reducer: schedulePagePeekExerciseReducer } = createSlice({
  name: '@@schedule-page-peek-exercise',
  initialState,
  reducers: {
    fetchPeekData: (state: Draft<SchedulePagePeekExerciseState>, _: PayloadAction<string>) => {
      state.isExerciseLoading = true
    },
    fetchPeekDataSuccess: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<SchedulePagePeekExerciseFetchPeekDataSuccessPayload>
    ) => {
      state.exercise = action.payload.exercise
      state.exerciseLinked = action.payload.exerciseLinked
      state.exerciseBookings = action.payload.exerciseBookings
      state.clientsInWaitingList = action.payload.clientsInWaitingList
      state.isExerciseLoaded = true
    },
    fetchPeekDataError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.isExerciseLoaded = true
      state.error = action.payload
    },
    fetchExercise: (state: Draft<SchedulePagePeekExerciseState>, _: PayloadAction<string>) => {
      state.isExerciseLoading = true
    },
    fetchExerciseSuccess: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<ExercisesApi.Exercise>
    ) => {
      state.exercise = action.payload
      state.isExerciseLoading = false
    },
    fetchExerciseError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isExerciseLoading = false
    },
    fetchBookings: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseFetchBookingsPayload>
    ) => {
      state.isBookingsLoading = true
    },
    fetchBookingsSuccess: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<Pagination<ExercisesApi.ExerciseBooking>>
    ) => {
      state.exerciseBookings = action.payload
      state.isBookingsLoading = false
    },
    fetchBookingsError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isBookingsLoading = false
    },
    fetchClientsInWaitingList: (state: Draft<SchedulePagePeekExerciseState>, _action: PayloadAction<string>) => {
      state.isBookingsLoading = true
    },
    fetchClientsInWaitingListSuccess: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<NNumber>) => {
      state.clientsInWaitingList = action.payload
      state.isBookingsLoading = false
    },
    fetchClientsInWaitingListError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isBookingsLoading = false
    },
    fetchExercisesLinked: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<string>) => {
      state.isExerciseLoading = true
    },
    fetchExercisesLinkedSuccess: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<any>) => {
      state.exerciseLinked = action.payload
      state.isExerciseLoading = false
    },
    fetchExercisesLinkedError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isExerciseLoading = false
    },
    fetchDictionaries: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseFetchDictionariesPayload>
    ): void => {
      state.isExerciseLoading = true
    },
    fetchDictionariesSuccess: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<SchedulePagePeekExerciseFetchDictionariesSuccessPayload>
    ): void => {
      state.studios = action.payload.studios
      state.trainers = action.payload.trainers
      state.exercisesType = action.payload.exercisesType
      state.isExerciseLoading = false
    },
    fetchDictionariesError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>): void => {
      state.error = action.payload
      state.isExerciseLoading = false
    },
    fetchTrainers: (
      state: Draft<SchedulePagePeekExerciseState>,
      _action: PayloadAction<SchedulePagePeekExerciseFetchTrainersPayload>
    ) => {
      state.isTrainersLoading = true
    },
    fetchTrainersSuccess: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<Nullable<Pagination<TrainersApi.Trainer>>>
    ) => {
      state.trainers = action.payload
      state.isTrainersLoading = false
    },
    fetchTrainersError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isTrainersLoading = false
    },
    fetchAudit: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseFetchAuditPayload>
    ): void => {
      state.isScheduleAuditLoading = true
    },
    fetchAuditSuccess: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<Pagination<AuditApi.Audit>>
    ): void => {
      state.scheduleAudit = action.payload
      state.isScheduleAuditLoading = false
    },
    fetchAuditError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>): void => {
      state.error = action.payload
      state.isScheduleAuditLoading = false
    },
    changeBookingVisitingConfirmation: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseChangeBookingVisitingConfirmationPayload>
    ) => {
      state.isBookingsLoading = true
    },
    changeBookingVisitingConfirmationSuccess: (state: Draft<SchedulePagePeekExerciseState>) => {
      state.isBookingsLoading = false
    },
    changeBookingVisitingConfirmationError: (
      state: Draft<SchedulePagePeekExerciseState>,
      action: PayloadAction<Error>
    ) => {
      state.isBookingsLoading = false
      state.error = action.payload
    },
    changeBookingPaymentType: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseChangeBookingPaymentTypePayload>
    ) => {
      state.isBookingsLoading = true
    },
    changeBookingPaymentTypeSuccess: (state: Draft<SchedulePagePeekExerciseState>) => {
      state.isBookingsLoading = false
    },
    changeBookingPaymentTypeError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.isBookingsLoading = false
      state.error = action.payload
    },
    cancelBooking: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseCancelBookingPayload>
    ) => {
      state.isExerciseLoading = true
    },
    cancelBookingSuccess: (state: Draft<SchedulePagePeekExerciseState>) => {
      state.isExerciseLoading = false
    },
    cancelBookingError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.isExerciseLoading = false
      state.error = action.payload
    },
    editExercise: (
      state: Draft<SchedulePagePeekExerciseState>,
      _: PayloadAction<SchedulePagePeekExerciseEditExercisePayload>
    ) => {
      state.isExerciseLoading = true
    },
    editExerciseSuccess: (state: Draft<SchedulePagePeekExerciseState>) => {
      state.isExerciseLoading = false
    },
    editExerciseError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.isExerciseLoading = false
      state.error = action.payload
    },
    removeExercise: (state: Draft<SchedulePagePeekExerciseState>, _: PayloadAction<string>) => {
      state.isExerciseLoading = true
    },
    removeExerciseSuccess: (state: Draft<SchedulePagePeekExerciseState>) => {
      state.isExerciseLoading = false
    },
    removeExerciseError: (state: Draft<SchedulePagePeekExerciseState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
