import * as React from 'react'

import { SchedulePageModalCommentsProps } from './schedule-page-modal-comments.types'
import { useSchedulePageModalComments } from './schedule-page-modal-comments.hook'
import { ExercisesCommentsModal } from '../../../components/exercises/exercises-comments-modal/exercises-comments-modal.component'

export const SchedulePageModalComments: React.FC<SchedulePageModalCommentsProps> = props => {
  const { exerciseId, bookingId } = props
  const { loading, comments, onSaveHandler, onCancelHandler } = useSchedulePageModalComments(exerciseId, bookingId)

  return (
    <ExercisesCommentsModal onSave={onSaveHandler} onCancel={onCancelHandler} loading={loading} comments={comments} />
  )
}
