import * as React from 'react'
import { FormInstance } from 'antd'

import { ExercisesBookingFormValues } from './exercises-booking-modal-form.types'
import {
  genExercisesBookingFormPaymentTypeValidationRules,
  genExercisesBookingFormClientDTO,
} from './exercises-booking-modal-form.utils'
import { Nullable } from '../../../../types/lang.types'
import { ClientsApi } from '../../../../api/types/clients-api.types'

interface Props {
  form: FormInstance<ExercisesBookingFormValues>
  onSave: (formValues: ExercisesBookingFormValues, clientDTO: Nullable<ClientsApi.ClientDTO>) => void
}

export function useExercisesBookingModalForm(props: Props) {
  const { form, onSave } = props

  const [clientCurrentData, setClientCurrentData] = React.useState<Nullable<ClientsApi.Client>>(null)

  const paymentTypeValidationRules = React.useMemo(genExercisesBookingFormPaymentTypeValidationRules, [])

  const onFinishHandler = React.useCallback((): void => {
    const formValues = form.getFieldsValue()
    const clientDTO: Nullable<ClientsApi.ClientDTO> = genExercisesBookingFormClientDTO(formValues, clientCurrentData)

    onSave(formValues, clientDTO)
  }, [clientCurrentData, form, onSave])

  return {
    paymentTypeValidationRules,
    setClientCurrentData,
    onFinishHandler,
  }
}
