import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { Nullable } from '../../../types/lang.types'
import { ClientsApi } from '../../../api/types/clients-api.types'
import {
  genSchedulePageModalBookingExercisesSpots,
  genSchedulePageModalBookingIsLoading,
} from '../../../store/pages/schedule-page/schedule-page-modal-booking/schedule-page-modal-booking.selectors'
import { schedulePageModalBookingActions } from '../../../store/pages/schedule-page/schedule-page-modal-booking/schedule-page-modal-booking.slice'
import { ExercisesBookingFormValues } from '../../../components/exercises/exercises-booking-modal/exercises-booking-modal-form/exercises-booking-modal-form.types'

export function useSchedulePageModalBooking(scheduleId: string, phone?: string) {
  const [form] = Form.useForm<ExercisesBookingFormValues>()

  const dispatch = useDispatch()

  const loading = useSelector(genSchedulePageModalBookingIsLoading)
  const placesOptions = useSelector(genSchedulePageModalBookingExercisesSpots)

  React.useEffect((): void => {
    dispatch(schedulePageModalBookingActions.fetchExercisesSpots(scheduleId))
  }, [dispatch, scheduleId])

  React.useEffect(() => {
    return () => {
      dispatch(schedulePageModalBookingActions.reset())
    }
  }, [dispatch])

  const onSaveHandler = React.useCallback(
    (exercisesBookingFormValues: ExercisesBookingFormValues, clientDTO: Nullable<ClientsApi.ClientDTO>): void => {
      dispatch(
        schedulePageModalBookingActions.createBooking({
          exerciseId: scheduleId,
          exercisesBookingFormValues,
          clientDTO,
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    form,
    loading,

    placesOptions,

    onSaveHandler,
    onCancelHandler,
  }
}
