import dayjs, { Dayjs } from 'dayjs'

import { formatDayjsToDate, genDefaultDayjsFormat } from '../format/date.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { ContractsApi } from '../api/types/contracts-api.types'

export function genCounterpartiesFormValues(counterparty: Nullable<ContractsApi.CounterpartiesDTO>): Nullable<any> {
  if (isDef(counterparty)) {
    return {
      companyName: counterparty.companyName,
      displayName: counterparty.companyName,
      ogrn: counterparty.ogrn,
      inn: counterparty.inn,
      kpp: counterparty.kpp,
      type: counterparty.type,
      passportIssueDate: isDef(counterparty.passportIssueDate)
        ? dayjs(counterparty.passportIssueDate, genDefaultDayjsFormat())
        : undefined,
      passportIssuer: counterparty.passportIssuer || null,
      passportNumber: counterparty.passportNumber || null,
      passportSeries: counterparty.passportSeries || null,
      birthDate: isDef(counterparty.birthDate) ? dayjs(counterparty.birthDate, genDefaultDayjsFormat()) : undefined,
      snoType: counterparty.snoType,
      address: counterparty.address,
      directorName: counterparty.directorName,
      phoneNumber: counterparty.phoneNumber,
      email: counterparty.email,
      bankName: counterparty.bankName,
      bic: counterparty.bic,
      bankAccount: counterparty.bankAccount,
      correspondentAccount: counterparty.correspondentAccount,
    }
  }

  return null
}

export function genCounterpartiesContractFormValues(contract: Nullable<ContractsApi.Contracts>): Nullable<any> {
  if (isDef(contract)) {
    return {
      id: contract.id,
      name: contract.name,
      link: contract.link,
      number: contract.number,
      validSince: isDef(contract.validSince) ? dayjs(contract.validSince, genDefaultDayjsFormat()) : undefined,
      validUntil: isDef(contract.validUntil) ? dayjs(contract.validUntil, genDefaultDayjsFormat()) : undefined,
      type: contract.type,
      paymentModel: contract.paymentModel.type,
      maxTotalAmount: contract.maxTotalAmount,
      months: 'months' in contract.paymentModel ? contract.paymentModel.months : '',
      day: 'day' in contract.paymentModel ? contract.paymentModel.day : '',
      value: 'value' in contract.paymentModel ? contract.paymentModel.value : '',
      maxPeriodAmount: contract.maxPeriodAmount,
      currentTurnover: contract.currentTurnover,
      lifetimeTurnover: contract.lifetimeTurnover,
      disablePaymentsOnMaxAmount: contract.disablePaymentsOnMaxAmount,
      disablePaymentsAfterPaymentDue: contract.disablePaymentsAfterPaymentDue,
      counterparty: contract.counterparty,
      managerId: contract.manager.id,
      status: contract.status,
      awaitsPaymentThreshold: contract.awaitsPaymentThreshold,
      notifyDaysBeforeExpire: contract.notifyDaysBeforeExpire,
      aboutToExpire: contract.aboutToExpire,
      deleted: contract.deleted,
    }
  }

  return null
}

export function genCounterpartiesContractUpdateFormValues(
  contract: Nullable<ContractsApi.ContractsDTO<Dayjs>>,
  link: string
): Nullable<any> {
  if (isDef(contract)) {
    return {
      name: contract.name,
      link,
      number: contract.number,
      validSince: isDef(contract.validSince) ? formatDayjsToDate(contract.validSince) : null,
      validUntil: isDef(contract.validUntil) ? formatDayjsToDate(contract.validUntil) : null,
      type: contract.type,
      paymentModel: contract.paymentModel,
      maxTotalAmount: contract.maxTotalAmount,
      maxPeriodAmount: contract.maxPeriodAmount,
      disablePaymentsOnMaxAmount: contract.disablePaymentsOnMaxAmount,
      disablePaymentsAfterPaymentDue: contract.disablePaymentsAfterPaymentDue,
      managerId: contract.managerId,
      awaitsPaymentThreshold: contract.awaitsPaymentThreshold,
      notifyDaysBeforeExpire: contract.notifyDaysBeforeExpire,
    }
  }

  return null
}

export function genCounterpartyTotalTurnover(counterparty: Nullable<ContractsApi.Counterparties>) {
  return Number(counterparty?.activeContracts?.reduce((sum, contract) => sum + (contract.lifetimeTurnover || 0), 0))
}

export function genCounterpartyTotalBalance(
  counterparty: Nullable<ContractsApi.Counterparties>,
  totalTurnover: number = 0
) {
  const totalAmount =
    counterparty?.activeContracts?.reduce((sum, contract) => sum + (contract.maxTotalAmount || 0), 0) || 0
  return totalAmount - totalTurnover
}

export function genCounterpartyByIdDebitAndCredit(counterparty: Nullable<ContractsApi.Counterparties>): {
  debit: number
  credit: number
} {
  if (!isDefAndNotEmpty(counterparty?.activeContracts)) {
    return { debit: 0, credit: 0 }
  }

  let debit = 0
  let credit = 0

  counterparty?.activeContracts.forEach(contract => {
    if (['ACTIVE', 'AWAITS_PAYMENT'].includes(contract.status)) {
      const amount = contract.maxPeriodAmount || 0
      const turnover = contract.currentTurnover || 0
      const creditBalance = turnover
      const debitBalance = amount - turnover

      if (contract.type === 'POSTPAY') {
        credit += creditBalance
      } else if (contract.type === 'PREPAY') {
        debit += debitBalance
      }
    }
  })

  return {
    debit,
    credit,
  }
}
