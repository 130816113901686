import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback } from 'react'
import { Dayjs } from 'dayjs'

import {
  getCounterpartyContractsInternal,
  getCounterpartyContractsIsLoading,
} from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-create/contracts-info-page-contracts.selectors'
import { contractsPageActions } from '../../../../../store/pages/contracts-page/contracts-page.slice'
import { contractsInfoPageContractsCreateActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-create/contracts-info-page-contracts.slice'
import { AppModal } from '../../../../../types/modal.types'
import { getContractPaymentMethod } from '../../../../../mapping/contracts.mapping'
import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { getManagersCardsList } from '../../../../../store/pages/contracts-page/contracts-page.selectors'
import { contractsInfoPageContractsEditActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts-edit/contracts-info-page-contracts-edit.slice'

export const useContractsInfoContractList = (counterpartyId: string) => {
  const dispatch = useDispatch()
  const contractsList = useSelector(getCounterpartyContractsInternal)
  const isLoading = useSelector(getCounterpartyContractsIsLoading)
  const activeContractsList = contractsList?.filter(contract => contract.status !== 'EXPIRED')
  const expiredContractsList = contractsList?.filter(contract => contract.status === 'EXPIRED')

  const onChangeManagerHandler = useCallback(
    (managerId: ContractsApi.ManagerId, contractId: string) => {
      if (contractsList) {
        dispatch(
          contractsPageActions.updateManager({
            contractId,
            managerId,
          })
        )
      }
    },
    [contractsList, dispatch]
  )

  const onEditContracts = React.useCallback(
    (id: string, data: ContractsApi.ContractsDTO<Dayjs>) => {
      const paymentModel = getContractPaymentMethod(data)
      dispatch(
        contractsInfoPageContractsEditActions.updateContract({ id, counterpartyId, data: { ...data, paymentModel } })
      )
    },
    [counterpartyId, dispatch]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const handleEditContract = (id: string) => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_CONTRACT_MODAL_EDIT,
        props: {
          handleOk: onEditContracts,
          handleCancel: onCloseHandler,
          contractId: id,
        },
      })
    )
  }

  React.useEffect(() => {
    dispatch(
      contractsInfoPageContractsCreateActions.fetchAllContract({
        counterpartyId,
        size: 300,
        showExpired: true,
        showNotStarted: true,
      })
    )
  }, [dispatch, counterpartyId])

  return {
    activeContractsList,
    expiredContractsList,
    isLoading,
    onChangeManagerHandler,
    handleEditContract,
  }
}
