import * as React from 'react'
import { Form, Select } from 'antd'

import { ExercisesBookFromWaitingModalFormProps } from './exercises-book-from-waiting-modal-form.types'
import { useExercisesBookFromWaitingModalForm } from './exercises-book-from-waiting-modal-form.hook'

export const ExercisesBookFromWaitingModalForm: React.FC<ExercisesBookFromWaitingModalFormProps> = props => {
  const { form, loading, onFieldsChange } = props
  const { placesOptions, paymentTypesOptions } = props

  const { placeValidationRules, paymentTypeValidationRules } = useExercisesBookFromWaitingModalForm()

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFieldsChange={onFieldsChange}
    >
      <Form.Item label="Место" name="place" rules={placeValidationRules}>
        <Select placeholder="Место" options={placesOptions} disabled={loading} loading={loading} />
      </Form.Item>

      <Form.Item label="Способ оплаты" name="paymentType" rules={paymentTypeValidationRules}>
        <Select placeholder="Способ оплаты" options={paymentTypesOptions} disabled={loading} loading={loading} />
      </Form.Item>
    </Form>
  )
}
