import { ChangeEvent, useEffect, useState } from 'react'

import { NString } from '../../../../../types/lang.types'

export interface Props {
  exerciseComment: NString
  onEditCommentHandler: (comment: string) => void
}

export function useExercisesPeekHeader(props: Props) {
  const { exerciseComment, onEditCommentHandler } = props

  const [comment, setComment] = useState<string>()
  const [isEditing, setIsEditing] = useState(false)

  const handleEditCommentClick = () => {
    setIsEditing(true)
  }

  const handleInputCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value)
  }

  const handleAddCommentClick = () => {
    setIsEditing(true)
  }

  const handleSaveCommentClick = () => {
    setIsEditing(false)
    onEditCommentHandler(comment || '')
  }

  useEffect(() => {
    if (exerciseComment) {
      setComment(exerciseComment)
    }
  }, [exerciseComment])

  return {
    comment,
    isEditing,
    handleEditCommentClick,
    handleAddCommentClick,
    handleInputCommentChange,
    handleSaveCommentClick,
  }
}
