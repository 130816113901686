import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { EmployeesApi } from '../../../api/types/employees-api.types'

export interface SelectManagersState {
  managers: Nullable<Pagination<EmployeesApi.Employee>>
  isLoading: boolean
  isLoaded: boolean
  isFetching: boolean
  error: Nullable<Error>
}

const initialState: SelectManagersState = {
  managers: null,
  isLoading: false,
  isLoaded: false,
  isFetching: false,
  error: null,
}

export const { actions: selectManagersActions, reducer: selectManagersReducer } = createSlice({
  name: '@@select-managers',
  initialState,
  reducers: {
    fetchAllManagers: (state: Draft<SelectManagersState>) => {
      state.isLoading = true
      state.isFetching = true
    },
    fetchAllManagersSuccess: (
      state: Draft<SelectManagersState>,
      action: PayloadAction<Nullable<Pagination<EmployeesApi.Employee>>>
    ) => {
      state.managers = action.payload
      state.isLoading = false
      state.isLoaded = true
      state.isFetching = false
    },
    fetchAllManagersError: (state: Draft<SelectManagersState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
      state.isFetching = false
    },
    reset: () => initialState,
  },
})
