import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { promotionsPageSalesEditActions } from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.slice'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { getPromotionsSalesIsCreating } from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.selectors'

export const usePromotionsSalesCreate = () => {
  const dispatch = useDispatch()
  const isCreating = useSelector(getPromotionsSalesIsCreating)

  const onSearchHandler = React.useCallback(
    (name: string, type: PromotionsApi.PromotionProductType): void => {
      dispatch(
        promotionsPageSalesEditActions.searchPromotionsProducts({
          name,
          type,
        })
      )
    },
    [dispatch]
  )

  React.useEffect(() => {
    dispatch(promotionsPageSalesEditActions.fetchPromotionsModalData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesEditActions.reset())
    }
  }, [dispatch])

  return {
    isCreating,
    onSearchHandler,
  }
}
