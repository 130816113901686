import * as React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ExpandableConfig } from 'rc-table/lib/interface'

import {
  genClientsSubscriptionsTableColumns,
  genClientSubscriptionsTableExpandedRowRender,
  genClientSubscriptionsTableRowExpandable,
} from './clients-subscriptions-table.utils'
import { ClientsSubscriptionsTableActionsEvents } from './clients-subscriptions-table-actions/clients-subscriptions-table-actions.types'
import { ClientsSubscriptionsTableDataItem } from './clients-subscriptions-table.types'
import './clients-subscriptions-table.styles.less'
import { CustomCard } from '../../custom-card/custom-card.component'

interface Props extends ClientsSubscriptionsTableActionsEvents {
  className?: string
  data: ClientsSubscriptionsTableDataItem[]
  pagination?: TablePaginationConfig
  loading?: boolean
  clientBookingReadPermission: boolean
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
  onExpirationDate: (expirationDate: string, subscriptionId: string) => void
  onBooking: (studioId: string, scheduleId: string) => void
}

export const ClientsSubscriptionsTable: React.FC<Props> = props => {
  const { data, pagination, loading, clientBookingReadPermission } = props
  const { onChangePage, onChangePageSize } = props
  const { onPause, onResume, onRefund } = props
  const { onExpirationDate } = props
  const { onBarcode } = props
  const { onBooking } = props

  const columns: ColumnsType<ClientsSubscriptionsTableDataItem> = React.useMemo(
    () => genClientsSubscriptionsTableColumns({ onBarcode, onPause, onResume, onRefund, onExpirationDate, onBooking }),
    [onBarcode, onPause, onResume, onRefund, onExpirationDate, onBooking]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  const expandable: ExpandableConfig<ClientsSubscriptionsTableDataItem> = React.useMemo(
    () => ({
      expandedRowRender: (record, index, indent, expanded) =>
        genClientSubscriptionsTableExpandedRowRender(
          record,
          index,
          indent,
          expanded,
          clientBookingReadPermission,
          onBooking
        ),
      rowExpandable: genClientSubscriptionsTableRowExpandable,
    }),
    [clientBookingReadPermission, onBooking]
  )

  const scroll = React.useMemo(() => ({ x: 1500 }), [])

  return (
    <CustomCard>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={paginationConfig}
        loading={loading}
        expandable={expandable}
        scroll={scroll}
        className="clients-subscriptions-table"
      />
    </CustomCard>
  )
}
