import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/es/form/Form'
import { useEffect } from 'react'
import * as React from 'react'
import { Dayjs } from 'dayjs'

import { promotionsPageCodesEditActions } from '../../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.slice'
import {
  getCodesEditSelectedFranchises,
  getCodesPatternEditSelectedFranchises,
  getPromotionsCodeIsEditing,
  getPromotionsCodesPatternFormValue,
} from '../../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-edit/promotions-page-codes-edit.selectors'
import {
  getPromotionsCodesFormValue,
  getPromotionsCodesProductsList,
} from '../../../../../store/pages/promotions-page/promotions-page-codes/promotions-page-codes-create/promotions-page-codes-create.selectors'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { isDef } from '../../../../../types/lang.types'
import { promotionsPageSalesEditActions } from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.slice'
import { getPromotionsSalesIsModalLoading } from '../../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.selectors'

export const useCodeEdit = (id: string, isPattern: boolean = false) => {
  const dispatch = useDispatch()
  const codesForm = useSelector(getPromotionsCodesFormValue)
  const isEditing = useSelector(getPromotionsCodeIsEditing)
  const isModalLoading = useSelector(getPromotionsSalesIsModalLoading)
  const promotionsEditSelectedFranchises = useSelector(
    isPattern ? getCodesPatternEditSelectedFranchises : getCodesEditSelectedFranchises
  )
  const codesFormPattern = useSelector(getPromotionsCodesPatternFormValue)
  const productsCodesList = useSelector(getPromotionsCodesProductsList)
  const [form] = useForm<PromotionsApi.Code<Dayjs>>()

  const onSearchHandler = React.useCallback(
    (name: string, type: PromotionsApi.PromotionProductType): void => {
      dispatch(
        promotionsPageSalesEditActions.searchPromotionsProducts({
          name,
          type,
        })
      )
    },
    [dispatch]
  )

  React.useEffect(() => {
    dispatch(promotionsPageSalesEditActions.fetchPromotionsModalData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesEditActions.reset())
      dispatch(promotionsPageCodesEditActions.reset())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      isPattern
        ? promotionsPageCodesEditActions.fetchPromotionsCodesPatternById({ id })
        : promotionsPageCodesEditActions.fetchPromotionsCodesById({ id })
    )
  }, [dispatch, id, isPattern])

  React.useEffect((): void => {
    if (isDef(codesForm)) {
      // @ts-ignore
      form.setFieldsValue(codesForm)
    } else if (isDef(codesFormPattern)) {
      // @ts-ignore
      form.setFieldsValue(codesFormPattern)
    }
  }, [form, codesForm, codesFormPattern])

  return {
    form,
    isEditing,
    isModalLoading,
    productsCodesList,
    onSearchHandler,
    promotionsEditSelectedFranchises,
  }
}
