import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Dayjs } from 'dayjs'

import { PromotionsApi } from '../../../../api/types/promotions-api.types'
import { modalActions } from '../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../types/modal.types'
import { genPromotionsPagePath } from '../../../../format/path.format'
import { PromotionsPageSection } from '../../promotions-page.types'
import { genPaginationConfig } from '../../../../utils/pagination.utils'
import { usePromotionsPageParams } from '../../promotions-page-hooks/promotions-page.hook'
import {
  getPromotionsSaleForTableRow,
  getPromotionsSalesIsLoading,
  getPromotionsSalesTotalElements,
} from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.selectors'
import { promotionsPageSalesEditActions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.slice'
import { promotionsPageSalesCreateActions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.slice'
import { getPromotionsSalesIsModalLoading } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.selectors'

export const useSalesTabs = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const { page, size } = usePromotionsPageParams()

  const isLoading = useSelector(getPromotionsSalesIsLoading)
  const isLoadingEdit = useSelector(getPromotionsSalesIsModalLoading)
  const sales = useSelector(getPromotionsSaleForTableRow)
  const totalElements = useSelector(getPromotionsSalesTotalElements)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onEditPromotionsSalesHandler = React.useCallback(
    (id: string, value: PromotionsApi.PromotionDTO<Dayjs>): void => {
      dispatch(promotionsPageSalesEditActions.updatePromotionsSales({ id, data: value }))
    },
    [dispatch]
  )

  const onEditHandler = React.useCallback(
    (id: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.PROMOTIONS_PAGE_SALES_MODAL_EDIT,
          props: {
            id: id,
            handleOk: onEditPromotionsSalesHandler,
            handleCancel: onCloseHandler,
          },
        })
      )
    },
    [dispatch, isLoadingEdit, onCloseHandler, onEditPromotionsSalesHandler]
  )

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      dispatch(promotionsPageSalesCreateActions.removeSales({ id }))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.SALES }, { page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genPromotionsPagePath({ section: PromotionsPageSection.SALES }, { page, size: pageSize }))
    },
    [page, push]
  )

  const onVisiblePastSalesHandler = React.useCallback(
    (value: boolean) => {
      dispatch(promotionsPageSalesCreateActions.fetchAllSales({ page, size, isShowPast: !value }))
    },
    [dispatch, page, size]
  )

  React.useEffect(() => {
    dispatch(promotionsPageSalesCreateActions.fetchAllSales({ page, size }))
  }, [dispatch, page, size])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesCreateActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    sales,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
    onEditHandler,
    onRemoveHandler,
    onVisiblePastSalesHandler,
  }
}
