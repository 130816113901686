import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import {
  getPromotionsSalesIsCreating,
  getPromotionsSalesProductsOptions,
} from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-create/promotions-page-sales-create.selectors'
import { promotionsPageSalesEditActions } from '../../../../store/pages/promotions-page/promotions-page-sales/promotions-page-sales-edit/promotions-page-sales-edit.slice'
import { PromotionsApi } from '../../../../api/types/promotions-api.types'
import { Nullable } from '../../../../types/lang.types'

export const usePromotionsFormProducts = (
  productsList: PromotionsApi.PromotionsProduct[] | Nullable<PromotionsApi.PromotionsProduct[]> = []
) => {
  const dispatch = useDispatch()
  const productsOptions = useSelector(getPromotionsSalesProductsOptions)
  const isLoading = useSelector(getPromotionsSalesIsCreating)

  const resetPromotionsProductsHandler = React.useCallback(() => {
    dispatch(promotionsPageSalesEditActions.resetPromotionsProducts())
  }, [dispatch])

  const discountTypeOptions = [
    {
      value: 'PERCENT',
      label: '%',
    },
    {
      value: 'FIXED',
      label: '₽',
    },
  ]

  const discountTypeProductOptions = [
    {
      value: 'ONE_TIME_SERVICE',
      label: 'Разовая услуга',
    },
    {
      value: 'PERSONAL_SERVICE',
      label: 'Персональная услуга',
    },
    {
      value: 'SUBSCRIPTION',
      label: 'Абонементы',
    },
    {
      value: 'GOODS',
      label: 'Товары',
    },
  ]

  React.useEffect(() => {
    dispatch(promotionsPageSalesEditActions.fetchPromotionsModalData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(promotionsPageSalesEditActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    productsOptions,
    discountTypeOptions,
    discountTypeProductOptions,
    resetPromotionsProductsHandler,
  }
}
