import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../../types/modal.types'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { ExercisesPeekBooking } from '../../exercises-peek.types'

interface Props {
  bookings: ExercisesPeekBooking[]
  exerciseId: string
  clientsCount: number
  confirmedCount: number
  bookingsTotalElements?: number
}

export function useExercisesPeekClientList(props: Props) {
  const { bookings, exerciseId, clientsCount, confirmedCount, bookingsTotalElements } = props

  const dispatch = useDispatch()

  const [searchTerm, setSearchTerm] = useState('')
  const [showCancelled, setShowCancelled] = useState(false)

  const [showButtonShowCancelled, setShowButtonCancelled] = useState(false)

  const filteredBookings = useMemo(() => {
    if (!isDefAndNotEmpty(bookings)) return []

    let filtered = bookings
    if (!showCancelled) {
      filtered = bookings.filter(booking => !booking.isCancelled)
    }

    if (searchTerm) {
      const searchLowerCase = searchTerm.toLowerCase()
      const searchPhoneNumber = searchTerm.replace(/\D/g, '').replaceAll(' ', '')

      filtered = filtered.filter(booking => {
        const firstName = booking.firstName || ''
        const lastNameInitial = booking.lastName ? `${booking.lastName}` : ''
        const fullName = `${firstName} ${lastNameInitial}`
        const fullNameLowerCase = fullName.toLowerCase()
        const phone = booking.phone || ''
        const phoneLowerCase = phone.toLowerCase()

        const nameMatches = fullNameLowerCase.includes(searchLowerCase)

        const phoneMatches = searchPhoneNumber ? phoneLowerCase.includes(searchPhoneNumber) : false

        return nameMatches || phoneMatches
      })
    }
    filtered.sort((a, b) => (a.isCancelled === b.isCancelled ? 0 : a.isCancelled ? 1 : -1))

    return filtered
  }, [bookings, searchTerm, showCancelled])

  const hasMoreBookings = useMemo(
    () => bookings.length < (bookingsTotalElements || 0),
    [bookings.length, bookingsTotalElements]
  )
  const bookingsToShow = useMemo(
    () => (bookingsTotalElements || 0) - bookings.length,
    [bookings.length, bookingsTotalElements]
  )

  const changeShowCancelled = () => {
    setShowCancelled(prev => !prev)
  }

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const onQrCodeHandler = useCallback(() => {
    if (exerciseId) {
      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_PAGE_MODAL_QR_READER,
          props: { exerciseId, confirmedCount, clientsCount },
        })
      )
    }
  }, [clientsCount, confirmedCount, dispatch, exerciseId])

  const onWaitingListHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_PAGE_MODAL_WAITING,
        props: { exerciseId },
      })
    )
  }, [dispatch, exerciseId])

  useEffect(() => {
    if (bookings?.some(booking => booking.isCancelled)) {
      setShowButtonCancelled(true)
    } else {
      setShowButtonCancelled(false)
    }
  }, [bookings])

  return {
    searchTerm,
    filteredBookings,
    showButtonShowCancelled,
    showCancelled,
    hasMoreBookings,
    bookingsToShow,
    changeShowCancelled,
    handleSearch,
    onQrCodeHandler,
    onWaitingListHandler,
  }
}
