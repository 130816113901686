import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { schedulePageModalCommentsActions } from './schedule-page-modal-comments.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { reFetchBookings } from '../schedule-page-peek-exercise/schedule-page-peek-exercise.sagas'

export function* fetchComments(action: ReturnType<typeof schedulePageModalCommentsActions.fetchComments>) {
  try {
    const { exerciseId, bookingId } = action.payload

    const response: Awaited<ReturnType<typeof api.exercisesService.fetchBookingComments>> = yield callApi(
      api.exercisesService.fetchBookingComments,
      exerciseId,
      bookingId
    )

    yield put(schedulePageModalCommentsActions.fetchCommentsSuccess(response.data))
  } catch (e) {
    yield put(schedulePageModalCommentsActions.fetchCommentsError(new Error()))
  }
}

export function* createComment(action: ReturnType<typeof schedulePageModalCommentsActions.createComment>) {
  try {
    const { exerciseId, bookingId, comment } = action.payload

    yield callApi(api.exercisesService.createBookingComment, exerciseId, bookingId, { comment })
    yield put(schedulePageModalCommentsActions.createCommentSuccess())

    yield call(reFetchBookings, exerciseId)

    yield put(modalActions.closeLast())
  } catch (e) {
    yield put(schedulePageModalCommentsActions.createCommentError(new Error()))
  }
}

export function* schedulePageModalCommentsSagas() {
  yield takeLatest(schedulePageModalCommentsActions.fetchComments, fetchComments)
  yield takeLatest(schedulePageModalCommentsActions.createComment, createComment)
}
