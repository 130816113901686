import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { genPaymentTypesOptions } from '../../../utils/payment.utils'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { schedulePageModalBookFromWaitingActions } from '../../../store/pages/schedule-page/schedule-page-modal-book-from-waiting/schedule-page-modal-book-from-waiting.slice'
import {
  genSchedulePageModalBookFromWaitingExercisesSpots,
  genSchedulePageModalBookFromWaitingIsLoading,
} from '../../../store/pages/schedule-page/schedule-page-modal-book-from-waiting/schedule-page-modal-book-from-waiting.selectors'
import { ExercisesBookingFormValues } from '../../../components/exercises/exercises-booking-modal/exercises-booking-modal-form/exercises-booking-modal-form.types'
import { ExercisesBookFromWaitingFormValues } from '../../../components/exercises/exercises-book-from-waiting-modal/exercises-book-from-waiting-modal-form/exercises-book-from-waiting-modal-form.types'

export function useSchedulePageModalBookFromWaiting(scheduleId: string, phone: string) {
  const [form] = Form.useForm<ExercisesBookingFormValues>()

  const dispatch = useDispatch()

  const loading = useSelector(genSchedulePageModalBookFromWaitingIsLoading)
  const placesOptions = useSelector(genSchedulePageModalBookFromWaitingExercisesSpots)

  const paymentTypesOptions = React.useMemo(genPaymentTypesOptions, [])

  React.useEffect((): void => {
    dispatch(schedulePageModalBookFromWaitingActions.fetchExercisesSpots(scheduleId))
  }, [dispatch, scheduleId])

  React.useEffect(() => {
    return () => {
      dispatch(schedulePageModalBookFromWaitingActions.reset())
    }
  }, [dispatch])

  const onSaveHandler = React.useCallback(
    (exercisesBookingFormValues: ExercisesBookFromWaitingFormValues): void => {
      dispatch(
        schedulePageModalBookFromWaitingActions.createBooking({
          exerciseId: scheduleId,
          exercisesBookingFormValues,
          phone,
        })
      )
    },
    [dispatch, phone, scheduleId]
  )

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    form,
    loading,

    paymentTypesOptions,
    placesOptions,

    onSaveHandler,
    onCancelHandler,
  }
}
