import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import dayjs from 'dayjs'

import { schedulePagePeekExerciseActions } from './schedule-page-peek-exercise.slice'
import { api } from '../../../../api/api'
import { callApi } from '../../../../utils/sagas.utils'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { getCurrentStudioId, getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import { isDef } from '../../../../types/lang.types'
import { genSchedulePagePath } from '../../../../format/path.format'
import { genSchedulePagePeekExercise } from './schedule-page-peek-exercise.selectors'
import { peekActions } from '../../../common/peek/peek.slice'
import { modalActions } from '../../../common/modal/modal.slice'
import { genExercisesPeekUpdateDTO } from '../../../../mapping/exercises.mapping'
import { reFetchExercises } from '../schedule-page-list/schedule-page-list.sagas'
import { EXERCISE_PEEK_BOOKINS_SIZE } from '../../../../pages/schedule-page/schedule-page-peek-exercise/schedule-page-peek-exercise.types'
import { formatDayjsToDate } from '../../../../format/date.format'

function* fetchPeekData(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchPeekData>) {
  try {
    const [exerciseResponse, exerciseLinkedResponse, bookingsResponse, waitingListResponse]: [
      Awaited<ReturnType<typeof api.exercisesService.fetchById>>,
      Awaited<ReturnType<typeof api.exercisesService.fetchAllLinked>>,
      Awaited<ReturnType<typeof api.exercisesService.fetchBookings>>,
      Awaited<ReturnType<typeof api.exercisesService.fetchWaitingList>>
    ] = yield all([
      callApi(api.exercisesService.fetchById, action.payload),
      callApi(api.exercisesService.fetchAllLinked, action.payload),
      callApi(api.exercisesService.fetchBookings, action.payload, {
        showCancelled: true,
        size: EXERCISE_PEEK_BOOKINS_SIZE,
      }),
      callApi(api.exercisesService.fetchWaitingList, action.payload),
    ])

    if (exerciseResponse.data) {
      const { type, room, timeFrom, timeTo } = exerciseResponse.data

      yield put(
        schedulePagePeekExerciseActions.fetchDictionaries({
          exerciseTypeId: type.id,
          roomId: room.id,
          timeFrom: timeFrom?.substring(0, 16),
          timeTo: timeTo?.substring(0, 16),
        })
      )
    }

    yield put(
      schedulePagePeekExerciseActions.fetchPeekDataSuccess({
        exercise: exerciseResponse.data,
        exerciseLinked: exerciseLinkedResponse.data,
        exerciseBookings: bookingsResponse.data,
        clientsInWaitingList: waitingListResponse.data?.totalElements,
      })
    )
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.fetchPeekDataError(new Error()))
  }
}

function* fetchExercise(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchExercise>) {
  try {
    const exerciseResponse: Awaited<ReturnType<typeof api.exercisesService.fetchById>> = yield callApi(
      api.exercisesService.fetchById,
      action.payload
    )

    yield put(schedulePagePeekExerciseActions.fetchExerciseSuccess(exerciseResponse.data))
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.fetchExerciseError(new Error()))
  }
}

export function* reFetchExercise(exerciseId: string) {
  yield put(schedulePagePeekExerciseActions.fetchExercise(exerciseId))

  yield race([
    take(schedulePagePeekExerciseActions.fetchExerciseSuccess.type),
    take(schedulePagePeekExerciseActions.fetchExerciseError.type),
  ])
}

function* fetchBookings(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchBookings>) {
  try {
    const { exerciseId, size } = action.payload

    const bookingsResponse: Awaited<ReturnType<typeof api.exercisesService.fetchBookings>> = yield callApi(
      api.exercisesService.fetchBookings,
      exerciseId,
      { showCancelled: true, size: size || EXERCISE_PEEK_BOOKINS_SIZE }
    )

    yield put(schedulePagePeekExerciseActions.fetchBookingsSuccess(bookingsResponse.data))
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.fetchBookingsError(new Error()))
  }
}

export function* reFetchBookings(exerciseId: string, size?: number) {
  yield put(schedulePagePeekExerciseActions.fetchBookings({ exerciseId, size }))

  yield race([
    take(schedulePagePeekExerciseActions.fetchBookingsSuccess.type),
    take(schedulePagePeekExerciseActions.fetchBookingsError.type),
  ])
}

export function* fetchClientsInWaitingList(
  action: ReturnType<typeof schedulePagePeekExerciseActions.fetchClientsInWaitingList>
) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesService.fetchWaitingList>> = yield callApi(
      api.exercisesService.fetchWaitingList,
      action.payload
    )

    yield put(schedulePagePeekExerciseActions.fetchClientsInWaitingListSuccess(response.data?.totalElements))
  } catch {
    yield put(schedulePagePeekExerciseActions.fetchClientsInWaitingListError(new Error()))
  }
}

export function* reFetchClientsInWaitingList(exerciseId: string) {
  yield put(schedulePagePeekExerciseActions.fetchClientsInWaitingList(exerciseId))

  yield race([
    take(schedulePagePeekExerciseActions.fetchClientsInWaitingListSuccess.type),
    take(schedulePagePeekExerciseActions.fetchClientsInWaitingListError.type),
  ])
}

function* fetchExecisesLinked(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchExercisesLinked>) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesService.fetchAllLinked>> = yield callApi(
      api.exercisesService.fetchAllLinked,
      action.payload
    )

    yield put(schedulePagePeekExerciseActions.fetchExercisesLinkedSuccess(response.data))
  } catch {
    yield put(schedulePagePeekExerciseActions.fetchExercisesLinkedError(new Error()))
  }
}

function* fetchDictionaries(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchDictionaries>) {
  try {
    const { exerciseTypeId, roomId, timeFrom, timeTo } = action.payload

    const [trainersResponse, studiosResponse, exerciseTypesResponse]: [
      Awaited<ReturnType<typeof api.trainersService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesTypesService.fetchById>>
    ] = yield all([
      callApi(api.trainersService.fetchAll, {
        size: 300,
        ...(isDef(timeTo) &&
          isDef(timeFrom) && {
            includeAvailability: true,
            exerciseStartsAt: timeFrom,
            exerciseEndsAt: timeTo,
            roomId,
          }),
      }),
      callApi(api.studiosService.fetchAll, { size: 300 }),
      callApi(api.exercisesTypesService.fetchById, exerciseTypeId),
    ])

    yield put(
      schedulePagePeekExerciseActions.fetchDictionariesSuccess({
        trainers: trainersResponse.data,
        studios: studiosResponse.data,
        exercisesType: exerciseTypesResponse.data,
      })
    )
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.fetchDictionariesError(new Error()))
  }
}

export function* fetchTrainers(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchTrainers>) {
  const { roomId, timeFrom, timeTo } = action.payload

  try {
    const trainersResponse: Awaited<ReturnType<typeof api.trainersService.fetchAll>> = yield callApi(
      api.trainersService.fetchAll,
      {
        size: 300,
        ...(isDef(timeTo) &&
          isDef(timeFrom) && {
            includeAvailability: true,
            exerciseStartsAt: timeFrom,
            exerciseEndsAt: timeTo,
            roomId,
          }),
      }
    )

    yield put(schedulePagePeekExerciseActions.fetchTrainersSuccess(trainersResponse.data))
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.fetchTrainersError(new Error()))
  }
}

function* fetchAudit(action: ReturnType<typeof schedulePagePeekExerciseActions.fetchAudit>) {
  try {
    const { page, size, id } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.auditService.fetchById>> = yield callApi(
      api.auditService.fetchById,
      'exercise',
      id,
      { ...params, sort: 'time,desc' }
    )
    yield put(schedulePagePeekExerciseActions.fetchAuditSuccess(response.data))
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.fetchAuditError(new Error()))
  }
}

function* changeBookingVisitingConfirmation(
  action: ReturnType<typeof schedulePagePeekExerciseActions.changeBookingVisitingConfirmation>
) {
  try {
    const { exerciseId, bookingId, confirm, bookingsSize } = action.payload

    if (confirm) {
      yield callApi(api.exercisesService.confirmBookingVisiting, exerciseId, bookingId)
    } else {
      yield callApi(api.exercisesService.cancelConfirmBookingVisiting, exerciseId, bookingId)
    }

    yield put(schedulePagePeekExerciseActions.changeBookingVisitingConfirmationSuccess())

    yield call(reFetchBookings, exerciseId, bookingsSize)
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.changeBookingVisitingConfirmationError(new Error()))
  }
}

export function* changeBookingPaymentType(
  action: ReturnType<typeof schedulePagePeekExerciseActions.changeBookingPaymentType>
) {
  try {
    const { exerciseId, paymentType, bookingId, bookingsSize } = action.payload

    yield callApi(api.exercisesService.changeBookingPaymentType, exerciseId, { paymentType, bookingId })

    yield put(schedulePagePeekExerciseActions.changeBookingPaymentTypeSuccess())

    yield call(reFetchBookings, exerciseId, bookingsSize)
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.changeBookingPaymentTypeError(new Error()))
  }
}

function* cancelBooking(action: ReturnType<typeof schedulePagePeekExerciseActions.cancelBooking>) {
  try {
    const { exerciseId, bookingId, reason } = action.payload

    yield callApi(api.exercisesService.cancelBooking, exerciseId, bookingId, { cancellationReason: reason })
    yield put(schedulePagePeekExerciseActions.cancelBookingSuccess())

    yield all([call(reFetchBookings, exerciseId), call(reFetchExercise, exerciseId)])

    yield put(modalActions.closeLast())
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.cancelBookingError(new Error()))
  }
}

function* editExercise(action: ReturnType<typeof schedulePagePeekExerciseActions.editExercise>) {
  try {
    const { exerciseId, exercisesEditFormValues, comment, successCallback } = action.payload

    const studioOffset: number | undefined = yield select(getCurrentStudioOffset)

    const exercisesUpdateDTO = genExercisesPeekUpdateDTO(exercisesEditFormValues, comment, studioOffset || 0)

    if (isDef(exercisesUpdateDTO)) {
      yield callApi(api.exercisesService.update, exerciseId, exercisesUpdateDTO)
      yield put(schedulePagePeekExerciseActions.editExerciseSuccess())

      if (successCallback) successCallback()
      yield all([call(reFetchExercise, exerciseId), call(reFetchExercises)])
    } else {
      yield put(schedulePagePeekExerciseActions.editExerciseError(new Error()))
    }
  } catch (e) {
    yield put(schedulePagePeekExerciseActions.editExerciseError(new Error()))
  }
}

function* removeExercise(action: ReturnType<typeof schedulePagePeekExerciseActions.removeExercise>) {
  try {
    const studioId: ReturnType<typeof getCurrentStudioId> = yield select(getCurrentStudioId)
    const schedulePagePeekExercise: ReturnType<typeof genSchedulePagePeekExercise> = yield select(
      genSchedulePagePeekExercise
    )
    if (isDef(schedulePagePeekExercise) && isDef(studioId)) {
      const date = dayjs(schedulePagePeekExercise.timeFrom)
      const scheduleDate = formatDayjsToDate(date)

      yield callApi(api.exercisesService.cancel, action.payload)
      yield put(schedulePagePeekExerciseActions.removeExerciseSuccess())
      yield put(peekActions.closeLast())
      yield put(push(genSchedulePagePath(studioId, { date: scheduleDate })))
    } else {
      yield put(schedulePagePeekExerciseActions.removeExerciseError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(schedulePagePeekExerciseActions.removeExerciseError(new Error()))
  }
}

export function* schedulePagePeekExerciseSagas() {
  yield takeLatest(schedulePagePeekExerciseActions.fetchPeekData.type, fetchPeekData)
  yield takeLatest(schedulePagePeekExerciseActions.fetchExercise.type, fetchExercise)
  yield takeLatest(schedulePagePeekExerciseActions.fetchBookings.type, fetchBookings)
  yield takeLatest(schedulePagePeekExerciseActions.fetchClientsInWaitingList.type, fetchClientsInWaitingList)
  yield takeLatest(schedulePagePeekExerciseActions.fetchExercisesLinked.type, fetchExecisesLinked)
  yield takeLatest(schedulePagePeekExerciseActions.fetchDictionaries.type, fetchDictionaries)
  yield takeLatest(schedulePagePeekExerciseActions.fetchTrainers.type, fetchTrainers)
  yield takeLatest(schedulePagePeekExerciseActions.fetchAudit.type, fetchAudit)
  yield takeLatest(
    schedulePagePeekExerciseActions.changeBookingVisitingConfirmation.type,
    changeBookingVisitingConfirmation
  )
  yield takeLatest(schedulePagePeekExerciseActions.changeBookingPaymentType.type, changeBookingPaymentType)
  yield takeLatest(schedulePagePeekExerciseActions.cancelBooking.type, cancelBooking)
  yield takeLatest(schedulePagePeekExerciseActions.editExercise.type, editExercise)
  yield takeLatest(schedulePagePeekExerciseActions.removeExercise.type, removeExercise)
}
