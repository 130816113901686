import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Flex, Dropdown, DropdownProps } from 'antd'
import { CheckOutlined, EyeOutlined, FundOutlined, LockOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { FilterOutlined } from '@ant-design/icons'
import { DefaultOptionType } from 'antd/lib/select'
import { LOCAL_STORAGE_ROOMS_KEY, LOCAL_STORAGE_TAGS_KEY } from '@constants/local-storage'
import clsx from 'clsx'

import { ExercisesFiltersProps, ExercisesFiltersTag } from './exercises-filters.types'
import { getTheme } from '../../../store/common/layout/layout.selectors'
import { useSchedulePageTable } from '../../../pages/schedule-page/schedule-page-table/schedule-page-table.hook'
import { schedulePageListActions } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import {
  genSchedulePageListBreaks,
  genSchedulePageListSlotClosingMode,
  genSchedulePageListTimetable,
} from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { sortOptionsByLocale } from '../../../utils/options.utils'
import { CustomSelect } from '../../custom-select/custom-select.component'
import './exercises-filters.styles.less'

export const ExercisesFilters: FC<ExercisesFiltersProps> = ({
  form,
  roomOptions,
  trainerOptions,
  directionOptions,
  recordOptions,
  paymentTypesOptions,
  clientBookingCreatePermission,
}) => {
  // Store
  const theme = useSelector(getTheme)
  const slotClosingMode = useSelector(genSchedulePageListSlotClosingMode)
  const breaks = useSelector(genSchedulePageListBreaks)
  const currentTimetable = useSelector(genSchedulePageListTimetable)
  const dispatch = useDispatch()

  // Custom hooks
  const { onAddHandler } = useSchedulePageTable()

  // State
  const [rooms, setRooms] = useState<string[]>([])
  const [tags, setTags] = useState<ExercisesFiltersTag[]>([])
  const [hasDefaultValues, setDefaultValues] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const trainers = useMemo(() => tags.filter(tag => tag.type === 'trainers').map(tag => tag.value), [tags])
  const paymentTypes = useMemo(() => tags.filter(tag => tag.type === 'paymentTypes').map(tag => tag.value), [tags])
  const directions = useMemo(() => tags.filter(tag => tag.type === 'directions').map(tag => tag.value), [tags])
  const records = useMemo(() => tags.filter(tag => tag.type === 'records').map(tag => tag.value), [tags])

  const selectOptions = useMemo(() => {
    if (!roomOptions) return []
    if (rooms.length === 1 && currentTimetable === 'weekly') {
      return roomOptions.map(option => (option.value === rooms[0] ? { ...option, disabled: true } : option))
    } else {
      return roomOptions
    }
  }, [rooms, roomOptions, currentTimetable])

  // Actions
  const onSelectFilters = useCallback(
    (tags: ExercisesFiltersTag[]) => {
      dispatch(
        schedulePageListActions.filterByTags({
          tags,
          timetableType: currentTimetable,
        })
      )
    },
    [currentTimetable, dispatch]
  )

  const toggleCurrentTimetable = useCallback(() => {
    setRooms([])
    dispatch(schedulePageListActions.updateCurrentTimetable())
  }, [dispatch])

  const handleSlotClosingMode = useCallback(() => {
    dispatch(schedulePageListActions.updateSlotClosingMode())
    if (isDefAndNotEmpty(breaks)) {
      dispatch(schedulePageListActions.updateBreaksInClosingMode(breaks))
    }
  }, [breaks, dispatch])

  const handleChangeRooms = useCallback((value: string[]) => {
    setRooms(value)
  }, [])

  const handleChangeTags = useCallback(
    (selectedOptions: DefaultOptionType | DefaultOptionType[], type: string) => {
      if (Array.isArray(selectedOptions)) {
        const remainingTags = tags.filter(tag => tag.type !== type)
        const newTags = selectedOptions.map(option => ({
          label: String(option.label),
          value: String(option.value),
          type,
        }))
        setTags([...remainingTags, ...newTags])
      }
    },
    [tags]
  )

  const clearFilters = useCallback(() => {
    setRooms([])
    setTags([])
  }, [])

  const handleDropdownOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger') {
      setDropdownOpen(nextOpen)
    }
  }

  // Filter item generator
  const optionsToItems = useCallback(
    (options: DefaultOptionType[] | undefined, type: string) => {
      if (!options) return

      return options.map(option => {
        return {
          key: option.value,
          label: (
            <Button
              type="text"
              className="exercises-filters__item"
              onClick={() => {
                const stringValue = String(option.value)

                if (type === 'rooms') {
                  if (rooms.some(item => item === stringValue)) {
                    setRooms(rooms.filter(item => item !== stringValue))
                  } else {
                    setRooms([...rooms, stringValue])
                  }
                } else {
                  if (tags.some(item => item.value === stringValue)) {
                    setTags(tags.filter(item => item.value !== stringValue))
                  } else {
                    setTags([
                      ...tags,
                      {
                        label: String(option.label),
                        value: stringValue,
                        type,
                      },
                    ])
                  }
                }
              }}
            >
              <span>{option.label}</span>
              {(type === 'rooms'
                ? rooms.includes(String(option.value))
                : tags.some(item => item.value === String(option.value))) && <CheckOutlined />}
            </Button>
          ),
        }
      })
    },
    [rooms, tags]
  )

  // Effects
  useEffect(() => {
    const savedTags = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAGS_KEY) || '[]')
    const savedRooms: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ROOMS_KEY) || '[]')

    const validRoomIds = (roomOptions ?? []).map(room => room.value)
    const filteredRooms = savedRooms.filter(roomId => validRoomIds.includes(roomId))

    if (filteredRooms.length !== savedRooms.length) {
      localStorage.setItem(LOCAL_STORAGE_ROOMS_KEY, JSON.stringify(filteredRooms))
      window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_ROOMS_KEY))
    }

    setTags(savedTags)
    setRooms(filteredRooms)
    setDefaultValues(true)
  }, [])

  useEffect(() => {
    const roomsTags = rooms.map(room => ({ label: String(room), value: String(room), type: 'rooms' }))
    onSelectFilters([...tags, ...roomsTags])

    localStorage.setItem(LOCAL_STORAGE_TAGS_KEY, JSON.stringify(tags))
    localStorage.setItem(LOCAL_STORAGE_ROOMS_KEY, JSON.stringify(rooms))
    window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_ROOMS_KEY))
  }, [tags, rooms, currentTimetable])

  // Set one room for weekly by default
  useEffect(() => {
    if (!hasDefaultValues) return

    if (currentTimetable === 'weekly' && roomOptions?.length && !rooms.length) {
      setRooms(sortOptionsByLocale(roomOptions).map(room => String(room.value)))
    }
  }, [roomOptions, currentTimetable, rooms, hasDefaultValues])

  // Menu items
  const filterMenuItems = useMemo(
    () => [
      {
        key: '1',
        label: 'Пространства',
        children: optionsToItems(selectOptions, 'rooms'),
      },
      {
        key: '2',
        label: 'Категории записей',
        children: optionsToItems(recordOptions, 'records'),
      },
      {
        key: '3',
        label: 'Исполнители',
        children: optionsToItems(trainerOptions, 'trainers'),
      },
      {
        key: '4',
        label: 'Направления',
        children: optionsToItems(directionOptions, 'directions'),
      },
      {
        key: '5',
        label: 'Статус оплаты',
        children: optionsToItems(paymentTypesOptions, 'paymentTypes'),
      },
    ],
    [selectOptions, recordOptions, trainerOptions, directionOptions, paymentTypesOptions, optionsToItems]
  )

  const currentTimetableMenuItems = useMemo(
    () => [
      {
        key: 'weekly',
        label: (
          <Button
            className="exercises-filters__item"
            type="text"
            style={{ padding: 0 }}
            onClick={toggleCurrentTimetable}
          >
            <span>Неделя</span>
            {currentTimetable === 'weekly' && <CheckOutlined />}
          </Button>
        ),
      },
      {
        key: 'daily',
        label: (
          <Button
            className="exercises-filters__item"
            type="text"
            style={{ padding: 0 }}
            onClick={toggleCurrentTimetable}
          >
            <span>День</span>
            {currentTimetable === 'daily' && <CheckOutlined />}
          </Button>
        ),
      },
    ],
    [currentTimetable, toggleCurrentTimetable]
  )

  return (
    <>
      <div className={clsx(`exercises-filters exercises-filters--${theme}`)}>
        <Flex justify="space-between" align="center">
          <Flex gap="5px">
            <Dropdown
              arrow
              onOpenChange={handleDropdownOpenChange}
              open={dropdownOpen}
              menu={{ items: filterMenuItems }}
            >
              <Button className="exercises-filters__button" type="primary" ghost icon={<FilterOutlined />}>
                Фильтры
              </Button>
            </Dropdown>

            <Dropdown arrow menu={{ items: currentTimetableMenuItems }}>
              <Button icon={<EyeOutlined />}>Вид</Button>
            </Dropdown>
          </Flex>

          {!slotClosingMode && (
            <Flex gap="middle" align="center">
              <Button disabled icon={<FundOutlined />}>
                Аналитика дня
              </Button>
              <Button icon={<LockOutlined />} size="small" type="dashed" onClick={handleSlotClosingMode} />
            </Flex>
          )}
        </Flex>
      </div>
      <div className={clsx(`exercises-selects exercises-selects--${theme}`)}>
        <Flex className="exercises-selects__wrapper" gap={8} align="center" wrap="wrap">
          {isDefAndNotEmpty(rooms) && (
            <CustomSelect
              options={selectOptions}
              placeholder="Пространства"
              mode="multiple"
              value={rooms}
              onChange={value => {
                if (Array.isArray(value)) handleChangeRooms(value)
              }}
            />
          )}
          {isDefAndNotEmpty(records) && (
            <CustomSelect
              options={recordOptions}
              placeholder="Категории записей"
              mode="multiple"
              value={tags.filter(tag => tag.type === 'records').map(tag => Number(tag.value))}
              onChange={(_, option) => handleChangeTags(option, 'records')}
            />
          )}
          {isDefAndNotEmpty(trainers) && (
            <CustomSelect
              options={trainerOptions}
              placeholder="Исполнители"
              mode="multiple"
              value={trainers}
              onChange={(_, option) => handleChangeTags(option, 'trainers')}
            />
          )}
          {isDefAndNotEmpty(directions) && (
            <CustomSelect
              options={directionOptions}
              placeholder="Направления"
              mode="multiple"
              value={tags.filter(tag => tag.type === 'directions').map(tag => Number(tag.value))}
              onChange={(_, option) => handleChangeTags(option, 'directions')}
            />
          )}
          {isDefAndNotEmpty(paymentTypes) && (
            <CustomSelect
              options={paymentTypesOptions}
              placeholder="Оплата"
              mode="multiple"
              value={tags.filter(tag => tag.type === 'paymentTypes').map(tag => tag.value)}
              onChange={(_, option) => handleChangeTags(option, 'paymentTypes')}
            />
          )}
          {(isDefAndNotEmpty(rooms) || isDefAndNotEmpty(tags)) && (
            <Button className="exercises-selects__reset-button" size="small" type="text" onClick={() => clearFilters()}>
              Сбросить
            </Button>
          )}
        </Flex>
      </div>
    </>
  )
}
