import React, { useEffect } from 'react'
import { Button, DatePicker, Divider, Flex, Form, Input, Modal, Typography } from 'antd'
import { Dayjs } from 'dayjs'

import { PromotionsFormProducts } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-products/promotions-create-form-products.component'
import { PromotionsFormLimitation } from '../../../../../components/promotions/promotions-create-form/promotions-create-form-limitation/promotions-create-form-limitation.component'
import { PromotionsApi } from '../../../../../api/types/promotions-api.types'
import { useSalesEdit } from './promotions-page-sales-modal-edit.hook'
import { PromotionsSalesModalEditProps } from './promotions-page-sales-modal-edit.types'
import { genDefaultDateFormat } from '../../../../../format/date.format'

export const PromotionsSalesModalEdit = ({ handleOk, handleCancel, id }: PromotionsSalesModalEditProps) => {
  const { form, onSearchHandler, productsSalesList, promotionsEditSelectedFranchises, isModalLoading, isEditing } =
    useSalesEdit(id)

  function handleFinishModal(value: PromotionsApi.PromotionDTO<Dayjs>) {
    handleOk(id, value)
  }

  useEffect(() => {
    form.setFieldValue('limitedByOrganization', promotionsEditSelectedFranchises)
  }, [form, promotionsEditSelectedFranchises])

  return (
    <Modal
      title="Редактироваие промоакции"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          disabled={isEditing || isModalLoading}
          loading={isEditing || isModalLoading}
        >
          Редактировать акцию
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          layout="vertical"
          name="form_in_modal"
          disabled={isModalLoading}
          onFinish={handleFinishModal}
          form={form}
          initialValues={{
            products: [{}],
          }}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      <Flex gap={'large'} vertical>
        <Flex gap={'large'} vertical>
          <Typography.Title level={4} style={{ margin: '0', marginBottom: '8px' }}>
            Основная информация
          </Typography.Title>
          <Form.Item
            style={{ flex: '1' }}
            name="name"
            label="Название промокампании"
            rules={[{ required: true, message: 'Введите название промокампании!' }]}
          >
            <Input style={{ width: '100%' }} size={'large'} />
          </Form.Item>
          <Flex gap={'middle'}>
            <Form.Item style={{ flex: '1' }} name="activeFrom" label="Начало действия">
              <DatePicker style={{ width: '100%' }} format={genDefaultDateFormat()} size={'large'} />
            </Form.Item>
            <Form.Item style={{ flex: '1' }} name="activeTo" label="Конец действия">
              <DatePicker style={{ width: '100%' }} format={genDefaultDateFormat()} size={'large'} />
            </Form.Item>
          </Flex>
        </Flex>

        {/*<PromotionsFormProducts onSearchHandler={onSearchHandler} form={form} productsList={productsSalesList} />*/}
        <PromotionsFormLimitation form={form} />
      </Flex>
    </Modal>
  )
}
